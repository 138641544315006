import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";

import Instances from "./Instances";
import Volumes from "./Volumes";
import Filters from "./Filters";

import {
  isInstanceInIntermediateState,
  isVolumeInIntermediateState,
} from "./utils";

import "./projectnodes.scss";

export default function ProjectNodes() {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [instanceTypes, setInstanceTypes] = useState([]);
  const [volumeTypes, setVolumeTypes] = useState([]);
  const [instances, setInstances] = useState([]);
  const [volumes, setVolumes] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredInstances, setFilteredInstances] = useState([]);
  const [filteredVolumes, setFilteredVolumes] = useState([]);
  const [isInstancesLoading, setIsInstancesLoading] = useState(false);
  const [isVolumesLoading, setIsVolumesLoading] = useState(false);

  const getInstanceTypes = () => {
    return api
      .execute("ec2.instance.list_instance_types")
      .then((instanceTypes) => {
        isMounted.current && setInstanceTypes(instanceTypes);
        return instanceTypes;
      });
  };

  const getVolumeTypes = () => {
    api
      .execute("ec2.volume.list_volume_types")
      .then((volumeTypes) => isMounted.current && setVolumeTypes(volumeTypes));
  };

  const getInstances = () => {
    setIsInstancesLoading(true);
    return api
      .execute("ec2.instance.list")
      .then((instances) => {
        const promise = instanceTypes.length
          ? Promise.resolve(instanceTypes)
          : getInstanceTypes();
        promise.then((instanceTypes) => {
          instances.forEach((instance) => {
            instance.isOld = !instanceTypes.some(
              (type) => type.instance_type === instance.instance_type
            );
          });
          isMounted.current && setInstances(instances);
        });

        // Check for any resources with intermediate states and refetch if needed
        const hasIntermediateStates = instances.some((instance) =>
          isInstanceInIntermediateState(instance.state)
        );
        hasIntermediateStates &&
          setTimeout(() => isMounted.current && getInstances(), 60000);
      })
      .finally(() => isMounted.current && setIsInstancesLoading(false));
  };

  // Gets the detached volumes only as the attached ones are shown as subtable on an instance
  const getVolumes = () => {
    setIsVolumesLoading(true);
    return api
      .execute("ec2.volume.list", { only_detached: true })
      .then((volumes) => {
        isMounted.current && setVolumes(volumes);

        // Check for any resources with intermediate states and refetch if needed
        const hasIntermediateStates = volumes.some((volume) =>
          isVolumeInIntermediateState(volume.state)
        );
        hasIntermediateStates &&
          setTimeout(() => isMounted.current && getVolumes(), 60000);
      })
      .finally(() => isMounted.current && setIsVolumesLoading(false));
  };

  const getUsers = () => {
    api
      .execute("user.list", { is_admin: false })
      .then((users) => isMounted.current && setUsers(users));
  };

  const getResources = () => {
    return Promise.all([getInstances(), getVolumes()]);
  };

  useEffect(() => {
    getVolumeTypes();
    getResources();
    getUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const terminateInstance = (instance) => {
    return api.execute("ec2.instance.terminate", {
      instance_id: instance.instance_id,
    });
  };

  const deleteVolume = (volume) => {
    return api.execute("ec2.volume.delete", { volume_id: volume.volume_id });
  };

  const deleteFnMap = {
    instance: terminateInstance,
    volume: deleteVolume,
  };

  const deletedResource = (resource) => {
    const deleteFn = deleteFnMap[resource.type];
    return deleteFn(resource.resource).finally(getResources);
  };

  return (
    <Container fluid={true} className="page project-nodes">
      <Row>
        <Col>
          <div className="page-title-box">
            <h3 className="page-title">Project Nodes</h3>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Filters
            instances={instances}
            volumes={volumes}
            filteredInstances={filteredInstances}
            setFilteredInstances={setFilteredInstances}
            filteredVolumes={filteredVolumes}
            setFilteredVolumes={setFilteredVolumes}
          ></Filters>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Instances
            isLoading={isInstancesLoading}
            instances={filteredInstances}
            instanceTypes={instanceTypes}
            volumeTypes={volumeTypes}
            users={users}
            getResources={getResources}
            deleteResource={deletedResource}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Volumes
            isLoading={isVolumesLoading}
            volumes={filteredVolumes}
            instances={filteredInstances}
            volumeTypes={volumeTypes}
            users={users}
            getResources={getResources}
            deleteResource={deletedResource}
          ></Volumes>
        </Col>
      </Row>
    </Container>
  );
}
