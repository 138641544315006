import { useState } from "react";
import { Modal, Button, FormControl, Table } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { ButtonWithSpinner, ErrorMessages } from "components/common";

export default function UploadByUrl({ bucket, curPath, onClose, getObjects }) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [step, setStep] = useState("PASTE");
  const [urls, setUrls] = useState("");
  const [filesToUpload, setFilesToUpload] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleContinue = () => {
    const files = {};
    urls.split(/\n/).forEach((url) => {
      url = url.trim();

      if (url) {
        let filename = url.match(/[^/?#]+(?=$|[?#])/);
        filename = !!filename ? filename[0] : "";
        files[url] = filename;
      }
    });

    setFilesToUpload(files);
    setStep("CONFIRM");
  };

  const handleConfirm = () => {
    setIsLoading(true);
    setErrors({});

    const promises = Object.keys(filesToUpload).map((url) =>
      api.execute("s3.upload_url", {
        bucket: bucket.name,
        key: curPath ? `${curPath}/${filesToUpload}` : filesToUpload[url],
        url,
      })
    );

    Promise.all(promises)
      .then(() => {
        if (isMounted.current) {
          setStep("DONE");
        }
      })
      .catch((errors) => isMounted.current && setErrors(errors))
      .finally(() => {
        getObjects();
        isMounted.current && setIsLoading(false);
      });
  };

  return (
    <Modal
      show
      animation={false}
      onHide={onClose}
      backdrop="static"
      size="lg"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Upload By Url</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {step === "PASTE" && (
          <>
            <p>Copy/paste the list of URLs, one per line.</p>
            <FormControl
              as="textarea"
              rows="10"
              value={urls}
              onChange={(e) => setUrls(e.target.value)}
            ></FormControl>
          </>
        )}

        {step === "CONFIRM" && (
          <>
            <p>Please confirm the detected filename for each URL.</p>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th>URL</th>
                  <th>filename</th>
                </tr>
              </thead>

              <tbody>
                {Object.keys(filesToUpload).map((url) => {
                  const filename = filesToUpload[url];
                  return (
                    <tr key={url}>
                      <td>
                        <FormControl value={url} readOnly></FormControl>
                      </td>
                      <td>
                        <FormControl
                          value={filename}
                          onChange={(e) =>
                            setFilesToUpload({
                              ...filesToUpload,
                              [url]: e.target.value,
                            })
                          }
                        ></FormControl>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <ErrorMessages errors={errors}></ErrorMessages>
          </>
        )}

        {step === "DONE" && (
          <p>
            Files are uploading. Only when completely uploaded will you be able
            to see them in the bucket.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onClose}>
          Cancel
        </Button>
        <ButtonWithSpinner
          isLoading={isLoading}
          onClick={() => {
            step === "PASTE"
              ? handleContinue()
              : step === "CONFIRM"
              ? handleConfirm()
              : onClose();
          }}
          disabled={!urls}
        >
          {step === "PASTE"
            ? "Continue"
            : step === "CONFIRM"
            ? "Confirm"
            : "Close"}
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
}
