import { useState } from "react";
import { Modal, Form, Col, Row, Button, InputGroup } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { ButtonWithSpinner, ErrorMessages } from "components/common";

export default function AttachVolume({
  volume,
  instances,
  getResources,
  close,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [instanceId, setInstanceId] = useState("");
  const [mountpoint, setMountpoint] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    setErrors({});

    api
      .execute("ec2.volume.attach", {
        volume_id: volume.volume_id,
        instance_id: instanceId,
        mountpoint: `/mnt/${mountpoint}`,
      })
      .then(close)
      .catch((errors) => setErrors(errors))
      .finally(() => {
        getResources();
        isMounted.current && setIsLoading(false);
      });
  };

  let availableInstances = [];
  if (instances && volume) {
    availableInstances = instances.filter(
      (instance) =>
        instance.customer === volume.customer &&
        instance.project === volume.project
    );
  }

  if (availableInstances.length) {
    return (
      <Modal
        show
        animation={false}
        onHide={close}
        size="lg"
        className="launch-instance-modal"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Attach Volume</Modal.Title>
        </Modal.Header>

        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Row className="mb-3">
              <Col>
                <p>
                  <i className="bi bi-exclamation-triangle mr-2"></i>Volumes can
                  only be attached to instances with the same project and
                  customer.
                </p>
              </Col>
            </Row>
            <Form.Group as={Row} className="mb-3 form-group-plaintext">
              <Form.Label column sm="3">
                Volume
              </Form.Label>
              <Col sm="9">
                <Form.Control plaintext readOnly defaultValue={volume.name} />
              </Col>
            </Form.Group>

            {/* INSTANCES */}
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Instance
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  name="instance"
                  custom
                  value={instanceId}
                  onChange={(e) => {
                    setInstanceId(e.target.value);
                    setErrors({});
                  }}
                  required
                  isInvalid={!!errors.instance}
                >
                  <option key="choose" value="">
                    Choose available instance
                  </option>
                  {availableInstances.map((instance) => (
                    <option
                      key={instance.instance_id}
                      value={instance.instance_id}
                    >
                      {instance.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.instance}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>

            {/* MOUNTPOINT */}
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Mount point
              </Form.Label>
              <Col sm="8">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>/mnt/</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    placeholder="Enter mount point"
                    value={mountpoint}
                    name="mountpoint"
                    onChange={(e) => {
                      const mountpoint = e.target.value;
                      setErrors({});

                      if (mountpoint === "/") {
                        setMountpoint("");
                      } else {
                        setMountpoint(mountpoint);
                      }
                    }}
                    isInvalid={!!errors.mountpoint}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.mountpoint}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Group>

            {/* GENERIC ERRORS */}
            <ErrorMessages
              errors={errors}
              keysToIgnore={["instance", "mountpoint"]}
              className="mt-3"
            ></ErrorMessages>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={close} disabled={isLoading}>
              Cancel
            </Button>
            <ButtonWithSpinner type="submit" isLoading={isLoading}>
              Attach
            </ButtonWithSpinner>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  } else {
    return (
      <Modal
        show
        animation={false}
        onHide={close}
        className="launch-instance-modal"
        size="lg"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Attach Volume</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mb-3">
            <Col>
              <p className="mb-0">
                <i className="bi bi-exclamation-triangle mr-2"></i>Could not
                find any available instances.
                <br />
                Volumes can only be attached to instances with the same project
                and customer.
              </p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <ButtonWithSpinner type="submit" onClick={close}>
            Close
          </ButtonWithSpinner>
        </Modal.Footer>
      </Modal>
    );
  }
}
