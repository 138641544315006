import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { CardTitle, RefreshButton } from "components/common";

import CustomersTable from "./CustomersTable";
import CreateProject from "./CreateProject";
import ProjectsTable from "./ProjectsTable";
import DeleteProject from "./DeleteProject";
import CreateCustomer from "./CreateCustomer";
import DeleteCustomer from "./DeleteCustomer";

export default function Projects() {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const customersTableRef = useRef();
  const [projects, setProjects] = useState({});
  const [customers, setCustomers] = useState([]);
  const [createdCustomer, setCreatedCustomer] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerProjects, setSelectedCustomerProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showDeleteCustomer, setShowDeleteCustomer] = useState(false);
  const [showDeleteProject, setShowDeleteProject] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const refresh = () => {
    setIsLoading(true);
    return getProjects(true).finally(
      () => isMounted.current && setIsLoading(false)
    );
  };

  const getProjects = useCallback(
    (refresh = false) => {
      return api
        .execute(`project.${refresh ? "refresh" : "list"}`)
        .then((projects) => {
          if (isMounted.current) {
            setCustomers(
              Object.keys(projects).map((customer) => ({ name: customer }))
            );
            setProjects(projects);
          }
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (selectedCustomer && projects[selectedCustomer]) {
      setSelectedCustomerProjects(
        projects[selectedCustomer].map((project) => ({ name: project }))
      );
    } else {
      setSelectedCustomerProjects([]);
    }
  }, [selectedCustomer, projects]);

  useEffect(() => {
    setIsLoading(true);
    getProjects().finally(() => {
      isMounted.current && setIsLoading(false);
    });
  }, [getProjects, isMounted]);

  useEffect(() => {
    // ensures a customer that was just created is selected
    if (createdCustomer) {
      const foundCustomer = customers.find(
        (customer) => customer.name === createdCustomer
      );
      if (foundCustomer) {
        setSelectedCustomer(createdCustomer);
        setCreatedCustomer(null);
        customersTableRef.current.selectRow("name", createdCustomer);
      }
    }
  }, [customers, createdCustomer]);

  return (
    <Container fluid className="page projects">
      <Row>
        <Col>
          <div className="page-title-box">
            <h3 className="page-title">Projects</h3>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={5}>
          <Card>
            <Card.Body>
              <CardTitle title="Customers" showSpinner={isLoading}>
                <span className="mr-2">
                  <CreateCustomer
                    customers={customers}
                    onCreated={(customer) => {
                      setCreatedCustomer(customer);
                      return refresh();
                    }}
                  />
                </span>
                <RefreshButton size="sm" onRefresh={refresh}></RefreshButton>
              </CardTitle>
              <CustomersTable
                tableRef={customersTableRef}
                customers={customers}
                onSelectCustomer={setSelectedCustomer}
                onDelete={(customer) => {
                  setSelectedCustomer(customer);
                  setShowDeleteCustomer(true);
                }}
              ></CustomersTable>
            </Card.Body>
          </Card>
        </Col>

        <Col className="mt-4 mt-xl-0">
          <Card>
            <Card.Body>
              <CardTitle title="Projects" showSpinner={isLoading}>
                <span className="mr-2">
                  <CreateProject
                    customer={selectedCustomer}
                    onCreated={refresh}
                  />
                </span>
                <RefreshButton size="sm" onRefresh={refresh}></RefreshButton>
              </CardTitle>

              <ProjectsTable
                projects={selectedCustomerProjects}
                onDelete={(project) => {
                  setSelectedProject(project);
                  setShowDeleteProject(true);
                }}
              />

              {showDeleteCustomer && (
                <DeleteCustomer
                  customer={selectedCustomer}
                  projects={projects[selectedCustomer]}
                  onDeleted={() =>
                    refresh().then(() => {
                      setShowDeleteCustomer(false);
                      setSelectedCustomer(null);
                    })
                  }
                  onCancel={() => {
                    setShowDeleteCustomer(false);
                  }}
                />
              )}
              {showDeleteProject && (
                <DeleteProject
                  customer={selectedCustomer}
                  project={selectedProject}
                  onDeleted={() => {
                    return refresh().then(() => {
                      setShowDeleteProject(false);
                      setSelectedProject(null);
                    });
                  }}
                  onCancel={() => {
                    setShowDeleteProject(false);
                    setSelectedProject(null);
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
