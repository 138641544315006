import { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import { useAtomValue } from "jotai";

import { IconButton, Table as CommonTable } from "components/common";
import { userAtom } from "store";

export default function Table({ data, onDelete }) {
  const user = useAtomValue(userAtom);

  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
    ];

    if (user.isAdmin) {
      columns.push({
        Header: "Users",
        filter: "includes",
        accessor: "users",
        Cell: ({ row }) =>
          row.original.users.map((user) => (
            <h6 key={`${row.original.name}.${user}`}>
              <Badge pill variant="primary" className="share-pill">
                {user}
              </Badge>
            </h6>
          )),
      });
    }

    columns.push({
      Header: "Actions",
      id: "actions",
      Cell: ({ row }) => {
        return (
          <IconButton
            title="Delete"
            icon="trash-fill"
            onClick={() => onDelete(row.original.name)}
          ></IconButton>
        );
      },
    });

    return columns;
  }, [user, onDelete]);

  return <CommonTable columns={columns} data={data} />;
}
