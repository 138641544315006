import Button from "react-bootstrap/Button";

export default function TablePagination(props) {
  const maxPages = props.pageOptions.length;
  const curPage = props.pageIndex + 1;
  let lastPage;

  if (curPage <= 3) {
    lastPage = maxPages <= 5 ? maxPages : 5;
  } else if (curPage + 2 > maxPages) {
    lastPage = maxPages;
  } else {
    lastPage = curPage + 2;
  }

  const firstPage = lastPage - 5 > 0 ? lastPage - 5 : 0;
  let btns = [];
  for (let i = lastPage; i > firstPage; i--) {
    btns.unshift(
      <li key={`page${i}`} className="pagination-item">
        <Button
          className={`pagination-link ${i === curPage ? "active" : ""}`}
          onClick={() => props.gotoPage(i - 1)}
        >
          {i}
        </Button>
      </li>
    );
  }

  return (
    <div className="table-pagination">
      <ul className="pagination pagination-rounded">
        <li className="pagination-item previous">
          <Button
            className="pagination-link"
            onClick={() => props.previousPage()}
            disabled={!props.canPreviousPage}
          >
            &lt;
          </Button>
        </li>

        {btns}

        <li className="pagination-item next">
          <Button
            className="pagination-link"
            onClick={() => props.nextPage()}
            disabled={!props.canNextPage}
          >
            &gt;
          </Button>
        </li>
      </ul>
    </div>
  );
}
