import { Button, Form, Col, Row, Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const NextflowModal = ({ nextflowData, close }) => {
  const nextflowConfig = nextflowData["nextflow.config"];
  const batchParams = nextflowData["batch_params.yml"];

  const handleDownload = (file, filename) => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(file)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Modal show size="lg" animation={false} onHide={close} backdrop="static">
      <Modal.Header>
        <Modal.Title>Nextflow Configuration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="nextflow-config-tab"
          id="config-tab"
          className="mb-3"
          justify
        >
          <Tab eventKey="nextflow-config-tab" title="nextflow.config">
            <Row>
              <Col>
                <div className="nextflow-config-container">
                  <Form.Control
                    as="textarea"
                    className="nextflow-config"
                    rows={15}
                    value={nextflowConfig}
                    readOnly
                  />
                </div>
              </Col>
            </Row>
            {!!nextflowConfig && (
              <Row className="mt-3 justify-content-center">
                <Col xs="auto">
                  <Button
                    onClick={() =>
                      handleDownload(nextflowConfig, "nextflow.config")
                    }
                  >
                    Download Nextflow Configuration File
                  </Button>
                </Col>
              </Row>
            )}
          </Tab>
          <Tab eventKey="batch-params-tab" title="batch_params.yml">
            <Row>
              <Col>
                <div className="batch-params-container">
                  <Form.Control
                    as="textarea"
                    className="batch-params"
                    rows={15}
                    value={batchParams}
                    readOnly
                  />
                </div>
              </Col>
            </Row>
            {!!batchParams && (
              <Row className="mt-3 justify-content-center">
                <Col xs="auto">
                  <Button
                    onClick={() =>
                      handleDownload(batchParams, "batch_params.yml")
                    }
                  >
                    Download Batch Params File
                  </Button>
                </Col>
              </Row>
            )}
          </Tab>
        </Tabs>
      </Modal.Body>

      <Modal.Footer>
        <Row>
          <Col>
            <Button onClick={close}>Close</Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default NextflowModal;
