import { useMemo } from "react";
import { Badge } from "react-bootstrap";
import { useAtomValue } from "jotai";

import { Table, IconButton, SvgButton } from "components/common";
import { roundToTwo } from "utils";
import { ReactComponent as AttachIcon } from "media/attach-icon.svg";
import { userAtom } from "store";

import { volumeStates } from "../utils";

export default function VolumesTable({
  tableRef,
  volumes,
  children,
  onModify,
  onAttach,
  onDelete,
  onShareVolumeUsers,
}) {
  const user = useAtomValue(userAtom);

  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "State",
        accessor: "state",
        Cell: ({ value }) => (
          <h6 className="mb-0">
            <Badge
              pill
              variant={volumeStates[value].variant}
              className="state-pill"
            >
              <i className={`bi ${volumeStates[value].icon}`} />
              {value}
            </Badge>
          </h6>
        ),
      },
      {
        Header: "Type",
        accessor: "volume_type",
      },
      {
        Header: "Size (GiB)",
        accessor: "size",
      },
      {
        Header: "Cost *",
        accessor: "cost",
        Cell: ({ value }) => `$${roundToTwo(value)}`,
      },
      {
        Header: "Customer",
        accessor: "customer",
      },
      {
        Header: "Project",
        accessor: "project",
      },
      {
        Header: "Users",
        accessor: "users",
        filter: "includes",
        Cell: ({ row }) =>
          row.original.users.map((share) => (
            <h6 key={`${row.original.name}.${share}`}>
              <Badge pill variant="primary" className="share-pill">
                {share}
              </Badge>
            </h6>
          )),
      },
    ];

    if (user.canManage) {
      columns.push({
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          return (
            <>
              <IconButton
                title="Modify"
                icon="pencil-fill"
                className="mr-2"
                onClick={() => onModify(row.original)}
              ></IconButton>

              <SvgButton
                title="Attach"
                icon="link-45deg"
                className="mr-2"
                onClick={() => onAttach(row.original)}
              >
                <AttachIcon></AttachIcon>
              </SvgButton>
              <IconButton
                title="Share With Users"
                icon="people-fill"
                className="mr-2"
                onClick={() => onShareVolumeUsers(row.original)}
              ></IconButton>

              <IconButton
                title="Delete"
                icon="trash-fill"
                onClick={() => onDelete(row.original)}
              ></IconButton>
            </>
          );
        },
      });
    }
    return columns;
  }, [onAttach, onDelete, onModify, onShareVolumeUsers, user]);

  return (
    <Table ref={tableRef} columns={columns} data={volumes} defaultPageSize={25}>
      {children}
    </Table>
  );
}
