import { useState } from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { ButtonWithSpinner, ErrorMessages } from "components/common";
import { roundToTwo } from "utils";

import { allowedVolumeSizes } from "../utils";

export default function ModifyVolume({
  volume,
  volumeTypes,
  getResources,
  close,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [volumeType, setVolumeType] = useState(volume.volume_type);
  const [size, setSize] = useState(volume.size);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const allowChangeType = volume.mountpoint !== "/";

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    api
      .execute("ec2.volume.modify", {
        volume_id: volume.volume_id,
        volume_type: volumeType,
        size: parseInt(size),
      })
      .then(close)
      .catch((errors) => setErrors(errors))
      .finally(() => {
        getResources();
        isMounted.current && setIsLoading(false);
      });
  };

  const calculateCost = () => {
    const zeroCost = roundToTwo(0);
    const cost = { current: zeroCost, new: zeroCost };

    if (!volumeTypes.length) {
      return cost;
    }

    const getVolumeType = (typeToFind) =>
      volumeTypes.find((type) => type.volume_type === typeToFind);
    cost.current = roundToTwo(volume.cost);
    cost.new = roundToTwo(getVolumeType(volumeType).cost * size);

    return cost;
  };
  const cost = calculateCost();

  const allowedMinSize =
    volume.size > allowedVolumeSizes[volumeType].min
      ? volume.size
      : allowedVolumeSizes[volumeType].min;

  return (
    <Modal
      show
      animation={false}
      onHide={close}
      size="lg"
      className="launch-instance-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Modify Volume</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <p>
            <i className="bi bi-exclamation-triangle mr-2"></i>Volume size can
            be increased at most once every 6 hours.
          </p>
          <Form.Group as={Row} className="mb-3 form-group-plaintext">
            <Form.Label column sm="3">
              Volume
            </Form.Label>
            <Col sm="9">
              <Form.Control plaintext readOnly defaultValue={volume.name} />
            </Col>
          </Form.Group>

          {/* VOLUME TYPE */}
          {allowChangeType && (
            <Form.Group as={Row}>
              <Form.Label column sm="3">
                Volume Type
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  name="volumeType"
                  custom
                  value={volumeType}
                  onChange={(e) => {
                    setVolumeType(e.target.value);
                    setErrors({});
                  }}
                  required
                  isInvalid={!!errors.volume_type}
                >
                  {volumeTypes.map((volumeType) => (
                    <option
                      key={volumeType.volume_type}
                      value={volumeType.volume_type}
                    >
                      {volumeType.volume_type} - {volumeType.description}, $
                      {volumeType.cost}/GiB
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.volume_type}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          )}

          {/* VOLUME SIZE */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Volume Size
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="number"
                placeholder="Enter size"
                value={size}
                min={allowedMinSize}
                max={16384}
                name="size"
                onChange={(e) => {
                  let newSize = e.target.value;
                  newSize = newSize <= 16384 ? newSize : 16384;
                  setSize(newSize);
                  setErrors({});
                }}
                isInvalid={!!errors.size}
                required
              />
              <Form.Text className="text-muted">
                Choose volume size between {allowedMinSize} and 16384 GiB.{" "}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.size}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* COST */}
          <Row className="justify-content-end mt-5">
            <Col xs="auto text-right">
              <table>
                <tbody className="font-weight-bold">
                  <tr>
                    <td className="pr-3">Current cost (monthly):</td>
                    <td>${cost.current}</td>
                  </tr>
                  <tr>
                    <td className="pr-3">New cost (monthly):</td>
                    <td>${cost.new}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          {/* GENERIC ERRORS */}
          <ErrorMessages
            errors={errors}
            keysToIgnore={["volume_type", "size"]}
            className="mt-3"
          ></ErrorMessages>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <ButtonWithSpinner type="submit" isLoading={isLoading}>
            Modify
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
