import React from "react";
import { useAsyncDebounce } from "react-table";
import { Row, Col, Form, InputGroup } from "react-bootstrap";

export default function TableFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Row>
      <Col className="table-filter">
        <Row>
          <Col>
            <InputGroup className="mb-2 ml-auto">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <i className="bi bi-search"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="search"
                placeholder="Enter to search"
                value={value || ""}
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              ></Form.Control>
            </InputGroup>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
