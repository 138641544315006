import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Amplify, Auth } from "aws-amplify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import { createAwsConfig } from "utils";
import constants from "constants";
import { useAuth } from "hooks";

import { ReactComponent as ExcelraLogo } from "media/excelra_logo.svg";
import { LoginForm, NewPasswordForm } from "./forms";

import "./login.scss";

export default function Login() {
  const { handleLoggedIn } = useAuth();
  const { search } = useLocation();
  const { email, code } = queryString.parse(search);

  const isFirstLogin = email && code;

  const [state, setState] = useState({
    isLoggingIn: false,
    isLoggingInFederated: false,
    username: email || "",
    password: code || "",
    errors: {},
    showNewPassword: false,
    newPassword: "",
    newPasswordRepeat: "",
    user: null,
    invalidToken: false,
  });

  const handleFederatedLogin = () => {
    localStorage.clear();
    Amplify.configure(createAwsConfig(false));
    setState((prevState) => ({ ...prevState, isLoggingInFederated: true }));
    Auth.federatedSignIn({ provider: "AzureAD" });
  };

  const handleLogIn = (username, password, showLoader = true) => {
    localStorage.clear();
    localStorage.setItem(constants.LOGIN_WITHOUT_SSO, true);
    Amplify.configure(createAwsConfig(true));

    showLoader &&
      setState((prevState) => ({ ...prevState, isLoggingIn: true }));

    Auth.signIn(username, password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setState((prevState) => ({
            ...prevState,
            user,
            showNewPassword: true,
            isLoggingIn: false,
          }));
        } else {
          handleLoggedIn(user);
        }
      })
      .catch((error) => {
        const errMsg =
          error.code === "UserNotFoundException" ||
          error.code === "NotAuthorizedException"
            ? isFirstLogin
              ? "Invalid create token, contact the administrator."
              : "Incorrect username or password."
            : error.message;

        setState((prevState) => ({
          ...prevState,
          errors: { "": errMsg },
          isLoggingIn: false,
          invalidToken: isFirstLogin,
        }));
      });
  };

  const handleCompleteNewPassword = () => {
    setState((prevState) => ({ ...prevState, isLoggingIn: true }));

    if (state.newPassword !== state.newPasswordRepeat) {
      setState((prevState) => ({
        ...prevState,
        errors: { newPasswordAgain: "Passwords must be identical." },
        isLoggingIn: false,
      }));
      return;
    }

    Auth.completeNewPassword(state.user, state.newPassword)
      .then(() => handleLogIn(state.username, state.newPassword))
      .catch((error) =>
        setState((prevState) => ({
          ...prevState,
          errors: { "": error.message },
          isLoggingIn: false,
        }))
      );
  };

  // Use a ref to track whether an authentication request is in progress.
  const isAuthenticatingRef = useRef(false);
  useEffect(() => {
    if (isFirstLogin && !isAuthenticatingRef.current) {
      isAuthenticatingRef.current = true;
      // immediately login with the email and code from the URL
      // this will allow new users to see the new password form
      // on their first login
      handleLogIn(email, code, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    isLoggingIn,
    isLoggingInFederated,
    username,
    password,
    errors,
    showNewPassword,
    newPassword,
    newPasswordRepeat,
    invalidToken,
  } = state;

  return (
    <div id="login">
      <div className="background"></div>
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col xs={10} className="card-container">
            <Card className="my-5">
              <Card.Header>
                <ExcelraLogo fill="white" width="275" height="100%" />
              </Card.Header>
              <Card.Body>
                <div className="w-75 m-auto">
                  {showNewPassword || isFirstLogin ? (
                    <NewPasswordForm
                      newPassword={newPassword}
                      newPasswordRepeat={newPasswordRepeat}
                      invalidToken={invalidToken}
                      errors={errors}
                      isLoggingIn={isLoggingIn}
                      handleCompleteNewPassword={handleCompleteNewPassword}
                      setState={setState}
                    />
                  ) : (
                    <LoginForm
                      username={username}
                      password={password}
                      isLoggingInFederated={isLoggingInFederated}
                      isLoggingIn={isLoggingIn}
                      handleFederatedLogin={handleFederatedLogin}
                      handleLogIn={handleLogIn}
                      errors={errors}
                      setState={setState}
                    />
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
