import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import { CardTitle, ConfirmDelete, RefreshButton } from "components/common";
import { useIsMounted, useLambdaApi } from "hooks";

import Table from "./Table";
import CreateAccount from "./CreateAccount";

export default function CustomerAccounts() {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [deleteErrors, setDeleteErrors] = useState({});

  const getCustomerAccounts = useCallback(() => {
    setIsLoading(true);

    return api.execute("customer_account.list").then((customerAccounts) => {
      if (isMounted.current) {
        setIsLoading(false);
        setCustomerAccounts(customerAccounts);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomers = useCallback(() => {
    return api
      .execute("customer.list")
      .then((customers) => isMounted.current && setCustomers(customers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSelectedAccount = () => {
    setIsDeleting(true);

    return api
      .execute("customer_account.delete", {
        username: selectedAccount.username,
      })
      .then(() => {
        setShowConfirmDelete(false);
        setSelectedAccount({});
      })
      .catch((errors) => setDeleteErrors(errors))
      .finally(() => {
        getCustomerAccounts();
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    getCustomerAccounts();
    getCustomers();
  }, [getCustomerAccounts, getCustomers]);

  return (
    <Container fluid={true} className="page data-storage">
      <Row>
        <Col>
          <div className="page-title-box">
            <h3 className="page-title">Customer Accounts</h3>
          </div>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <CreateAccount
            getCustomerAccounts={getCustomerAccounts}
            customers={customers}
          ></CreateAccount>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="h-100">
            <Card.Body>
              <CardTitle title="Customer Accounts" showSpinner={isLoading}>
                <RefreshButton
                  size="sm"
                  onRefresh={getCustomerAccounts}
                ></RefreshButton>
              </CardTitle>
              <Table
                data={customerAccounts}
                onDelete={(account) => {
                  setSelectedAccount(account);
                  setShowConfirmDelete(true);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {showConfirmDelete && (
        <ConfirmDelete
          name={selectedAccount.username}
          resourceType="customer account"
          isDeleting={isDeleting}
          errors={deleteErrors}
          onCancel={() => {
            setShowConfirmDelete(false);
            setSelectedAccount({});
          }}
          onConfirm={() => deleteSelectedAccount()}
        />
      )}
    </Container>
  );
}
