import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";
import Toast from "react-bootstrap/Toast";

import { notificationsAtom, removeNotificationAtom } from "store";

import "./Notifications.scss";

const Notifications = () => {
  const notifications = useAtomValue(notificationsAtom);
  const removeNotification = useSetAtom(removeNotificationAtom);

  const createToast = useCallback(
    ({ id, title, message, autohide }) => {
      return (
        <Toast
          key={id}
          onClose={() => {
            removeNotification(id);
          }}
          delay={5000}
          autohide={autohide}
        >
          <Toast.Header>
            <strong>{title}</strong>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      );
    },
    [removeNotification]
  );

  const toasts = useMemo(() => {
    return notifications.map((notification) => createToast(notification));
  }, [notifications, createToast]);

  return <div className="notifications-container">{toasts}</div>;
};

export default Notifications;
