import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { sortObjectsAlfabetically, callPromisesSerial } from "utils";

import ShareBucketModal from "./ShareBucketModal";

export default function ShareBucketCustomers({
  bucket,
  customerAccounts,
  getBuckets,
  close,
}) {
  const api = useLambdaApi();
  const [isLoading, setIsLoading] = useState(false);
  const [toAdd, setToAdd] = useState({
    username: "",
    read: false,
    write: false,
  });
  const [shared, setShared] = useState([]);
  const [errors, setErrors] = useState({});
  const [addErrors, setAddErrors] = useState({});
  const isMounted = useIsMounted();

  useEffect(
    () =>
      bucket &&
      setShared(() =>
        // json used to deep copy object
        bucket.shared ? JSON.parse(JSON.stringify(bucket.shared)) : []
      ),
    [bucket]
  );

  let selectableOptions = customerAccounts.filter(
    (account) =>
      !shared.some((share) => share.username === account.username && share.mode)
  );

  selectableOptions = sortObjectsAlfabetically(selectableOptions, "customer");

  selectableOptions = selectableOptions.map((account, index) => (
    <option key={account.username + index} value={account.username}>
      {account.customer} - {account.username}
    </option>
  ));

  selectableOptions.unshift(
    <option key="choose" value="">
      Choose...
    </option>
  );

  const getShareMode = (customer) => {
    return customer.read && customer.write
      ? "rw"
      : customer.read && !customer.write
      ? "ro"
      : "wo";
  };

  const handleAdd = () => {
    setAddErrors({});
    const mode = getShareMode(toAdd);

    const existing = shared.find((share) => share.username === toAdd.username);
    if (existing) {
      existing.mode = mode;
      setShared(() => [...shared]);
    } else {
      const newShared = { username: toAdd.username, mode };
      setShared((prev) => [...prev, newShared]);
    }

    // RESET add customer form
    setToAdd({ username: "", read: false, write: false });
  };

  const handleRemove = (shareToRemove) => {
    shareToRemove.mode = null;
    setShared((shared) => [...shared]);
  };

  const handleShare = () => {
    setIsLoading(true);

    const apiCallFuncs = shared.map((share) => () => {
      if (share.mode) {
        return api.execute("s3.share_customer", {
          name: bucket.name,
          ...share,
        });
      } else {
        return api.execute("s3.unshare_customer", {
          name: bucket.name,
          username: share.username,
        });
      }
    });

    const results = callPromisesSerial(apiCallFuncs);

    results
      .then(() => getBuckets().finally(close))
      .catch((errors) => setErrors(errors))
      .finally(() => {
        isMounted.current && setIsLoading(false);
      });
  };

  const sharedWith = shared
    .filter((share) => share.mode)
    .map((share) => (
      <h6 key={share.username} className="d-inline-block mx-1">
        <Badge pill variant="primary" className="share-pill">
          <span>
            {share.username}:{share.mode}
          </span>
          <button
            size="sm"
            className="ml-2"
            onClick={() => handleRemove(share)}
          >
            <i className="bi bi-x-circle"></i>
          </button>
        </Badge>
      </h6>
    ));

  return (
    <ShareBucketModal
      addErrors={addErrors}
      bucket={bucket}
      close={close}
      errors={errors}
      handleAdd={handleAdd}
      handleShare={handleShare}
      isLoading={isLoading}
      selectableOptions={selectableOptions}
      setToAdd={setToAdd}
      shareWithCustomers={true}
      sharedWith={sharedWith}
      title="Share With Customers"
      toAdd={toAdd}
    ></ShareBucketModal>
  );
}
