import { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { applyDefaultFilters } from "../../../utils";
import { CardTitle, DefaultFilters, Filter } from "../../common";

export default function Filters({
  instances,
  volumes,
  filteredInstances,
  setFilteredInstances,
  filteredVolumes,
  setFilteredVolumes,
}) {
  const [filters, setFilters] = useState({
    user: [],
    customer: [],
    project: [],
    state: [],
  });

  const [instanceStates, setInstanceStates] = useState([]);

  const updateAvailableResources = () => {
    const availableInstanceStates = new Set();
    filteredInstances.forEach((instance) => {
      availableInstanceStates.add(instance.state);
    });
    setInstanceStates(Array.from(availableInstanceStates).sort());
  };

  useEffect(() => {
    setFilteredInstances(
      applyDefaultFilters(instances, filters).filter((instance) => {
        const allowedValue = filters.state[0];
        return allowedValue && instance.state !== allowedValue ? false : true;
      })
    );
    setFilteredVolumes(applyDefaultFilters(volumes, filters));
  }, [instances, volumes, filters, setFilteredInstances, setFilteredVolumes]);

  useEffect(() => {
    updateAvailableResources();
  }, [filteredInstances]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className="h-100 instances-card">
      <Card.Body>
        <CardTitle title="Filters" showSpinner={false}></CardTitle>
        <Form autoComplete="false">
          <Row>
            <DefaultFilters
              filteredResources={[...filteredInstances, ...filteredVolumes]}
              filters={filters}
              setFilters={setFilters}
              resetFilters={() =>
                setFilters({
                  user: [],
                  customer: [],
                  project: [],
                  state: [],
                })
              }
            >
              <Col>
                <Filter
                  filter="state"
                  name="Instance State"
                  placeholder="Choose an instance state..."
                  options={instanceStates}
                  filters={filters}
                  setFilters={setFilters}
                ></Filter>
              </Col>
            </DefaultFilters>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
