import { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import { useAtomValue } from "jotai";

import { IconButton, Table as CommonTable } from "components/common";
import constants from "constants";
import { userAtom } from "store";

export default function Table({
  data,
  onDelete,
  onShareBatchEnvironmentUsers,
  onNextflowConfig,
}) {
  const user = useAtomValue(userAtom);

  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Queue",
        accessor: "queue",
      },
      {
        Header: "Compute Environment",
        accessor: "type",
        Cell: ({ row }) => {
          switch (row.original.type) {
            case "spot":
              return "Spot";
            case "ondemand":
              return "On Demand";
            case "mixed":
              return "Mixed";
            default:
              return null;
          }
        },
      },
      {
        Header: "Customer",
        accessor: "customer",
      },
      {
        Header: "Project",
        accessor: "project",
      },
      {
        Header: "Users",
        accessor: "users",
        filter: "includes",
        Cell: ({ row }) =>
          row.original.users.map((share) => (
            <h6 key={`${row.original.name}.${share}`}>
              <Badge pill variant="primary" className="share-pill">
                {share}
              </Badge>
            </h6>
          )),
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          return (
            <>
              {constants.USE_NEXTFLOW && (
                <IconButton
                  title="show Nextflow Config"
                  icon="gear-fill"
                  className="mr-2"
                  onClick={() => onNextflowConfig(row.original)}
                ></IconButton>
              )}
              {user.canManage && (
                <IconButton
                  title="Share With Users"
                  icon="people-fill"
                  className="mr-2"
                  onClick={() => onShareBatchEnvironmentUsers(row.original)}
                ></IconButton>
              )}

              {user.canManage && (
                <IconButton
                  title="Delete"
                  icon="trash-fill"
                  onClick={() => onDelete(row.original)}
                ></IconButton>
              )}
            </>
          );
        },
      },
    ];

    return columns;
  }, [onShareBatchEnvironmentUsers, onDelete, onNextflowConfig, user]);

  return <CommonTable columns={columns} data={data} />;
}
