import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import { ButtonWithSpinner, ErrorMessages } from "../../common";

export default function ShareBatchEnvironmentModal({
  addErrors,
  batchEnvironment,
  close,
  errors,
  handleAdd,
  handleShare,
  isLoading,
  selectableOptions,
  setToAdd,
  sharedWith,
  title,
  toAdd,
}) {
  return (
    <Modal
      show
      size="lg"
      animation={false}
      onHide={close}
      className="share-batchEnvironment-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={Row} className="mb-4 form-group-plaintext">
          <Form.Label column sm="3">
            Batch Environment
          </Form.Label>
          <Col sm="9">
            <Form.Control
              plaintext
              readOnly
              defaultValue={batchEnvironment.name}
            />
          </Col>
        </Form.Group>

        <h6 className="text-bold font-weight-bold">Add User</h6>

        <Form.Group as={Row} className="mb-4">
          <Col>
            <Row>
              <Col>
                <Form.Label srOnly>User</Form.Label>
                <Form.Control
                  as="select"
                  custom
                  value={toAdd.username}
                  onChange={(e) => {
                    setToAdd((prev) => ({
                      ...prev,
                      username: e.target.value,
                    }));
                  }}
                >
                  {selectableOptions}
                </Form.Control>
              </Col>

              <Col sm="auto">
                <Button onClick={handleAdd} disabled={!toAdd.username}>
                  Add
                </Button>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col>
                {!!addErrors["mode"] && (
                  <p className="text-danger m-0">{addErrors["mode"]}</p>
                )}
              </Col>
            </Row>
          </Col>
        </Form.Group>

        <h6 className="text-bold font-weight-bold">Shared With</h6>

        <div className="share-pills-container">{sharedWith}</div>

        <ErrorMessages errors={errors}></ErrorMessages>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={close} disabled={isLoading}>
          Cancel
        </Button>
        <ButtonWithSpinner onClick={handleShare} isLoading={isLoading}>
          Submit
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
}
