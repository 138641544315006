const allowedVolumeSizes = {
  gp3: { min: 1, max: 16384 },
  st1: { min: 125, max: 16384 },
  sc1: { min: 125, max: 16384 },
};

const volumeStates = {
  available: {
    variant: "success",
    icon: "bi-check-circle",
  },
  "in-use": {
    variant: "success",
    icon: "bi-check-circle",
  },
  creating: {
    variant: "warning",
    icon: "bi-clock",
  },
  error: {
    variant: "danger",
    icon: "bi-clock",
  },
  deleting: {
    variant: "secondary",
    icon: "bi-clock",
  },
  deleted: {
    variant: "secondary",
    icon: "bi-dash-circle",
  },
};

const instanceStates = {
  running: {
    variant: "success",
    icon: "bi-check-circle",
  },
  pending: {
    variant: "warning",
    icon: "bi-clock",
  },
  initializing: {
    variant: "warning",
    icon: "bi-clock",
  },
  "cloud-init-failed": {
    variant: "danger",
    icon: "bi-clock",
  },
  "shutting-down": {
    variant: "secondary",
    icon: "bi-clock",
  },
  stopping: {
    variant: "secondary",
    icon: "bi-clock",
  },
  stopped: {
    variant: "secondary",
    icon: "bi-dash-circle",
  },
  impaired: {
    variant: "danger",
    icon: "bi-exclamation-triangle",
  },
  "insufficient-data": {
    variant: "danger",
    icon: "bi-exclamation-triangle",
  },
  "not-applicable": {
    variant: "danger",
    icon: "bi-exclamation-triangle",
  },
};

const isInstanceInIntermediateState = (state) =>
  ["pending", "shutting-down", "stopping", "initializing"].includes(state);

const isVolumeInIntermediateState = (state) =>
  ["creating", "deleting"].includes(state);

const getTotalInstanceCost = (instance) => {
  let total = instance.cost;
  if (instance.volumes) {
    instance.volumes.forEach((volume) => (total += volume.cost));
  }

  return total;
};

export {
  allowedVolumeSizes,
  volumeStates,
  instanceStates,
  isInstanceInIntermediateState,
  isVolumeInIntermediateState,
  getTotalInstanceCost,
};
