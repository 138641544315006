import { useState } from "react";

import { ConfirmDelete, Popup } from "components/common";
import { useIsMounted, useLambdaApi } from "hooks";

export default function DeleteCustomer({
  customer,
  projects,
  onCancel,
  onDeleted,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const deleteCustomer = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .execute("customer.delete", { customer })
      .then(onDeleted)
      .catch((error) => isMounted.current && setErrors(error.response.data))
      .finally(() => isMounted.current && setIsLoading(false));
  };

  return projects.length ? (
    <Popup
      title="Delete Customer"
      message="All projects have to be deleted before deleting a customer."
      onClose={onCancel}
    />
  ) : (
    <ConfirmDelete
      name={customer}
      resourceType="customer"
      isDeleting={isLoading}
      errors={errors}
      onCancel={onCancel}
      onConfirm={deleteCustomer}
    />
  );
}
