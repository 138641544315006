import { Form } from "react-bootstrap";

const CanManage = ({ user, updateCanManage }) => {
  const toggle = (e) => {
    e.preventDefault();
    updateCanManage(user, e.target.checked);
  };

  return user.is_admin ? (
    <h4>
      <i className="bi bi-check2"></i>
    </h4>
  ) : (
    <>
      <Form>
        <Form.Check
          id={`can-manage-switch-${user.id}`}
          type="switch"
          checked={user.can_manage}
          onChange={toggle}
        />
      </Form>
    </>
  );
};

export default CanManage;
