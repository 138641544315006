import { useState } from "react";
import { Button, Form, Col, Row, Modal } from "react-bootstrap";

import {
  ButtonWithSpinner,
  ErrorMessages,
  CustomerProjectSelector,
} from "components/common";
import { useIsMounted } from "hooks";

const CreateBucket = ({ createBucket, close }) => {
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [project, setProject] = useState("");
  const [customer, setCustomer] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    try {
      await createBucket(name, customer, project);
      close();
    } catch (errors) {
      isMounted.current && setErrors(errors);
    } finally {
      isMounted.current && setIsLoading(false);
    }
  };

  return (
    <Modal
      show
      animation={false}
      onHide={close}
      className="create-bucket-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Create Bucket</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Name
            </Form.Label>
            <Col sm="8">
              <Form.Control
                placeholder="Enter name"
                value={name}
                name="name"
                onChange={(e) => {
                  setName(e.target.value.toLowerCase());
                  setErrors({});
                }}
                isInvalid={!!errors.name}
                required
              />
              <Form.Text className="text-muted">
                Bucket names must be unique globally (in the whole AWS Cloud)
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <CustomerProjectSelector
            customer={customer}
            project={project}
            setCustomer={setCustomer}
            setProject={setProject}
            errors={errors}
            setErrors={setErrors}
          />

          <ErrorMessages
            errors={errors}
            keysToIgnore={["name", "project", "customer"]}
            className="mt-2 align-items-center"
            position="start"
          ></ErrorMessages>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <ButtonWithSpinner
            type="submit"
            isLoading={isLoading}
            disabled={!name || !customer || !project}
          >
            Create
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateBucket;
