import { useMemo } from "react";
import { IconButton, Table } from "../../common";

export default function ProjectsTable({ projects, onDelete }) {
  const columns = useMemo(
    () => [
      {
        Header: "Project",
        accessor: "name",
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => {
          return (
            <IconButton
              title="Delete"
              icon="trash-fill"
              onClick={() => onDelete(row.original.name)}
            ></IconButton>
          );
        },
      },
    ],
    [onDelete]
  );

  return (
    <Table
      columns={columns}
      data={projects}
      defaultPageSize={10}
      emptyMsg="No projects found"
      showHeaders={false}
    />
  );
}
