import InstanceVolumesTable from "./InstanceVolumesTable";
import InstanceDetailsTable from "./InstanceDetailsTable";

export default function InstanceSubTables({
  data: instance,
  instanceTypes,
  onDelete,
  onDetachVolume,
  onModifyVolume,
}) {
  if (!instance.volumes.length) {
    return false;
  }

  return (
    <>
      {!!instance && (
        <div>
          <h6 className="text-uppercase">Instance Details</h6>
          <InstanceDetailsTable
            instance={instance}
            instanceTypes={instanceTypes}
          />
        </div>
      )}
      {!!instance.volumes.length && (
        <div>
          <h6 className="text-uppercase">Volumes</h6>
          <InstanceVolumesTable
            instance={instance}
            volumes={instance.volumes}
            onDetach={onDetachVolume}
            onModify={onModifyVolume}
            onDelete={(volume) => onDelete(volume, "volume")}
          />
        </div>
      )}
    </>
  );
}
