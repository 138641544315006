import { useState } from "react";
import { Button, Form, Col, Row, Modal } from "react-bootstrap";

import {
  ButtonWithSpinner,
  ErrorMessages,
  CustomerProjectSelector,
} from "components/common";
import { useIsMounted, useLambdaApi } from "hooks";

const CreateBatchEnvironment = ({ getBatchEnvironments, close }) => {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState("");
  const [customer, setCustomer] = useState("");
  const [computeEnv, setComputeEnv] = useState("spot");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    api
      .execute("batch.create", {
        project: project,
        customer: customer,
        type: computeEnv,
      })
      .then(close)
      .catch((errors) => isMounted.current && setErrors(errors))
      .finally(() => {
        getBatchEnvironments();
        isMounted.current && setIsLoading(false);
      });
  };

  return (
    <Modal
      show
      animation={false}
      onHide={close}
      className="create-batchEnvironment-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Create Batch Environment</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <CustomerProjectSelector
            customer={customer}
            project={project}
            setCustomer={setCustomer}
            setProject={setProject}
            errors={errors}
            setErrors={setErrors}
          />

          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Compute Environment
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                value={computeEnv}
                name="computeEnv"
                required
                custom
                onChange={(e) => {
                  setComputeEnv(e.target.value);
                  setErrors({});
                }}
                isInvalid={!!errors.customer}
              >
                <option value="spot">Spot</option>
                <option value="ondemand">On Demand</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.type}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <ErrorMessages
            errors={errors}
            keysToIgnore={["name", "project", "customer"]}
            className="mt-2 align-items-center"
            position="start"
          ></ErrorMessages>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <ButtonWithSpinner
            type="submit"
            isLoading={isLoading}
            disabled={!customer || !project || !computeEnv}
          >
            Create
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateBatchEnvironment;
