import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export default function RefreshButton({
  onRefresh,
  size,
  disabled,
  className,
}) {
  return (
    <OverlayTrigger
      transition={false}
      overlay={<Tooltip id={`tooltip-top`}>Refresh</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <Button
          size={size}
          variant="secondary"
          className={`refresh-btn ${className}`}
          type="button"
          onClick={onRefresh}
          disabled={disabled}
          {...triggerHandler}
          ref={ref}
        >
          <i className="bi bi-arrow-clockwise"></i>
        </Button>
      )}
    </OverlayTrigger>
  );
}
