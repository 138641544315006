import { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useAtomValue } from "jotai";

import { CardTitle, ConfirmDelete, RefreshButton } from "components/common";
import { useIsMounted, useLambdaApi } from "hooks";
import constants from "constants";
import { userAtom } from "store";

import Filters from "./Filters";
import Table from "./Table";
import ShareBatchEnvironmentUsers from "./ShareBatchEnvironmentUsers";
import CreateBatchEnvironment from "./CreateBatchEnvironment";
import NextFlowModal from "./NextflowModal";

import "./batch.scss";

export default function BatchEnvironment() {
  const api = useLambdaApi();
  const user = useAtomValue(userAtom);
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [batchEnvironments, setBatchEnvironments] = useState([]);
  const [filteredBatchEnvironments, setFilteredBatchEnvironments] = useState(
    []
  );
  const [users, setUsers] = useState([]);
  const [nextflowConfig, setNextflowConfig] = useState("");
  const [selectedBatchEnvironment, setSelectedBatchEnvironment] = useState({});
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCreateBatchEnvironment, setShowCreateBatchEnvironment] =
    useState(false);
  const [showShareBatchEnvironmentUsers, setShowShareBatchEnvironmentUsers] =
    useState(false);
  const [deleteErrors, setDeleteErrors] = useState(null);

  const [showNextflowModal, setShowNextflowModal] = useState(false);

  const getBatchEnvironments = () => {
    setIsLoading(true);

    return api.execute("batch.list").then((batches) => {
      if (isMounted.current) {
        setIsLoading(false);
        setBatchEnvironments(batches);
      }
    });
  };

  const getUsers = () => {
    api
      .execute("user.list", { is_admin: false })
      .then((users) => isMounted.current && setUsers(users));
  };

  const getNextflowConfig = (batchEnvironment) => {
    return api
      .execute("batch.nextflow_config", {
        name: batchEnvironment.name,
      })
      .then((config) => {
        if (isMounted.current) {
          setIsLoading(false);
          setNextflowConfig(config);
        }
      });
  };

  const deleteSelectedBatchEnvironment = () => {
    setIsDeleting(true);
    setDeleteErrors({});

    return api
      .execute("batch.delete", { name: selectedBatchEnvironment.name })
      .then(() => {
        if (isMounted.current) {
          setSelectedBatchEnvironment({});
          setShowConfirmDelete(false);
        }
      })
      .catch((errors) => isMounted.current && setDeleteErrors(errors))
      .finally(() => {
        getBatchEnvironments();
        isMounted.current && setIsDeleting(false);
      });
  };

  useEffect(() => {
    getBatchEnvironments();
    getUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid={true} className="page batch-jobs">
      <Row>
        <Col>
          <div className="page-title-box">
            <h3 className="page-title">Batch</h3>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Filters
            batchEnvironments={batchEnvironments}
            filteredBatchEnvironments={filteredBatchEnvironments}
            setFilteredBatchEnvironments={setFilteredBatchEnvironments}
          ></Filters>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="h-100">
            <Card.Body>
              <CardTitle
                title="Batch Environment"
                subtitle={
                  constants.USE_NEXTFLOW && (
                    <span>
                      Make sure you follow the following steps in order to run
                      your jobs properly.
                      <br />
                      1. Click on the <i
                        className={`bi bi-gear-fill`}
                      ></i> icon{" "}
                      <b>
                        <i>(Nextflow Config)</i>
                      </b>{" "}
                      that will show you the required config files.
                      <br />
                      2. Copy the content of these files and save them as{" "}
                      <b>nextflow.config</b> and <b>batch_params.yml</b>
                      <br />
                      3. Copy these files to the directory where the Nextflow
                      command will be executed.
                      <br />
                      4. Execute nextflow as follows:{" "}
                      <code>
                        nextflow run [pipeline_folder]/main.nf -params-file
                        batch_params.yml
                      </code>
                    </span>
                  )
                }
                showSpinner={isLoading}
              >
                {!user.isAdmin && user.canManage && (
                  <Button
                    size="sm"
                    className="mr-2"
                    onClick={() => setShowCreateBatchEnvironment(true)}
                  >
                    Create Batch Environment
                  </Button>
                )}
                <RefreshButton
                  size="sm"
                  onRefresh={() => getBatchEnvironments()}
                ></RefreshButton>
              </CardTitle>
              <Table
                data={filteredBatchEnvironments}
                onDelete={(batchEnvironment) => {
                  setSelectedBatchEnvironment(batchEnvironment);
                  setShowConfirmDelete(true);
                }}
                onShareBatchEnvironmentUsers={(batchEnvironment) => {
                  setSelectedBatchEnvironment(batchEnvironment);
                  setShowShareBatchEnvironmentUsers(true);
                }}
                onNextflowConfig={(batchEnvironment) => {
                  setSelectedBatchEnvironment(batchEnvironment);
                  getNextflowConfig(batchEnvironment);
                  setShowNextflowModal(true);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {showConfirmDelete && (
        <ConfirmDelete
          name={selectedBatchEnvironment.name}
          resourceType="batch environment"
          isDeleting={isDeleting}
          errors={deleteErrors}
          onCancel={() => {
            setDeleteErrors({});
            setSelectedBatchEnvironment({});
            setShowConfirmDelete(false);
          }}
          onConfirm={() =>
            deleteSelectedBatchEnvironment(selectedBatchEnvironment)
          }
        />
      )}

      {showShareBatchEnvironmentUsers && (
        <ShareBatchEnvironmentUsers
          batchEnvironment={selectedBatchEnvironment}
          users={users}
          getBatchEnvironments={getBatchEnvironments}
          close={() => {
            setSelectedBatchEnvironment({});
            setShowShareBatchEnvironmentUsers(false);
          }}
        ></ShareBatchEnvironmentUsers>
      )}

      {showNextflowModal && (
        <NextFlowModal
          nextflowData={nextflowConfig}
          close={() => {
            setShowNextflowModal(false);
          }}
        ></NextFlowModal>
      )}

      {showCreateBatchEnvironment && (
        <CreateBatchEnvironment
          getBatchEnvironments={getBatchEnvironments}
          close={() => {
            setShowCreateBatchEnvironment(false);
          }}
        ></CreateBatchEnvironment>
      )}
    </Container>
  );
}
