import { ConfirmDelete } from "components/common";
import { useState } from "react";
import { useIsMounted } from "hooks";

const DeleteBucket = ({ bucket, deleteBucket, close }) => {
  const isMounted = useIsMounted();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState(null);

  const handleDeleteBucket = async () => {
    setIsDeleting(true);
    try {
      await deleteBucket(bucket);
      close();
    } catch (error) {
      isMounted.current && setDeleteErrors(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ConfirmDelete
      name={bucket.name}
      resourceType="bucket"
      isDeleting={isDeleting}
      errors={deleteErrors}
      onCancel={close}
      onConfirm={handleDeleteBucket}
    />
  );
};

export default DeleteBucket;
