import { useEffect } from "react";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";

import constants from "constants";

import useAuth from "./useAuth";

export default function useLambdaApi() {
  const { cognitoUser, refreshSession } = useAuth();

  const appPrefix = constants.IS_S3_APP ? "S3" : "MGMT";
  const lambdaFunction = constants[`LAMBDA_FUNCTION_${appPrefix}`];
  const identityPoolId = constants[`IDENTITY_POOL_ID_${appPrefix}`];
  const region = constants.AWS_REGION;

  const configureAWS = (region, userPoolId, jwtToken) => {
    const IDP = `cognito-idp.${region}.amazonaws.com/${userPoolId}`;
    AWS.config.region = region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
      Logins: {
        [IDP]: jwtToken,
      },
    });
    AWS.config.credentials.refresh();
  };

  const isConfigured = () => {
    if (AWS.config.region && AWS.config.credentials) {
      return true;
    } else {
      return false;
    }
  };

  const refreshCredentials = () => {
    return refreshSession().then((cognitoUser) => {
      configureAWS(
        region,
        cognitoUser.pool.userPoolId,
        cognitoUser.signInUserSession.idToken.jwtToken
      );

      return cognitoUser.signInUserSession.accessToken.jwtToken;
    });
  };

  const signoutAndReload = () => {
    Auth.signOut().then(() => {
      AWS.config.credentials = null;
      localStorage.clear();
      window.location.reload();
    });
  };

  const execute = (
    action,
    params = {},
    accessToken = null,
    isRetry = false
  ) => {
    accessToken =
      accessToken || cognitoUser.signInUserSession.accessToken.jwtToken;
    const lambda = new AWS.Lambda();
    return lambda
      .invoke({
        FunctionName: lambdaFunction,
        Payload: JSON.stringify({
          access_token: accessToken,
          action,
          params,
        }),
      })
      .promise()
      .then((response) => handleLambdaResponse(action, params, response))
      .catch((error) => handleLambdaError(action, params, isRetry, error));
  };

  const handleLambdaResponse = (action, params, response) => {
    const payload = JSON.parse(response.Payload);

    if (payload.statusCode === 200) {
      return payload.body;
    } else {
      if (payload.body && payload.body[""] === "Access token invalid") {
        return refreshCredentials().then((accessToken) =>
          execute(action, params, accessToken)
        );
      } else {
        return Promise.reject(payload.body);
      }
    }
  };

  const handleLambdaError = (action, params, isRetry, error) => {
    if (error && error.code === "CredentialsError") {
      if (isRetry) {
        return Promise.reject(error);
      }

      return refreshCredentials()
        .then((accessToken) => execute(action, params, accessToken, true))
        .catch((error) => {
          if (error && error.code === "CredentialsError") {
            signoutAndReload();
          }
          return Promise.reject(error);
        });
    } else {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (!cognitoUser || AWS.config.credentials) return;

    configureAWS(
      region,
      cognitoUser.pool.userPoolId,
      cognitoUser.signInUserSession.idToken.jwtToken
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser]);

  return {
    execute: (action, params) => execute(action, params),
    isConfigured,
  };
}
