import { useState, useEffect } from "react";

export default function useObjects(api, bucket, curPath, isMounted) {
  const [isLoading, setIsLoading] = useState(false);
  const [objects, setObjects] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const getObjects = () => {
    if (!bucket || curPath === null || bucket.mode === "wo") {
      setObjects([]);
      return;
    }
    setIsLoading(true);
    return api
      .execute("s3.list", { bucket: bucket.name, prefix: curPath })
      .then((objects) => isMounted.current && setObjects(objects))
      .finally(() => isMounted.current && setIsLoading(false));
  };

  const deleteObject = (object) => {
    return api.execute("s3.delete", {
      bucket: bucket.name,
      key: getKey(object),
    });
  };

  const deleteObjects = (objects) => {
    setIsDeleting(true);
    const promises = objects.map(deleteObject);

    return Promise.all(promises)
      .then(getObjects)
      .finally(() => setIsDeleting(false));
  };

  const getKey = (object) => {
    return curPath ? `${curPath}/${object.name}` : object.name;
  };

  useEffect(() => {
    getObjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bucket, curPath]);

  return { isLoading, objects, getObjects, isDeleting, deleteObjects };
}
