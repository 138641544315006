import { Col, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

export default function Filter({
  filter,
  name,
  placeholder,
  filters,
  setFilters,
  options,
}) {
  return (
    <Form.Group className="mb-0">
      <Form.Row>
        <Form.Label column xs="auto">
          {name}
        </Form.Label>
        <Col>
          <Typeahead
            clearButton
            id={`${filter}-filter`}
            s
            onChange={(selection) => {
              setFilters({ ...filters, [filter]: selection });
            }}
            options={options}
            placeholder={placeholder}
            selected={filters[filter]}
          ></Typeahead>
        </Col>
      </Form.Row>
    </Form.Group>
  );
}
