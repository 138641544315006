import { Modal, Button, Row, Col } from "react-bootstrap";

import { ErrorMessages, ButtonWithSpinner } from "components/common";

export default function ConfirmDelete({
  objects,
  isDeleting,
  onCancel,
  onConfirm,
  deleteWarning,
  errors,
}) {
  let name = "";

  if (objects.length === 1) {
    name = `'${objects[0].name}'`;
  } else if (objects.length > 1) {
    name = "the following objects";
  }

  return (
    <Modal
      show
      animation={false}
      onHide={onCancel}
      backdrop="static"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {deleteWarning && (
          <Row className="mb-3">
            <Col>
              <p className="mb-0">
                <i className="bi bi-exclamation-triangle mr-2"></i>
                {deleteWarning}
              </p>
            </Col>
          </Row>
        )}

        <p>Are you sure you want to delete {name}?</p>

        {objects.length > 1 && (
          <ul>
            {objects.map((object) => (
              <li key={object.name}>
                {object.name}
                {object.is_directory && "/"}
              </li>
            ))}
          </ul>
        )}

        <ErrorMessages errors={errors} className="mt-3"></ErrorMessages>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={onCancel} disabled={isDeleting}>
          Cancel
        </Button>
        <ButtonWithSpinner
          type="submit"
          block={false}
          onClick={onConfirm}
          isLoading={isDeleting}
        >
          Confirm
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
}
