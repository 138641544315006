import { useMemo } from "react";
import { Badge } from "react-bootstrap";
import { useAtomValue } from "jotai";

import { SubTable, IconButton, SvgButton } from "components/common";
import { roundToTwo } from "utils";
import { userAtom } from "store";
import { ReactComponent as DetachIcon } from "media/detach-icon.svg";

import { volumeStates } from "../utils";

export default function InstanceVolumesTable({
  instance,
  volumes,
  onDetach,
  onModify,
  onDelete,
}) {
  const user = useAtomValue(userAtom);
  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "State",
        accessor: "state",
        Cell: ({ value }) => (
          <h6 className="mb-0">
            <Badge
              pill
              variant={volumeStates[value].variant}
              className="state-pill"
            >
              <i className={`bi ${volumeStates[value].icon}`} />
              {value}
            </Badge>
          </h6>
        ),
      },
      {
        Header: "Mount Point",
        accessor: "mountpoint",
      },
      {
        Header: "Size (GiB)",
        accessor: "size",
      },
      {
        Header: "Type",
        accessor: "volume_type",
      },
      {
        Header: "Cost *",
        accessor: "cost",
        Cell: ({ value }) => `$${roundToTwo(value)}`,
      },
    ];

    if (user.canManage) {
      columns.push({
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          if (instance.state === "shutting-down") {
            return null;
          }
          return (
            <>
              <IconButton
                title="Modify"
                icon="pencil-fill"
                className={`${row.original.mountpoint !== "/" && "mr-2"}`}
                onClick={() => onModify(row.original)}
              ></IconButton>

              {row.original.mountpoint !== "/" && (
                <>
                  <SvgButton
                    title="Detach"
                    className="mr-2"
                    onClick={() => onDetach(row.original)}
                  >
                    <DetachIcon></DetachIcon>
                  </SvgButton>

                  <IconButton
                    title="Delete"
                    icon="trash-fill"
                    onClick={() => onDelete(row.original)}
                  ></IconButton>
                </>
              )}
            </>
          );
        },
      });
    }

    return columns;
  }, [onDetach, onModify, onDelete, instance.state, user]);

  return <SubTable columns={columns} data={volumes}></SubTable>;
}
