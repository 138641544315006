import { useMemo } from "react";
import { Badge } from "react-bootstrap";
import { SubTable } from "../../../common";

export default function InstanceDetailsTable({ instance, instanceTypes }) {
  const columns = useMemo(
    () => [
      {
        Header: "vCPUs",
        accessor: "instance_type",
        id: "vcpus",
        Cell: () => {
          let instanceType = instanceTypes.find(
            (instanceType) =>
              instanceType.instance_type === instance.instance_type
          );

          return instanceType ? `${instanceType.vcpus} vCPUs` : "";
        },
      },
      {
        Header: "Memory",
        accessor: "instance_type",
        id: "memory",
        Cell: () => {
          let instanceType = instanceTypes.find(
            (instanceType) =>
              instanceType.instance_type === instance.instance_type
          );

          return instanceType ? `${instanceType.memory} GiB` : "";
        },
      },
      {
        Header: "Instance Profile",
        accessor: "instance_profile",
        Cell: ({ value }) => (
          <h6>
            <Badge pill variant="primary" className="share-pill">
              {value}
            </Badge>
          </h6>
        ),
      },
      {
        Header: "Users",
        accessor: "users",
        Cell: ({ row }) => (
          <div className="d-flex">
            {row.original.users.map((share) => (
              <h6
                key={`${row.original.name}.${share}`}
                className="d-inline mr-1"
              >
                <Badge pill variant="primary" className="share-pill">
                  {share}
                </Badge>
              </h6>
            ))}
          </div>
        ),
      },
    ],

    [instance, instanceTypes]
  );

  return (
    <SubTable
      columns={columns}
      data={[instance]}
      className="instance-details-table"
    ></SubTable>
  );
}
