import { useEffect, useState } from "react";
import { Card, Form, Row } from "react-bootstrap";

import { applyDefaultFilters } from "../../../utils";
import { CardTitle, DefaultFilters } from "../../common";

export default function Filters({
  batchEnvironments,
  filteredBatchEnvironments,
  setFilteredBatchEnvironments,
}) {
  const [filters, setFilters] = useState({
    user: [],
    customer: [],
    project: [],
  });

  useEffect(() => {
    setFilteredBatchEnvironments(
      applyDefaultFilters(batchEnvironments, filters)
    );
  }, [batchEnvironments, filters, setFilteredBatchEnvironments]);

  return (
    <Card className="h-100 instances-card">
      <Card.Body>
        <CardTitle title="Filters" showSpinner={false}></CardTitle>
        <Form autoComplete="false">
          <Row>
            <DefaultFilters
              filteredResources={filteredBatchEnvironments}
              filters={filters}
              setFilters={setFilters}
              resetFilters={() =>
                setFilters({
                  user: [],
                  customer: [],
                  project: [],
                })
              }
            ></DefaultFilters>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
