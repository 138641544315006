import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import {
  ButtonWithSpinner,
  CardTitle,
  Popup,
  ErrorMessages,
} from "components/common";

import "./vpn.scss";

export default function Vpn() {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [configuration, setConfiguration] = useState("");
  const [isConfigured, setIsConfigured] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);

  const [generateErrors, setGenerateErrors] = useState({});

  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    api.execute("vpn.is_configured").then((response) => {
      if (isMounted.current) {
        setIsConfigured(response.configured);
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGenerateVpnConfig = () => {
    setIsGenerating(true);
    setGenerateErrors({});

    return api
      .execute("vpn.generate")
      .then((response) => {
        setConfiguration(response.configuration);
        setIsConfigured(true);
        setShowWarning(true);
      })
      .catch(setGenerateErrors)
      .finally(() => setIsGenerating(false));
  };

  const handleDownloadConfiguration = () => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(configuration)
    );
    element.setAttribute("download", "vpn-profile.conf");
    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
    element.remove();
  };

  let vpnConfig;
  if (isLoading) {
    vpnConfig = "";
  } else if (isConfigured && !configuration) {
    vpnConfig = `VPN configuration already generated. Ask an admin to delete the existing one to be able to generate a new configuration`;
  } else {
    vpnConfig = configuration;
  }

  return (
    <>
      <Container fluid={true} className="page vpn">
        <Row>
          <Col>
            <div className="page-title-box">
              <h3 className="page-title">VPN</h3>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <CardTitle
                  title="Vpn Profile"
                  subtitle={
                    <span>
                      <b>READ FIRST</b>
                      <br />
                      In order to reach your instances you will need to use a
                      VPN tunnel.
                      <br />
                      To do this you will first need to install WireGuard:
                      <a
                        href="https://www.wireguard.com/install/"
                        target="blank"
                      >
                        https://www.wireguard.com/install/
                      </a>
                      <br />
                      Then you can generate the WireGuard profile here. Make
                      sure to copy it to your WireGuard config immediately, as
                      it will no longer be available once you leave this page.
                      <br />
                      In order for Wireguard to load your profile, make sure
                      your configuration file has the '.conf' extension.
                      <br />
                      An admin can delete your existing profile so you can
                      generate a ew one if you ever need to reinstall your
                      computer. But you can only have one profile at any given
                      time.
                      <br />
                      If you need access on more than one computer you need to
                      put the same profile on both, and make sure to only
                      activate the VPN on one computer at the same time.
                    </span>
                  }
                  showSpinner={isLoading}
                >
                  <ButtonWithSpinner
                    className="mr-2"
                    disabled={isConfigured || isLoading}
                    isLoading={isGenerating}
                    onClick={handleGenerateVpnConfig}
                  >
                    Generate
                  </ButtonWithSpinner>
                </CardTitle>
                <Row>
                  <Col>
                    <div className="config-container">
                      <Form.Control
                        className="vpn-profile-box"
                        as="textarea"
                        rows={15}
                        value={vpnConfig}
                        readOnly
                      />
                    </div>
                  </Col>
                </Row>
                <ErrorMessages
                  errors={generateErrors}
                  className="mt-3"
                ></ErrorMessages>
                {configuration && (
                  <Row className="mt-3">
                    <Col>
                      <Button block onClick={handleDownloadConfiguration}>
                        Download Configuration File
                      </Button>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {showWarning && (
        <Popup
          message={
            <p>
              Make sure you copy/download the VPN profile before navigating away
              from this page. It will not be shown again.
            </p>
          }
          title="VPN Profile"
          onClose={() => setShowWarning(false)}
        ></Popup>
      )}
    </>
  );
}
