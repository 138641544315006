import { useEffect, useMemo, useState } from "react";
import { Modal, Form, Col, Button, Row } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import {
  ButtonWithSpinner,
  ErrorMessages,
  CustomerProjectSelector,
} from "components/common";
import { roundToTwo } from "utils";

import { allowedVolumeSizes } from "../utils";

export default function LaunchInstance({
  instanceTypes,
  volumeTypes,
  sshKeys,
  getResources,
  close,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();

  const [formData, setFormData] = useState({
    name: "",
    customer: "",
    project: "",
    key: "",
    instanceType: "",
    volumeType: "",
    size: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const allowedSize = useMemo(() => {
    if (formData.volumeType) {
      return allowedVolumeSizes[formData.volumeType];
    } else {
      return null;
    }
  }, [formData.volumeType]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    setErrors({});

    const { name, customer, project, key, instanceType, volumeType } = formData;

    const size = formData.size ? parseInt(formData.size, 10) : null;

    const instance = {
      name,
      customer,
      project,
      key,
      instance_type: instanceType,
      volume_type: volumeType || null,
      size,
    };

    try {
      await api.execute("ec2.instance.create", instance);
      close(instance);
    } catch (errors) {
      isMounted.current && setErrors(errors);
    } finally {
      getResources();
      isMounted.current && setIsLoading(false);
    }
  };

  const setFieldValue = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Reset errors when users change fields
    setErrors({});
  };

  const cost = useMemo(() => {
    const { instanceType, volumeType, size } = formData;

    const getInstanceCost = () => {
      let instanceCost = 0;
      if (instanceTypes.length && instanceType) {
        const type = instanceTypes.find(
          (t) => t.instance_type === instanceType
        );
        instanceCost = roundToTwo(type.cost);
      }

      return instanceCost;
    };

    const getVolumeCost = () => {
      let volumeCost = 0;
      if (volumeTypes.length) {
        const gp3Type = volumeTypes.find((t) => t.volume_type === "gp3");
        volumeCost = roundToTwo(gp3Type.cost * 75);

        if (volumeType && size) {
          const selectedVolumeType = volumeTypes.find(
            (t) => t.volume_type === volumeType
          );
          const extraCost = roundToTwo(selectedVolumeType.cost * size);
          volumeCost += extraCost;
        }
      }

      return volumeCost;
    };

    const instanceCost = getInstanceCost();
    const volumeCost = getVolumeCost();

    return {
      instance: instanceCost,
      volume: volumeCost,
      total: roundToTwo(instanceCost + volumeCost),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.instanceType, formData.volumeType, formData.size]);

  useEffect(() => {
    if (instanceTypes.length) {
      setFormData((prev) => ({
        ...prev,
        instanceType: instanceTypes[0].instance_type,
      }));
    }
  }, [instanceTypes]);

  return (
    <Modal
      show
      animation={false}
      onHide={close}
      size="lg"
      className="launch-instance-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Launch Instance</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {/* NAME */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Name
            </Form.Label>
            <Col sm="8">
              <Form.Control
                placeholder="Enter name"
                value={formData.name}
                name="name"
                onChange={(e) => setFieldValue("name", e.target.value)}
                isInvalid={!!errors.name}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <CustomerProjectSelector
            customer={formData.customer}
            project={formData.project}
            setCustomer={(value) => setFieldValue("customer", value)}
            setProject={(value) => setFieldValue("project", value)}
            errors={errors}
            setErrors={setErrors}
          />

          {/* SSH KEY */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              SSH Key
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                name="key"
                custom
                value={formData.key}
                onChange={(e) => setFieldValue("key", e.target.value)}
                required
                isInvalid={!!errors.key}
              >
                <option key="choose" value="">
                  Choose key
                </option>
                {sshKeys.map((key) => (
                  <option key={`key-${key.name}`} value={key.name}>
                    {key.name}
                  </option>
                ))}
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                {errors.key}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* INSTANCE TYPE */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Instance Type
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                name="instanceType"
                custom
                value={formData.instanceType}
                onChange={(e) => setFieldValue("instanceType", e.target.value)}
                required
                isInvalid={!!errors.instance_type}
              >
                {instanceTypes.map((instanceType) => (
                  <option
                    key={instanceType.instance_type}
                    value={instanceType.instance_type}
                  >
                    {`${instanceType.instance_type} ${instanceType.vcpus}
                    vCPUs, ${instanceType.memory}GiB, $${instanceType.cost}`}
                  </option>
                ))}
              </Form.Control>

              <Form.Control.Feedback type="invalid">
                {errors.instance_type}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* DEFAULT VOLUME */}
          <h6 className="text-bold font-weight-bold">Default Volume</h6>

          <Form.Group as={Row} className="mb-0 form-group-plaintext">
            <Form.Label column sm="3">
              Volume Type
            </Form.Label>
            <Form.Label column sm="9">
              gp3 (SSD)
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-0 form-group-plaintext">
            <Form.Label column sm="3">
              Volume Size
            </Form.Label>
            <Form.Label column sm="9">
              75 GiB
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 form-group-plaintext">
            <Form.Label column sm="3">
              Mount Point
            </Form.Label>
            <Form.Label column sm="9">
              /
            </Form.Label>
          </Form.Group>

          <Row className="justify-content-center my-4">
            <Col className="col-auto">
              <h6>
                <a
                  className="advanced-settings text-decoration-none"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAdvanced(!showAdvanced);
                    // reset volume fields
                    setFieldValue("volumeType", "");
                    setFieldValue("size", "");
                  }}
                >
                  <span className="d-flex align-items-center fw-bold">
                    {showAdvanced ? (
                      <i className="bi bi-caret-up-fill mr-1"></i>
                    ) : (
                      <i className="bi bi-caret-down-fill mr-1"></i>
                    )}
                    Advanced Settings
                  </span>
                </a>
              </h6>
            </Col>
          </Row>

          {showAdvanced && (
            <>
              <h6 className="text-bold font-weight-bold">
                Add Workspace Volume
              </h6>

              <p className="text-muted">
                If you need more space than the default 75GB root volume
                provides you can add another workspace volume here.
                <br />
                You can always add another volume at a later time, or increase
                the size of any existing volumes.
              </p>

              {/* VOLUME TYPE */}
              <Form.Group as={Row}>
                <Form.Label column sm="3">
                  Volume Type
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="select"
                    name="volumeType"
                    custom
                    value={formData.volumeType}
                    disabled={!formData.instanceType}
                    onChange={(e) => {
                      setFieldValue("volumeType", e.target.value);
                      if (!e.target.value) {
                        setFieldValue("size", "");
                      }
                    }}
                    isInvalid={!!errors.volume_type}
                  >
                    <option key="choose" value="">
                      Choose volume Type
                    </option>
                    {volumeTypes.map((volumeType) => (
                      <option
                        key={volumeType.volume_type}
                        value={volumeType.volume_type}
                      >
                        {volumeType.volume_type} - {volumeType.description}, $
                        {volumeType.cost}/GiB
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.volume_type}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* VOLUME SIZE */}
              <Form.Group as={Row} className="mb-0">
                <Form.Label column sm="3">
                  Volume Size
                </Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    placeholder="Enter size"
                    value={formData.size}
                    disabled={!formData.volumeType}
                    min={allowedSize?.min}
                    max={allowedSize?.max}
                    name="size"
                    onChange={(e) => {
                      let newSize = e.target.value;
                      const numericSize = parseInt(newSize, 10);
                      if (
                        !isNaN(numericSize) &&
                        numericSize > allowedSize.max
                      ) {
                        newSize = allowedSize.max.toString();
                      }
                      setFieldValue("size", newSize);
                    }}
                    isInvalid={!!errors.size}
                    required
                  />
                  <Form.Text className="text-muted">
                    {allowedSize?.min &&
                      `Choose volume size between ${allowedSize?.min} and  ${allowedSize?.max} GiB.`}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.size}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              {/* MOUNT POINT */}
              {formData.volumeType && (
                <Form.Group as={Row} className="mb-3 form-group-plaintext">
                  <Form.Label column sm="3">
                    Mount Point
                  </Form.Label>
                  <Form.Label column sm="9">
                    /mnt/workspace
                  </Form.Label>
                </Form.Group>
              )}
            </>
          )}

          <Row className="justify-content-end mt-5">
            <Col xs="auto text-right">
              <table>
                <tbody className="font-weight-bold">
                  <tr>
                    <td className="pr-3">Instance cost (while running):</td>
                    <td>${cost.instance}</td>
                  </tr>
                  <tr>
                    <td className="pr-3">Storage cost (continuously):</td>
                    <td>${cost.volume}</td>
                  </tr>
                  <tr className="total-monthly-cost">
                    <td className="pr-3">Total cost (monthly):</td>
                    <td>${cost.total}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          {/* GENERIC ERRORS */}
          <ErrorMessages
            errors={errors}
            keysToIgnore={[
              "name",
              "customer",
              "project",
              "key",
              "instance_type",
              "volume_type",
              "size",
            ]}
            className="mt-5"
          ></ErrorMessages>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <ButtonWithSpinner
            type="submit"
            isLoading={isLoading}
            disabled={
              !formData.name ||
              !formData.instanceType ||
              !formData.customer ||
              !formData.project ||
              !formData.key
            }
          >
            Launch
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
