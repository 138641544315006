const constants = {
  LOGIN_WITHOUT_SSO: "aws_mgmt_login_without_sso",
  SIDEBAR_LS_KEY: "aws_mgmt_sidebar_collapsed",
  IS_S3_APP: process.env["REACT_APP_APP_TYPE"] === "S3",
  ADMIN_USER_POOL_ID: process.env["REACT_APP_ADMIN_USER_POOL_ID"],
  ADMIN_USER_POOL_WEB_CLIENT_ID:
    process.env["REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID"],
  CUSTOMER_USER_POOL_ID: process.env["REACT_APP_CUSTOMER_USER_POOL_ID"],
  ADMIN_USER_POOL_DOMAIN: process.env["REACT_APP_ADMIN_USER_POOL_DOMAIN"],
  CUSTOMER_USER_POOL_WEB_CLIENT_ID:
    process.env["REACT_APP_CUSTOMER_USER_POOL_WEB_CLIENT_ID"],
  CUSTOMER_USER_POOL_DOMAIN: process.env["REACT_APP_CUSTOMER_USER_POOL_DOMAIN"],
  EMPLOYEE_USER_POOL_ID: process.env["REACT_APP_EMPLOYEE_USER_POOL_ID"],
  EMPLOYEE_USER_POOL_WEB_CLIENT_ID:
    process.env["REACT_APP_EMPLOYEE_USER_POOL_WEB_CLIENT_ID"],
  EMPLOYEE_USER_POOL_DOMAIN: process.env["REACT_APP_EMPLOYEE_USER_POOL_DOMAIN"],
  IDENTITY_POOL_ID_S3: process.env["REACT_APP_IDENTITY_POOL_ID_S3"],
  IDENTITY_POOL_ID_MGMT: process.env["REACT_APP_IDENTITY_POOL_ID_MGMT"],
  AWS_REGION: process.env["REACT_APP_REGION"],
  LAMBDA_FUNCTION_MGMT: process.env["REACT_APP_LAMBDA_FUNCTION_MGMT"],
  LAMBDA_FUNCTION_S3: process.env["REACT_APP_LAMBDA_FUNCTION_S3"],
  USE_NEXTFLOW: process.env["REACT_APP_USE_NEXTFLOW"] === "true",
};

export default constants;
