import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { FormModal } from "components/common";

export default function CreateProject({ customer, onCreated }) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();

  const [show, setShow] = useState(false);
  const [project, setProject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const createProject = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    api
      .execute("project.create", { project, customer })
      .then(onCreated)
      .then(() => {
        if (isMounted.current) {
          setProject("");
          setShow(false);
        }
      })
      .catch((error) => isMounted.current && setErrors(error.response.data))
      .finally(() => isMounted.current && setIsLoading(false));
  };

  return (
    <>
      <Button size="sm" onClick={() => setShow(true)} disabled={!customer}>
        Create Project
      </Button>

      {show && (
        <FormModal
          onSubmit={createProject}
          onClose={() => {
            setShow(false);
          }}
          errors={errors}
          errorKeysToIgnore={["customer", "project"]}
          isLoading={isLoading}
          title="Create Project"
          submitText="Create"
        >
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Customer
            </Form.Label>
            <Col sm="10">
              <Form.Label column>{customer}</Form.Label>
              <Form.Control.Feedback type="invalid">
                {errors.customer}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Project
            </Form.Label>
            <Col sm="10">
              <Form.Control
                placeholder="Enter project"
                value={project}
                name="name"
                onChange={(e) => {
                  setProject(e.target.value);
                }}
                isInvalid={!!errors.project}
                required
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                {errors.project}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </FormModal>
      )}
    </>
  );
}
