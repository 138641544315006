import { useState } from "react";
import { Modal, Form, Row, Col, Button, InputGroup } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { ButtonWithSpinner, ErrorMessages } from "components/common";
import { roundToTwo } from "utils";

import { allowedVolumeSizes } from "../utils";

export default function AddVolume({
  instance,
  volumeTypes,
  getResources,
  close,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [name, setName] = useState("");
  const [volumeType, setVolumeType] = useState("");
  const [mountpoint, setMountpoint] = useState("");
  const [size, setSize] = useState(0);
  const [allowedSize, setAllowedSize] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    api
      .execute("ec2.volume.create", {
        instance_id: instance.instance_id,
        name,
        mountpoint: `/mnt/${mountpoint}`,
        customer: instance.customer,
        project: instance.project,
        volume_type: volumeType,
        size: parseInt(size),
      })
      .then(() => close(true))
      .catch((errors) => setErrors(errors))
      .finally(() => {
        getResources();
        isMounted.current && setIsLoading(false);
      });
  };

  const calculateCost = () => {
    if (volumeTypes.length && volumeType && size) {
      const cost = volumeTypes.find(
        (type) => type.volume_type === volumeType
      ).cost;
      return roundToTwo(cost * size);
    }

    return roundToTwo(0);
  };

  return (
    <Modal
      show
      animation={false}
      onHide={close}
      className="launch-instance-modal"
      backdrop="static"
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Add Volume</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <p>
            <i className="bi bi-exclamation-triangle mr-2"></i>It might be
            necessary to reboot the instance before newly added volumes can be
            mounted.
          </p>
          <Form.Group as={Row} className="mb-3 form-group-plaintext">
            <Form.Label column sm="3">
              Instance
            </Form.Label>
            <Form.Label column sm="9">
              {instance.name}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 form-group-plaintext">
            <Form.Label column sm="3">
              Customer
            </Form.Label>
            <Form.Label column sm="9">
              {instance.customer}
            </Form.Label>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 form-group-plaintext">
            <Form.Label column sm="3">
              Project
            </Form.Label>
            <Form.Label column sm="9">
              {instance.project}
            </Form.Label>
          </Form.Group>

          {/* NAME */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Volume Name
            </Form.Label>
            <Col sm="8">
              <Form.Control
                placeholder="Enter name"
                value={name}
                name="name"
                onChange={(e) => {
                  setName(e.target.value);
                  setMountpoint(e.target.value.replace(/\s+/g, ""));
                  setErrors({});
                }}
                isInvalid={!!errors.name}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* MOUNTPOINT */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Mount point
            </Form.Label>
            <Col sm="8">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>/mnt/</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  placeholder="Enter mount point"
                  value={mountpoint}
                  name="mountpoint"
                  onChange={(e) => {
                    const mountpoint = e.target.value;
                    setErrors({});

                    if (mountpoint === "/") {
                      setMountpoint("");
                    } else {
                      setMountpoint(mountpoint);
                    }
                  }}
                  isInvalid={!!errors.mountpoint}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mountpoint}
                </Form.Control.Feedback>
              </InputGroup>
            </Col>
          </Form.Group>

          {/* VOLUME TYPES */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Volume Type
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                name="volumeType"
                custom
                value={volumeType}
                onChange={(e) => {
                  setVolumeType(e.target.value);
                  setAllowedSize(allowedVolumeSizes[e.target.value] || {});
                  if (!e.target.value) {
                    setSize(0);
                  }
                  setErrors({});
                }}
                isInvalid={!!errors.volume_type}
              >
                <option key="choose" value="">
                  Choose volume Type
                </option>
                {volumeTypes.map((volumeType) => (
                  <option
                    key={volumeType.volume_type}
                    value={volumeType.volume_type}
                  >
                    {volumeType.volume_type} - {volumeType.description}, $
                    {volumeType.cost}/GiB
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.volume_type}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* VOLUME SIZE */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Volume Size
            </Form.Label>
            <Col sm="8">
              <Form.Control
                type="number"
                placeholder="Enter size"
                value={size}
                disabled={!volumeType}
                min={allowedSize.min}
                max={allowedSize.max}
                name="size"
                onChange={(e) => {
                  let newSize = e.target.value;
                  newSize =
                    newSize <= allowedSize.max ? newSize : allowedSize.max;
                  setSize(newSize);
                  setErrors({});
                }}
                isInvalid={!!errors.size}
                required
              />
              <Form.Text className="text-muted">
                {allowedSize.min &&
                  `Choose volume size between ${allowedSize.min} and  ${allowedSize.max} GiB.`}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {errors.size}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* COST */}
          <Row className="justify-content-end mt-5">
            <Col xs="auto text-right">
              <table>
                <tbody className="font-weight-bold">
                  <tr>
                    <td className="pr-3">Cost (monthly):</td>
                    <td>${calculateCost()}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          {/* GENERIC ERRORS */}
          <ErrorMessages
            errors={errors}
            keysToIgnore={["name", "mountpoint", "volume_type", "size"]}
            className="mt-3"
          ></ErrorMessages>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <ButtonWithSpinner type="submit" isLoading={isLoading}>
            Add
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
