import { atom } from "jotai";

export const cognitoUserAtom = atom(null);
export const userAtom = atom(null);
export const apiAtom = atom(null);
export const sidebarCollapsedAtom = atom(false);
export const authStateAtom = atom({
  isLoggedIn: false,
  isAuthenticating: true,
});

export const notificationsAtom = atom([]);
export const addNotificationAtom = atom(null, (get, set, notification) => {
  if (!notification.id) {
    notification.id = Math.random().toString(36).slice(2, 11);
  }

  // If the notification already exists, don't add it again
  const existingNotification = get(notificationsAtom).find(
    (n) => n.id === notification.id
  );

  if (existingNotification) return;

  set(notificationsAtom, [...get(notificationsAtom), notification]);
});

export const removeNotificationAtom = atom(null, (get, set, id) => {
  const notifications = get(notificationsAtom);
  const updatedNotifications = notifications.filter(
    (notification) => notification.id !== id
  );
  set(notificationsAtom, updatedNotifications);
});
