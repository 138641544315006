import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./IconButton.scss";

export default function IconButton({
  icon,
  onClick,
  title,
  disabled,
  className = null,
}) {
  return (
    <OverlayTrigger
      placement="top"
      transition={false}
      overlay={<Tooltip>{title}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div
          className={`icon-btn-container d-inline-block ${
            className ? className : ""
          }`}
          disabled={disabled}
          {...triggerHandler}
          ref={ref}
        >
          <a
            href="/"
            className="icon-btn"
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
            disabled={disabled}
          >
            <i className={`bi bi-${icon}`}></i>
          </a>
        </div>
      )}
    </OverlayTrigger>
  );
}
