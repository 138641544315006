import { useEffect, useState } from "react";
import { roundToTwo } from "../../../../utils";
import { ButtonWithSpinner } from "../../../common";

export default function CalculateTotalCost({ instances }) {
  const [cost, setCost] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCalculateCost = (e) => {
    setIsLoading(true);

    let total = 0;

    instances.forEach((instance) => {
      total += instance.cost;

      instance.volumes.forEach((volume) => {
        total += volume.cost;
      });
    });

    setCost(roundToTwo(total));
    setIsLoading(false);
  };

  const reset = () => {
    setCost(null);
    setError(null);
    setIsLoading(false);
  };

  useEffect(() => {
    reset();
  }, [instances]);

  return (
    <span className="mr-2">
      {error ? (
        <span className="text-danger">{error}</span>
      ) : cost !== null ? (
        <span>{`${cost}$/month`}</span>
      ) : (
        <ButtonWithSpinner
          isLoading={isLoading}
          disabled={!instances.length}
          size="sm"
          onClick={handleCalculateCost}
        >
          Calculate Cost
        </ButtonWithSpinner>
      )}
    </span>
  );
}
