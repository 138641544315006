import { useState, useEffect } from "react";
import { Card, Form, Col } from "react-bootstrap";

import { ButtonWithSpinner, CardTitle, ErrorMessages } from "components/common";
import { useLambdaApi } from "hooks";

const CreateAccount = ({ customers, getCustomerAccounts }) => {
  const api = useLambdaApi();
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [customer, setCustomer] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => setIsLoading(false), [customers]);

  const formReset = () => {
    setErrors({});
    setEmail("");
    setCustomer("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    api
      .execute("customer_account.create", {
        email: email,
        customer: customer,
      })
      .then(() => formReset())
      .catch((errors) => setErrors(errors))
      .finally(() => {
        getCustomerAccounts();
        setIsLoading(false);
      });
  };

  const customerOptions = customers.map((customer, index) => (
    <option key={customer + index} value={customer}>
      {customer}
    </option>
  ));

  customerOptions.unshift(
    <option key="choose" value="">
      Choose Customer
    </option>
  );

  return (
    <Card>
      <Card.Body>
        <CardTitle title="Create Accounts" showSpinner={false} />

        <Form onSubmit={handleSubmit}>
          <Form.Row className="d-flex align-items-center">
            <Col>
              <Form.Row>
                <Col>
                  <Form.Label srOnly>Email</Form.Label>
                  <Form.Control
                    placeholder="Enter email"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({});
                    }}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Col>

                <Col>
                  <Form.Label srOnly>Customer</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    name="customer"
                    value={customer}
                    onChange={(e) => {
                      setCustomer(e.target.value);
                      setErrors({});
                    }}
                    required
                    isInvalid={!!errors.customer}
                  >
                    {customerOptions}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.customer}
                  </Form.Control.Feedback>
                </Col>
              </Form.Row>
            </Col>
            <Col xs="auto">
              <ButtonWithSpinner type="submit" isLoading={isLoading} block>
                Create
              </ButtonWithSpinner>
            </Col>
          </Form.Row>

          <ErrorMessages
            errors={errors}
            keysToIgnore={["email", "customer"]}
            position="start"
            className="mt-2"
          ></ErrorMessages>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CreateAccount;
