import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useAtomValue } from "jotai";

import { CardTitle, ConfirmDelete, RefreshButton } from "components/common";
import { useIsMounted, useLambdaApi } from "hooks";
import { userAtom } from "store";

import Generate from "./Generate";
import Import from "./Import";
import Table from "./Table";

import "./sshkeys.scss";

export default function SshKeys() {
  const api = useLambdaApi();
  const user = useAtomValue(userAtom);

  const isMounted = useIsMounted();
  const [sshKeys, setSshKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState({});

  const getSshKeys = useCallback(() => {
    setIsLoading(true);
    return api.execute("ec2.keypair.list").then((keys) => {
      if (isMounted.current) {
        setSshKeys(keys);
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSshKey = (name) => {
    setIsDeleting(true);

    return api
      .execute("ec2.keypair.delete", { name })
      .then(() => {
        if (isMounted.current) {
          setKeyToDelete(null);
          setShowConfirmDelete(false);
        }
      })
      .catch((errors) => setDeleteErrors(errors))
      .finally(() => {
        getSshKeys();
        isMounted.current && setIsDeleting(false);
      });
  };

  useEffect(() => {
    getSshKeys();
  }, [getSshKeys]);

  return (
    <Container fluid={true} className="page ssh-keys">
      <Row>
        <Col>
          <div className="page-title-box">
            <h3 className="page-title">SSH Keys</h3>
          </div>
        </Col>
      </Row>

      {!user.isAdmin && (
        <Row className="mb-4">
          <Col span={6}>
            <Generate getSshKeys={getSshKeys} />
          </Col>

          <Col span={6}>
            <Import getSshKeys={getSshKeys} />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Card className="h-100">
            <Card.Body>
              <CardTitle
                title="SSH Keys"
                showSpinner={isLoading}
                subtitle={
                  <span>Default login user for all instances is ubuntu.</span>
                }
              >
                <RefreshButton size="sm" onRefresh={getSshKeys}></RefreshButton>
              </CardTitle>
              <Table
                data={sshKeys}
                onDelete={(key) => {
                  setKeyToDelete(key);
                  setShowConfirmDelete(true);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {showConfirmDelete && (
        <ConfirmDelete
          name={keyToDelete}
          resourceType="SSH key"
          isDeleting={isDeleting}
          errors={deleteErrors}
          onCancel={() => {
            setKeyToDelete(null);
            setShowConfirmDelete(false);
          }}
          onConfirm={() => deleteSshKey(keyToDelete)}
        />
      )}
    </Container>
  );
}
