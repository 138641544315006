import { Navigate, Route, Routes } from "react-router-dom";
import { useAtomValue } from "jotai";

import { userAtom } from "store";
import {
  BatchEnvironment,
  CustomerAccounts,
  DataStorage,
  SshKeys,
  Login,
  ProjectNodes,
  Users,
  Vpn,
  Buckets,
  ResetPassword,
  ResetPasswordConfirm,
  Projects,
} from "components/pages";
import constants from "constants";

export function AppRoutes({ buckets }) {
  const user = useAtomValue(userAtom);

  if (constants.IS_S3_APP) {
    return (
      <Routes>
        <Route path="/buckets/" element={<Buckets buckets={buckets} />} />
        <Route
          path="*"
          element={
            <Navigate
              to={`/buckets/${buckets[0] ? `?bucket=${buckets[0].name}` : ""}`}
            />
          }
        />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/project-nodes/" element={<ProjectNodes />} />
        <Route path="/data-storage/" element={<DataStorage />} />
        <Route path="/batch/" element={<BatchEnvironment />} />
        <Route path="/ssh-keys/" element={<SshKeys />} />
        <Route path="/vpn/" element={<Vpn />} />
        {user.isAdmin && (
          <>
            <Route path="/customer-accounts/" element={<CustomerAccounts />} />
            <Route path="/users/" element={<Users />} />
            <Route path="/projects/" element={<Projects />} />
          </>
        )}
        <Route path="*" element={<Navigate to="/project-nodes/" />} />
      </Routes>
    );
  }
}

export function AuthRoutes() {
  return (
    <Routes>
      <Route path="/login/" element={<Login />} />
      <Route path="/reset-password/" element={<ResetPassword />} />
      <Route
        path="/reset-password-confirm/"
        element={<ResetPasswordConfirm />}
      />
      <Route path="*" element={<Navigate to="/login/" />} />
    </Routes>
  );
}
