import React, {
  useState,
  cloneElement,
  Fragment,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Row, Col, Table as BTable } from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
} from "react-table";

import TableFilter from "./TableFilter";
import TableRows from "./TableRows";
import TableInfo from "./TableInfo";
import TablePagination from "./TablePagination";
import SelectionCheckbox from "./SelectionCheckbox.js";

import "./table.scss";

const Table = forwardRef(
  (
    {
      columns,
      hiddenColumns = [],
      data,
      children,
      emptyMsg = null,
      subTableDataFields,
      defaultPageSize,
      setFilteredRows = null,
      enableRowSelection = false,
      enableSelectOnClick = false,
      enableMultiRowSelection = false,
      selectedRows = null,
      setSelectedRows = null,
      onRowClick = null,
      showHeaders = true,
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      setGlobalFilter,
      page,
      canPreviousPage,
      canNextPage,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      pageOptions,
      rows,
      selectedFlatRows,
      toggleAllPageRowsSelected,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        state: { selectedFlatRows: selectedRows },
        initialState: {
          hiddenColumns,
          pageIndex: 0,
          pageSize: defaultPageSize || 25,
        },
        autoResetGlobalFilter: false,
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        enableMultiRowSelection &&
          hooks.visibleColumns.push((columns) => [
            // Let's make a column for selection
            {
              id: "selection",
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div>
                  <SelectionCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }) => (
                <div>
                  <SelectionCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
            ...columns,
          ]);
      }
    );

    const toggleRowOpen = (id) => {
      if (open === id) {
        setOpen(false);
      } else {
        setOpen(id);
      }
    };

    const handleRowClick = (row) => {
      if (enableSelectOnClick) {
        !enableMultiRowSelection && toggleAllPageRowsSelected(false);
        row.toggleRowSelected(!row.isSelected);
        onRowClick(row.original);
      }
    };

    useEffect(() => {
      !!setFilteredRows &&
        setFilteredRows((prev) =>
          prev.length !== rows.length ? rows.map((row) => row.original) : prev
        );
    }, [rows, setFilteredRows]);

    useEffect(() => {
      !!setSelectedRows &&
        setSelectedRows(selectedFlatRows.map((row) => row.original));
    }, [setSelectedRows, selectedFlatRows]);

    useImperativeHandle(ref, () => ({
      expandRow: (resource, key) => {
        const foundRow = rows.find(
          (row) => row.original[key] === resource[key]
        );
        !!foundRow && setOpen(foundRow.id);
      },
      resetSelection: () => toggleAllPageRowsSelected(false),
      selectRow: (key, value) => {
        const foundRow = rows.find((row) => row.original[key] === value);
        !!foundRow && foundRow.toggleRowSelected(true);
      },
    }));

    return (
      <div className="common-table">
        <Row>
          <Col className="my-auto" xs="12" lg="6">
            <TableRows pageSize={pageSize} setPageSize={setPageSize} />
          </Col>
          <Col className="my-auto" xs="12" lg="6">
            <TableFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            ></TableFilter>
          </Col>
        </Row>
        <Row>
          <Col>
            <BTable
              responsive
              hover={enableRowSelection || enableMultiRowSelection}
              className="table-centered my-2"
              {...getTableProps()}
            >
              {showHeaders && (
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {!!children && <th className="row-expand"></th>}
                      {headerGroup.headers.map((column) => {
                        return (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className={`${column.id} ${
                              column.canSort && "sortable"
                            } ${
                              column.isSortedDesc
                                ? "desc"
                                : column.isSorted
                                ? "asc"
                                : ""
                            }`}
                          >
                            {column.render("Header")}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
              )}
              <tbody {...getTableBodyProps}>
                {(page.length &&
                  page.map((row) => {
                    prepareRow(row);
                    const hasSubTableData =
                      row.original &&
                      subTableDataFields &&
                      subTableDataFields.some(
                        (field) =>
                          row.original[field] && row.original[field].length
                      );

                    return (
                      <Fragment key={row.id}>
                        <tr
                          {...row.getRowProps()}
                          key={row.id}
                          onClick={() => handleRowClick(row)}
                          className={`${row.isSelected ? "selected" : ""} ${
                            enableSelectOnClick ? "clickable" : ""
                          }`}
                        >
                          {!!children && (
                            <td>
                              {hasSubTableData && (
                                <span
                                  id={row.id}
                                  className="clickable row-expand"
                                  onClick={() => toggleRowOpen(row.id)}
                                >
                                  {open === row.id ? (
                                    <i className="bi bi-caret-down-fill"></i>
                                  ) : (
                                    <i className="bi bi-caret-right-fill"></i>
                                  )}
                                </span>
                              )}
                            </td>
                          )}
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className={cell.column.id}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                        {open === row.id && hasSubTableData && (
                          <tr colSpan={row.cells.length + 1}>
                            <td></td>
                            <td colSpan={row.cells.length}>
                              {cloneElement(children, { data: row.original })}
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    );
                  })) || (
                  <tr>
                    <td
                      className="text-center"
                      colSpan={
                        enableMultiRowSelection
                          ? columns.length + 1
                          : columns.length
                      }
                    >
                      {emptyMsg ? emptyMsg : "No records available"}
                    </td>
                  </tr>
                )}
              </tbody>
            </BTable>
          </Col>
        </Row>

        {!!data.length && (
          <Row className="mt-2">
            {pageOptions.length > 1 && (
              <>
                <Col className="my-auto" xs="12" lg="auto">
                  <TableInfo
                    rowCount={page.length}
                    totalRowCount={rows.length}
                  />
                </Col>

                <Col className="my-auto" xs="12" lg>
                  <TablePagination
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    pageOptions={pageOptions}
                    pageIndex={pageIndex}
                  />
                </Col>
              </>
            )}
          </Row>
        )}
      </div>
    );
  }
);

export default Table;
