import { Form, Modal, Button } from "react-bootstrap";
import ErrorMessages from "./ErrorMessages";
import ButtonWithSpinner from "./ButtonWithSpinner";

const FormModal = ({
  onSubmit,
  onClose,
  errors,
  errorKeysToIgnore = [],
  isLoading,
  title,
  children,
  cancelText = "Cancel",
  submitText = "Submit",
}) => {
  const hasErrors = Object.keys(errors).length;

  return (
    <Modal show={true} animation={false} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={onSubmit}>
        <Modal.Body>
          {children}
          <ErrorMessages
            errors={errors}
            keysToIgnore={errorKeysToIgnore}
            className="mt-3"
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={onClose}>
            {cancelText}
          </Button>
          <ButtonWithSpinner
            type="submit"
            isLoading={isLoading}
            disabled={hasErrors}
          >
            {submitText}
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FormModal;
