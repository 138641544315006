import { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useAtomValue } from "jotai";

import { CardTitle, RefreshButton } from "components/common";
import { userAtom } from "store";

import Table from "./Table";
import CreateBucket from "./CreateBucket";
import Filters from "./Filters";
import ShareBucketCustomers from "./ShareBucketCustomers";
import ShareBucketUsers from "./ShareBucketUsers";
import { useDataStorage } from "./useDataStorage";
import DeleteBucket from "./DeleteBucket";
import RequestTempCreds from "./RequestTempCreds";

import "./datastorage.scss";

export default function DataStorage() {
  const {
    buckets,
    customerAccounts,
    users,
    isLoading,
    calculateCost,
    fetchBuckets,
    deleteBucket,
    createBucket,
  } = useDataStorage();
  const user = useAtomValue(userAtom);
  const [filteredBuckets, setFilteredBuckets] = useState([]);
  const [selectedBucket, setSelectedBucket] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCreateBucket, setShowCreateBucket] = useState(false);
  const [showShareBucketCustomers, setShowShareBucketCustomers] =
    useState(false);
  const [showShareBucketUsers, setShowShareBucketUsers] = useState(false);
  const [showRequestTempCreds, setShowRequestTempCreds] = useState(false);

  return (
    <Container fluid={true} className="page data-storage">
      <Row>
        <Col>
          <div className="page-title-box">
            <h3 className="page-title">Data Storage</h3>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Filters
            buckets={buckets}
            filteredBuckets={filteredBuckets}
            setFilteredBuckets={setFilteredBuckets}
          ></Filters>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card className="h-100">
            <Card.Body>
              <CardTitle title="Buckets" showSpinner={isLoading}>
                {!user.isAdmin && user.canManage && (
                  <Button
                    size="sm"
                    className="mr-2"
                    onClick={() => setShowCreateBucket(true)}
                  >
                    Create Bucket
                  </Button>
                )}
                <RefreshButton
                  size="sm"
                  onRefresh={fetchBuckets}
                ></RefreshButton>
              </CardTitle>
              <Table
                data={filteredBuckets}
                onDelete={(bucket) => {
                  setSelectedBucket(bucket);
                  setShowConfirmDelete(true);
                }}
                calculateCost={calculateCost}
                onShareBucketCustomers={(bucket) => {
                  setSelectedBucket(bucket);
                  setShowShareBucketCustomers(true);
                }}
                onShareBucketUsers={(bucket) => {
                  setSelectedBucket(bucket);
                  setShowShareBucketUsers(true);
                }}
                onRequestTempCreds={(bucket) => {
                  setSelectedBucket(bucket);
                  setShowRequestTempCreds(true);
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {showConfirmDelete && (
        <DeleteBucket
          bucket={selectedBucket}
          deleteBucket={deleteBucket}
          close={() => {
            setSelectedBucket(null);
            setShowConfirmDelete(false);
          }}
        ></DeleteBucket>
      )}

      {showCreateBucket && (
        <CreateBucket
          createBucket={createBucket}
          close={() => {
            setShowCreateBucket(false);
          }}
        ></CreateBucket>
      )}

      {showShareBucketCustomers && (
        <ShareBucketCustomers
          bucket={selectedBucket}
          customerAccounts={customerAccounts}
          getBuckets={fetchBuckets}
          close={() => {
            setSelectedBucket(null);
            setShowShareBucketCustomers(false);
          }}
        ></ShareBucketCustomers>
      )}

      {showShareBucketUsers && (
        <ShareBucketUsers
          bucket={selectedBucket}
          users={users}
          getBuckets={fetchBuckets}
          close={() => {
            setSelectedBucket(null);
            setShowShareBucketUsers(false);
          }}
        ></ShareBucketUsers>
      )}

      {showRequestTempCreds && (
        <RequestTempCreds
          bucket={selectedBucket}
          close={() => {
            setShowRequestTempCreds(false);
            setSelectedBucket(null);
          }}
        />
      )}
    </Container>
  );
}
