import { Form } from "react-bootstrap";
import { ButtonWithSpinner } from "components/common";

export default function NewPasswordForm({
  newPassword,
  newPasswordRepeat,
  invalidToken,
  errors,
  isLoggingIn,
  handleCompleteNewPassword,
  setState,
}) {
  return (
    <>
      <div className="text-center">
        <h4 className="text-center font-weight-bold mb-3">
          New Password Required
        </h4>
      </div>
      <Form
        id="new-pwd-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleCompleteNewPassword();
        }}
      >
        <Form.Group controlId="formBasicPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            autoComplete="new-password"
            minLength="6"
            maxLength="99"
            disabled={invalidToken}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                errors: {},
                newPassword: e.target.value,
              }));
            }}
            value={newPassword}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBasicPasswordRepeat">
          <Form.Label>Repeat Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={newPasswordRepeat}
            autoComplete="new-password"
            minLength="6"
            maxLength="99"
            disabled={invalidToken}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                errors: {},
                newPasswordRepeat: e.target.value,
              }));
            }}
            isInvalid={!!errors.newPasswordAgain}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.newPasswordAgain}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          {!!(errors && errors[""]) && (
            <p className="text-danger">{errors[""]}</p>
          )}
        </Form.Group>
        <div className="mt-4">
          <ButtonWithSpinner
            type="submit"
            block
            isLoading={isLoggingIn}
            disabled={invalidToken}
          >
            Change Password
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  );
}
