import { NavLink } from "react-router-dom";

const MenuItem = ({ menuItem, collapsed, fullMatch = false }) => (
  <li>
    <NavLink
      to={menuItem.link}
      className={({ isActive, isPending }) => {
        let classes = "side-nav-link";

        if (isPending) {
          classes += " pending";
        } else if (fullMatch) {
          const fullPath = window.location.pathname + window.location.search;
          if (fullPath === menuItem.link) {
            classes += " active";
          }
        } else if (isActive) {
          classes += " active";
        }

        return classes;
      }}
    >
      <i className={`bi bi-${menuItem.icon}`}></i>
      {!collapsed && <span>{menuItem.name}</span>}
    </NavLink>
  </li>
);

export default MenuItem;
