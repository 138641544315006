import { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { ButtonWithSpinner, ErrorMessages } from "components/common";

export default function ChangeInstanceProfile({
  instance,
  getResources,
  close,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [instanceProfile, setInstanceProfile] = useState(
    instance.instance_profile
  );
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    api
      .execute("ec2.instance.change_instance_profile", {
        instance_id: instance.instance_id,
        user: instanceProfile,
      })
      .then(close)
      .catch((errors) => setErrors(errors))
      .finally(() => {
        getResources();
        isMounted.current && setIsLoading(false);
      });
  };

  useEffect(() => {
    const _users = instance.users;
    if (!_users.includes(instance.instance_profile)) {
      _users.push(instance.instance_profile);
    }
    setUsers(_users.sort());
  }, [instance]);

  return (
    <Modal
      show
      animation={false}
      onHide={close}
      size="lg"
      className="launch-instance-modal"
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Change Instance Profile</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3 form-group-plaintext">
            <Form.Label column sm="3">
              Instance
            </Form.Label>
            <Col sm="9">
              <Form.Control plaintext readOnly defaultValue={instance.name} />
            </Col>
          </Form.Group>

          {/* INSTANCE TYPE */}
          <Form.Group as={Row}>
            <Form.Label column sm="3">
              Instance Profile
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="select"
                name="instanceProfile"
                custom
                value={instanceProfile}
                onChange={(e) => {
                  setInstanceProfile(e.target.value);
                  setErrors({});
                }}
                required
                isInvalid={!!errors.instance_type}
              >
                {users.map((user) => (
                  <option key={user} value={user}>
                    {user}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.instance_profile}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          {/* GENERIC ERRORS */}
          <ErrorMessages
            errors={errors}
            keysToIgnore={["instance_profile"]}
            className="mt-3"
          ></ErrorMessages>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={close} disabled={isLoading}>
            Cancel
          </Button>
          <ButtonWithSpinner type="submit" isLoading={isLoading}>
            Modify
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
