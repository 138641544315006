import { useState } from "react";

import { useIsMounted, useLambdaApi } from "hooks";

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const isMounted = useIsMounted();
  const api = useLambdaApi();
  const [isLoading, setIsLoading] = useState(false);

  const getUsers = () => {
    setIsLoading(true);
    return api
      .execute("user.list")
      .then((users) => {
        isMounted.current && setUsers(users);
      })
      .finally(() => setIsLoading(false));
  };

  const deleteUser = (user) => {
    return api
      .execute("user.delete", { id: user.id, name: user.name })
      .then(() => {
        // remove user from list
        setUsers((users) => users.filter((u) => u.id !== user.id));
      });
  };

  const updateUser = (user, changes) => {
    setUsers((users) => {
      const userIndex = users.findIndex((u) => u.id === user.id);
      users[userIndex] = { ...user, ...changes };
      return users;
    });
  };

  const updateUserCanManage = (user, canManage) => {
    setIsLoading(true);

    // Optimistically update the user in the UI for snappy UI experience
    updateUser(user, { can_manage: canManage });

    return api
      .execute("user.change", {
        id: user.id,
        can_manage: canManage,
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteUserVpnConfig = (user) => {
    return api.execute("vpn.remove", { id: user.id }).then(() => {
      updateUser(user, { vpn: false });
    });
  };

  return {
    users,
    isLoading,
    getUsers,
    deleteUser,
    updateUserCanManage,
    deleteUserVpnConfig,
  };
};

export default useUsers;
