import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { sortObjectsAlfabetically, callPromisesSerial } from "utils";

import ShareBatchEnvironmentModal from "./ShareBatchEnvironmentModal";

export default function ShareBatchEnvironmentUsers({
  batchEnvironment,
  getBatchEnvironments,
  users,
  close,
}) {
  const api = useLambdaApi();
  const [isLoading, setIsLoading] = useState(false);
  const [toAdd, setToAdd] = useState({ username: "", toRemove: false });
  const [shared, setShared] = useState([]);
  const [errors, setErrors] = useState({});
  const [addErrors, setAddErrors] = useState({});
  const isMounted = useIsMounted();

  const getSelectableOptions = () => {
    let selectableOptions = users
      .filter((user) => !(batchEnvironment.user === user.name))
      .filter((user) => !shared.some((share) => share.username === user.name));

    selectableOptions = sortObjectsAlfabetically(selectableOptions, "name");

    selectableOptions = selectableOptions.map((user, index) => (
      <option key={user.name + index} value={user.name}>
        {user.name}
      </option>
    ));

    selectableOptions.unshift(
      <option key="choose" value="">
        Choose...
      </option>
    );
    return selectableOptions;
  };

  const handleAdd = () => {
    setAddErrors({});

    const existing = shared.find((share) => share.username === toAdd.username);
    if (existing) {
      existing.toRemove = false;
      setShared(() => [...shared]);
    } else {
      setShared((prev) => [...prev, toAdd]);
    }

    setToAdd({ username: "", toRemove: false });
  };

  const handleRemove = (shareToRemove) => {
    if (batchEnvironment.users.includes(shareToRemove.username)) {
      shareToRemove.toRemove = true;
      setShared((shared) => [...shared]);
    } else {
      setShared((shared) =>
        shared.filter((share) => share.username !== shareToRemove.username)
      );
    }
  };

  const handleShare = () => {
    setIsLoading(true);

    const apiCalls = shared.map((share) => () => {
      if (share.toRemove) {
        return api.execute("batch.unshare_user", {
          name: batchEnvironment.name,
          user: share.username,
        });
      } else {
        return api.execute("batch.share_user", {
          name: batchEnvironment.name,
          user: share.username,
        });
      }
    });

    const results = callPromisesSerial(apiCalls);

    results
      .then(() => getBatchEnvironments().finally(close))
      .catch((errors) => setErrors(errors))
      .finally(() => {
        isMounted.current && setIsLoading(false);
      });
  };

  const sharedWith = shared
    .filter((share) => !share.toRemove)
    .map((share) => (
      <h6 key={share.username} className="d-inline-block mx-1">
        <Badge pill variant="primary" className="share-pill">
          <span>{share.username}</span>
          <button
            size="sm"
            className="ml-2"
            onClick={() => handleRemove(share)}
          >
            <i className="bi bi-x-circle"></i>
          </button>
        </Badge>
      </h6>
    ));

  useEffect(
    () =>
      setShared(() => {
        // json used to deep copy object
        const sharedUsers = batchEnvironment.users
          ? JSON.parse(JSON.stringify(batchEnvironment.users))
          : [];

        return sharedUsers.map((user) => ({
          username: user,
          toRemove: false,
        }));
      }),
    [batchEnvironment]
  );

  return (
    <ShareBatchEnvironmentModal
      addErrors={addErrors}
      batchEnvironment={batchEnvironment}
      close={close}
      errors={errors}
      handleAdd={handleAdd}
      handleShare={handleShare}
      isLoading={isLoading}
      selectableOptions={getSelectableOptions()}
      setToAdd={setToAdd}
      sharedWith={sharedWith}
      title="Share With Users"
      toAdd={toAdd}
    ></ShareBatchEnvironmentModal>
  );
}
