import { Spinner as BootstrapSpinner } from "react-bootstrap";

export default function Spinner(props) {
  return (
    <div className={`spinner-container ${props.className}`}>
      <BootstrapSpinner
        animation="border"
        role="status"
        as={props.as || "div"}
        size={props.size || "md"}
      >
        <span className="sr-only">
          {props.message ? props.message : "Loading..."}
        </span>
      </BootstrapSpinner>
      {props.message && <p className="spinner-message">{props.message}</p>}
    </div>
  );
}
