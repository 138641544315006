import queryString from "query-string";
import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";

import { createAwsConfig } from "../../../utils";
import { ButtonWithSpinner } from "../../common";

import { ReactComponent as ExcelraLogo } from "../../../media/excelra_logo.svg";
import "./login.scss";

export default function ResetPasswordConfirm() {
  const { search } = useLocation();
  const { email, code } = queryString.parse(search);
  const [isLoading, setIsLoading] = useState(false);
  const [showPwdChanged, setShowPwdChanged] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [errors, setErrors] = useState({});

  const handleConfirmResetPassword = () => {
    Amplify.configure(createAwsConfig(true));

    setErrors({});
    setIsLoading(true);

    if (newPassword !== newPasswordRepeat) {
      setErrors({ newPasswordAgain: "Passwords must be identical." });
      setIsLoading(false);
      return;
    }

    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => setShowPwdChanged(true))
      .catch((error) => setErrors({ "": error.message }))
      .finally(() => setIsLoading(false));
  };

  if (!email || !code) {
    window.location.replace(`${window.location.origin}/login/`);
    return false;
  }

  return (
    <div id="login">
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col xs={10} className="card-container">
            <Card className="my-5">
              <Card.Header>
                <ExcelraLogo fill="white" width="275" height="100%" />
              </Card.Header>
              <Card.Body>
                <div className="w-75 m-auto">
                  {showPwdChanged ? (
                    <>
                      <h4 className="text-center font-weight-bold mb-3">
                        Password Changed
                      </h4>
                      <p className="text-center">
                        Your password has been changed, you can now login again.
                      </p>

                      <a
                        href="/login/"
                        className="mt-4 btn btn-primary d-block"
                      >
                        Back to Login
                      </a>
                    </>
                  ) : (
                    <Form
                      id="confirm-pwd-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleConfirmResetPassword();
                      }}
                    >
                      <h4 className="text-center font-weight-bold mb-3">
                        Change Password
                      </h4>
                      <p className="text-center">
                        Change your password with the verification code received
                        by email and a new password.
                      </p>

                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" disabled value={email} />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Verification Code</Form.Label>
                        <Form.Control disabled value={code} />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          autoComplete="new-password"
                          minLength="6"
                          maxLength="99"
                          onChange={(e) => {
                            e.preventDefault();
                            setErrors({});
                            setNewPassword(e.target.value);
                          }}
                          value={newPassword}
                          required
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Repeat Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          value={newPasswordRepeat}
                          autoComplete="new-password"
                          minLength="6"
                          maxLength="99"
                          onChange={(e) => {
                            e.preventDefault();
                            setErrors({});
                            setNewPasswordRepeat(e.target.value);
                          }}
                          isInvalid={!!errors.newPasswordAgain}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.newPasswordAgain}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        {!!errors[""] && (
                          <p className="text-danger">{errors[""]}</p>
                        )}
                      </Form.Group>

                      <div className="mt-4">
                        <ButtonWithSpinner
                          type="submit"
                          block
                          isLoading={isLoading}
                        >
                          Change Password
                        </ButtonWithSpinner>
                      </div>

                      <div className="text-center mt-3">
                        <a href="/login/">back to login</a>
                      </div>
                    </Form>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
