import { useState } from "react";
import IconButton from "../IconButton";
import Spinner from "../Spinner";

export default function CostCell({ row, calculateCost, resourceType }) {
  const [value, setValue] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCalculateCost = () => {
    setIsLoading(true);
    calculateCost(row.original)
      .then((calculated) => setValue(calculated))
      .catch((error) => typeof error === "object" && setError(error[""]))
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return <Spinner className="d-inline-block" as="span" size="sm"></Spinner>;
  } else if (error) {
    return <span className="text-danger">{error}</span>;
  } else if (value !== null) {
    if (resourceType === "bucket") {
      return <>{`${value.size} (${value.cost}$/month)`}</>;
    }
  } else {
    return (
      <IconButton
        title="Calculate"
        icon="calculator-fill"
        onClick={handleCalculateCost}
      ></IconButton>
    );
  }
}
