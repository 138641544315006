import { useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { ButtonWithSpinner, ErrorMessages } from "components/common";

export default function CreateFolder({
  bucket,
  curPath,
  objects,
  getObjects,
  onClose,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    api
      .execute("s3.upload", {
        bucket: bucket.name,
        key: curPath ? `${curPath}/${name}/` : `${name}/`,
        size: 0,
      })
      .then((response) => {
        const promise = new Promise((resolve, reject) => {
          const formData = new FormData();

          Object.keys(response.fields).forEach((key) => {
            formData.append(key, response.fields[key]);
          });
          formData.append("file", "");
          const req = new XMLHttpRequest();
          req.open("post", response.url, true);
          req.onload = () =>
            getObjects().then(() => {
              resolve();
              onClose();
            });
          const errorCb = () => {
            getObjects().then(() => {
              reject({ "": "Failed to create folder" });
            });
          };
          req.onabort = errorCb;
          req.onerror = errorCb;
          req.send(formData);
        });

        return promise;
      })
      .catch((errors) => isMounted.current && setErrors(errors))
      .finally(() => isMounted.current && setIsLoading(false));
  };

  return (
    <Modal show animation={false} onHide={onClose} backdrop="static">
      <Modal.Header>
        <Modal.Title>Create Folder</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Name
            </Form.Label>
            <Col sm="10">
              <Form.Control
                placeholder="Enter name"
                value={name}
                name="name"
                onChange={(e) => {
                  const name = e.target.value;
                  setName(e.target.value);
                  if (
                    objects.some(
                      (object) => object.is_directory && object.name === name
                    )
                  ) {
                    setErrors({ name: "Folder already exists." });
                  } else {
                    setErrors({});
                  }
                }}
                isInvalid={!!errors.name}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <ErrorMessages
            errors={errors}
            keysToIgnore={["name"]}
            className="mt-3"
          ></ErrorMessages>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={onClose}>
            Cancel
          </Button>
          <ButtonWithSpinner
            type="submit"
            isLoading={isLoading}
            disabled={Object.keys(errors).length}
          >
            Create
          </ButtonWithSpinner>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
