import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";

export default function CustomerProjectSelector({
  customer,
  setCustomer,
  project,
  setProject,
  errors,
  setErrors,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const [_projects, _customers] = await Promise.all([
        api.execute("project.list"),
        api.execute("customer.list"),
      ]);

      if (isMounted.current) {
        setProjects(_projects);
        setCustomers(_customers);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCustomerChange = (e) => {
    const selectedCustomer = e.target.value;
    setCustomer(selectedCustomer);
    setErrors({});

    // Update project based on selected customer
    const associatedProjects = projects[selectedCustomer] || [];
    if (associatedProjects.length === 1) {
      // If there's only one project, select it automatically
      setProject(associatedProjects[0]);
    } else {
      // If there are no projects or multiple projects, set project to empty
      setProject("");
    }
  };

  const customerProjects = customer ? projects[customer] || [] : [];

  return (
    <>
      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Customer
        </Form.Label>
        <Col sm="8">
          <Form.Control
            as="select"
            value={customer}
            name="customer"
            required
            custom
            onChange={handleCustomerChange}
            isInvalid={!!errors.customer}
          >
            <option key="choose-customer" value="">
              Choose Customer...
            </option>
            {customers.map((customer, index) => (
              <option key={`${customer}-${index}`} value={customer}>
                {customer}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.customer}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm="3">
          Project
        </Form.Label>
        <Col sm="8">
          <Form.Control
            as="select"
            value={project}
            name="project"
            required
            custom
            onChange={(e) => {
              setProject(e.target.value);
              setErrors({});
            }}
            isInvalid={!!errors.project}
          >
            <option key="choose-project" value="">
              Choose Project...
            </option>
            {customerProjects.map((project, index) => (
              <option key={`${project}-${index}`} value={project}>
                {project}
              </option>
            ))}
          </Form.Control>
          {customer && !customerProjects.length && (
            <Form.Text className="text-danger">
              <i className="bi bi-exclamation-triangle mr-1"></i>An admin needs
              to create a project for this customer first.
            </Form.Text>
          )}
          <Form.Control.Feedback type="invalid">
            {errors.project}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    </>
  );
}
