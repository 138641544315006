import Button from "react-bootstrap/Button";
import Spinner from "./Spinner";

export default function ButtonWithSpinner({
  variant,
  isLoading,
  onClick,
  type,
  block,
  size,
  disabled,
  className,
  children,
}) {
  return (
    <Button
      variant={variant}
      block={block}
      size={size}
      type={type}
      disabled={disabled || isLoading}
      onClick={onClick}
      className={className}
    >
      {children}
      {isLoading && (
        <Spinner className="ml-3 d-inline-block" as="span" size="sm"></Spinner>
      )}
    </Button>
  );
}
