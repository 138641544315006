import { Row, Col } from "react-bootstrap";
import Spinner from "./Spinner";

export default function CardTitle({ title, subtitle, showSpinner, children }) {
  return (
    <div className="mb-3">
      <Row>
        <Col className="my-auto">
          <h4 className="header-title d-inline">{title}</h4>
          {showSpinner && (
            <Spinner className="ml-3 d-inline" as="span" size="sm"></Spinner>
          )}
        </Col>
        {!!children && <Col xs="auto">{children}</Col>}
      </Row>
      {subtitle && (
        <Row>
          <Col className="text-muted">{subtitle}</Col>
        </Row>
      )}
    </div>
  );
}
