import { Modal, Button } from "react-bootstrap";

export default function SFTPInfo({ onClose }) {
  const getHostname = () => {
    let index = window.location.host.indexOf("-") + 1;
    return window.location.host.slice(index);
  };

  return (
    <Modal
      show
      animation={false}
      onHide={onClose}
      backdrop="static"
      className="sftp-info"
    >
      <Modal.Header>
        <Modal.Title className="text-capitalize">
          Upload/Download with SFTP
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <b>How to upload/download by SFTP?</b>
        </p>
        <ul className="list-inline">
          <li className="mb-1">
            <span className="mb-auto mr-3 rounded-circle bg-primary">1</span>
            <span className="d-flex">
              Download and install&nbsp;
              <a
                href="https://filezilla-project.org/download.php?show_all=1"
                target="_blank"
                rel="noreferrer"
              >
                FileZilla.
              </a>
            </span>
          </li>

          <li className="mb-1">
            <span className="mb-auto mr-3 rounded-circle bg-primary">2</span>
            <span className="d-flex">
              Fill in hostname&nbsp;
              <b>{`sftp-${getHostname()}`}</b>
              &nbsp;and port&nbsp;<b>22</b>
            </span>
          </li>

          <li className="mb-1">
            <span className="mb-auto mr-3 rounded-circle bg-primary">3</span>
            <span className="d-flex">Fill in your username and password.</span>
          </li>

          <li className="mb-1">
            <span className="mb-auto mr-3 rounded-circle bg-primary">4</span>
            <span className="d-flex">
              When connected, find the S3 bucket on the right panel.
            </span>
          </li>

          <li className="mb-1">
            <span className="mb-auto mr-3 rounded-circle bg-primary">5</span>
            <span className="d-flex">
              Upload desired files by moving them from the left panel (local) to
              the samples folder in right panel (S3 bucket).
            </span>
          </li>

          <li className="mb-1">
            <span className="mb-auto mr-3 rounded-circle bg-primary">5</span>
            <span className="d-flex">
              Download desired files by moving them from the right panel (S3
              bucket) to the samples folder in left panel (local).
            </span>
          </li>

          <li className="mb-1">
            <span className="mb-auto mr-3 rounded-circle bg-primary">6</span>
            <span className="d-flex">
              Refresh the page when upload has completed.
            </span>
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
