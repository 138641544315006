import { Navbar, Button, Form } from "react-bootstrap";
import { useAtomValue, useAtom } from "jotai";

import constants from "constants";
import { userAtom, sidebarCollapsedAtom } from "store";
import { useAuth } from "hooks";

import "./header.scss";

function Header() {
  const { handleLogout } = useAuth();
  const user = useAtomValue(userAtom);
  const [sidebarCollapsed, setSidebarCollapsed] = useAtom(sidebarCollapsedAtom);

  const toggleSidebarCollapsed = () => {
    setSidebarCollapsed((prev) => {
      localStorage.setItem(constants.SIDEBAR_COLLAPSED_KEY, !prev);
      return !prev;
    });
  };

  return (
    <header>
      <Navbar
        className={`header ${sidebarCollapsed ? "sidebar-collapsed" : ""}`}
      >
        {!constants.IS_S3_APP && (
          <Button className="toggle-sidebar" onClick={toggleSidebarCollapsed}>
            <i
              className={`bi bi-chevron-double-${
                sidebarCollapsed ? "right" : "left"
              }`}
            ></i>
          </Button>
        )}

        <Form inline className="ml-auto">
          <p className="mr-3 my-auto">{user ? user.email.toLowerCase() : ""}</p>
          <Button variant="primary" onClick={handleLogout}>
            Sign Out
          </Button>
        </Form>
      </Navbar>
    </header>
  );
}

export default Header;
