import { Modal, Button } from "react-bootstrap";

export default function UploadFailed({ file, extraMsg, onClose }) {
  return (
    <Modal show animation={false} onHide={onClose} backdrop="static">
      <Modal.Header>
        <Modal.Title>Upload Failed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="mb-0">Not able to upload '{file}'</p>
          {!!extraMsg && <p>{extraMsg}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
