import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useAtom } from "jotai";

import { useAuth, useLambdaApi, useUser } from "hooks";
import { Sidebar, PageLoader, Header, Footer, Notifications } from "components";
import { sortObjectsAlfabetically } from "utils";
import constants from "constants";
import { sidebarCollapsedAtom } from "store";

import { AppRoutes, AuthRoutes } from "./routes";

import "./App.scss";
import "../pages/pages.scss";

export default function App() {
  const { isAuthenticating, isLoggedIn, checkIfLoggedIn } = useAuth();

  const { user } = useUser();
  const api = useLambdaApi();

  const [sidebarCollapsed, setSidebarCollapsed] = useAtom(sidebarCollapsedAtom);

  const [buckets, setBuckets] = useState([]);
  const [isLoadingBuckets, setIsLoadingBuckets] = useState(false);

  useEffect(() => {
    if (isLoggedIn && constants.IS_S3_APP) {
      setIsLoadingBuckets(true);
      api
        .execute("s3.list")
        .then((buckets) => {
          buckets = sortObjectsAlfabetically(buckets, "name");
          setBuckets(buckets);
        })
        .finally(() => {
          setIsLoadingBuckets(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    let navbarCollapsed = constants.IS_S3_APP
      ? false
      : JSON.parse(localStorage.getItem(constants.SIDEBAR_COLLAPSED_KEY));
    setSidebarCollapsed(!!navbarCollapsed);
  }, [setSidebarCollapsed]);

  useEffect(() => {
    checkIfLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <div className="wrapper">
        {isAuthenticating || (isLoggedIn && !user) || isLoadingBuckets ? (
          <PageLoader />
        ) : isLoggedIn ? (
          !!user && (
            <>
              <div
                className={`sidebar-wrapper ${
                  sidebarCollapsed ? "sidebar-collapsed" : ""
                }`}
              >
                <Sidebar buckets={buckets} />
              </div>

              <div
                className={`content-wrapper ${
                  sidebarCollapsed ? "sidebar-collapsed" : ""
                }`}
              >
                <div className="content">
                  <Header />
                  <AppRoutes buckets={buckets} />
                </div>
                {isLoadingBuckets && <PageLoader />}
                <Footer />
                <Notifications />
              </div>
            </>
          )
        ) : (
          <AuthRoutes />
        )}
      </div>
    </BrowserRouter>
  );
}
