import { Fragment } from "react";

export default function BreadCrumbs({ bucket, curPath, handleNavigate }) {
  const crumbs = [];

  crumbs.push({ name: bucket.name, path: "" });
  if (curPath) {
    const pieces = curPath.split("/");
    pieces.forEach((piece, index) => {
      crumbs.push({
        name: piece || bucket.name,
        path: pieces.slice(0, index + 1).join("/"),
      });
    });
  }

  const crumbEls = crumbs.map((crumb, index) => (
    <Fragment key={`${crumb.name}-${index}`}>
      {(crumbs.length !== index + 1 && (
        <div className="float-left">
          {!!index && (
            <span className="mr-3">
              <i className="bi bi-slash-lg"></i>
            </span>
          )}
          <a
            href="/"
            className="mr-3"
            onClick={(e) => {
              e.preventDefault();
              handleNavigate(crumb.path);
            }}
          >
            {crumb.name}
          </a>
        </div>
      )) || (
        <div className="float-left">
          {!!index && (
            <span className="mr-3">
              <i className="bi bi-slash-lg"></i>
            </span>
          )}
          <span>{crumb.name}</span>
        </div>
      )}
    </Fragment>
  ));

  return <>{crumbEls}</>;
}
