import { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Spinner from "./Spinner";

const CopyToClipBoardButton = ({
  asIcon = false,
  elementId = null,
  getTextToCopy = null,
  hoverText = "Copy to clipboard",
  copiedText = "Text copied",
}) => {
  const [showIsCopied, setShowIsCopied] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [showCopyFailed, setShowCopyFailed] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = async () => {
    let textToCopy;
    if (elementId) {
      const text = document.getElementById(elementId);
      const range = document.createRange();
      range.selectNodeContents(text);
      textToCopy = range.toString();
    } else if (getTextToCopy) {
      setIsLoading(true);
      try {
        textToCopy = await getTextToCopy().finally(() => setIsLoading(false));
      } catch (e) {
        setShowCopyFailed(true);
        console.error(e);
        return;
      }
    }

    await navigator.clipboard.writeText(textToCopy);
    setShowIsCopied(true);
  };

  // isShownProp is used to persist the tooltip when text is copied.
  // We have to make sure show is not even a prop when we want the tooltip to
  // properly work on hover as well.
  const isShownProp = showIsCopied || showCopyFailed ? { show: true } : {};

  useEffect(() => {
    if (showCopyFailed) {
      setTooltipContent(
        <>
          <i className="bi bi-x mr-1"></i>Failed to copy
        </>
      );
    } else if (showIsCopied) {
      setTooltipContent(
        <>
          <i className="bi bi-check mr-1"></i>
          {copiedText}
        </>
      );
    } else {
      // Change tooltip back to "Copy to clipboard" after 250ms
      // to ensure tooltip message does not change for a brief moment
      // before hiding after button is blurred
      setTimeout(() => {
        setTooltipContent(hoverText);
      }, 250);
    }
  }, [showIsCopied, showCopyFailed, copiedText, hoverText]);

  return (
    <>
      <OverlayTrigger
        placement="top"
        transition={false}
        {...isShownProp}
        overlay={<Tooltip>{tooltipContent}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
          <Button
            ref={ref}
            {...triggerHandler}
            variant={asIcon ? "link" : "secondary"}
            size="sm"
            onClick={handleOnClick}
            onBlur={() => setShowIsCopied(false)}
          >
            {isLoading ? (
              <Spinner as="span" size="sm" />
            ) : (
              <i className="bi bi-copy"></i>
            )}
          </Button>
        )}
      </OverlayTrigger>
    </>
  );
};

export default CopyToClipBoardButton;
