import { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { sortObjectsAlfabetically, callPromisesSerial } from "utils";

import ShareInstanceModal from "./ShareInstanceModal";

export default function ShareInstanceUsers({
  instance,
  getResources,
  users,
  close,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [toAdd, setToAdd] = useState({ username: "", toRemove: false });
  const [shared, setShared] = useState([]);
  const [errors, setErrors] = useState({});
  const [addErrors, setAddErrors] = useState({});

  useEffect(
    () =>
      setShared(() => {
        // json used to deep copy object
        const sharedUsers = instance.users
          ? JSON.parse(JSON.stringify(instance.users))
          : [];

        return sharedUsers.map((user) => ({
          username: user,
          toRemove: false,
        }));
      }),
    [instance]
  );

  let selectableOptions = users
    .filter((user) => !(instance.user === user.name))
    .filter((user) => !shared.some((share) => share.username === user.name));

  selectableOptions = sortObjectsAlfabetically(selectableOptions, "name");

  selectableOptions = selectableOptions.map((user, index) => (
    <option key={user.name + index} value={user.name}>
      {user.name}
    </option>
  ));

  selectableOptions.unshift(
    <option key="choose" value="">
      Choose...
    </option>
  );

  const handleAdd = () => {
    setAddErrors({});

    const existing = shared.find((share) => share.username === toAdd.username);
    if (existing) {
      existing.toRemove = false;
      setShared(() => [...shared]);
    } else {
      setShared((prev) => [...prev, toAdd]);
    }

    setToAdd({ username: "", toRemove: false });
  };

  const handleRemove = (shareToRemove) => {
    if (instance.users.includes(shareToRemove.username)) {
      shareToRemove.toRemove = true;
      setShared((shared) => [...shared]);
    } else {
      setShared((shared) =>
        shared.filter((share) => share.username !== shareToRemove.username)
      );
    }
  };

  const handleShare = () => {
    setIsLoading(true);

    const apiCalls = shared.map((share) => () => {
      if (share.toRemove) {
        return api.execute("ec2.instance.unshare_user", {
          instance_id: instance.instance_id,
          user: share.username,
        });
      } else {
        return api.execute("ec2.instance.share_user", {
          instance_id: instance.instance_id,
          user: share.username,
        });
      }
    });

    const results = callPromisesSerial(apiCalls);

    results
      .then(() => getResources().finally(close))
      .catch((errors) => setErrors(errors))
      .finally(() => {
        isMounted.current && setIsLoading(false);
      });
  };

  const sharedWith = shared
    .filter((share) => !share.toRemove)
    .map((share) => (
      <h6 key={share.username} className="d-inline-block mx-1">
        <Badge pill variant="primary" className="share-pill">
          <span>{share.username}</span>
          <button
            size="sm"
            className="ml-2"
            onClick={() => handleRemove(share)}
          >
            <i className="bi bi-x-circle"></i>
          </button>
        </Badge>
      </h6>
    ));

  return (
    <ShareInstanceModal
      addErrors={addErrors}
      instance={instance}
      close={close}
      errors={errors}
      handleAdd={handleAdd}
      handleShare={handleShare}
      isLoading={isLoading}
      selectableOptions={selectableOptions}
      setToAdd={setToAdd}
      shareWithCustomers={false}
      sharedWith={sharedWith}
      title="Share With Users"
      toAdd={toAdd}
    ></ShareInstanceModal>
  );
}
