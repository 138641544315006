import Form from "react-bootstrap/Form";

export default function TableRows({ pageSize, setPageSize }) {
  return (
    <div className="table-rows">
      <label className="form-label">
        Show
        <Form.Control
          as="select"
          size="sm"
          name="row-amount-input"
          custom
          className="mx-2"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Form.Control>
        records
      </label>
    </div>
  );
}
