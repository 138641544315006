import { Modal, Button } from "react-bootstrap";

import ButtonWithSpinner from "./ButtonWithSpinner";
import ErrorMessages from "./ErrorMessages";

export default function ConfirmAction({
  action,
  isLoading,
  onCancel,
  onConfirm,
  errors,
}) {
  return (
    <Modal show animation={false} onHide={onCancel} backdrop="static">
      <Modal.Header>
        <Modal.Title>
          Confirm{" "}
          <span className="text-capitalize">
            {action.action} {action.resourceType}
          </span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Are you sure you want to {action.action} '{action.resourceName}'?
        </p>
        <ErrorMessages errors={errors} className="mt-4"></ErrorMessages>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <ButtonWithSpinner
          type="submit"
          block={false}
          onClick={onConfirm}
          isLoading={isLoading}
        >
          Confirm
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
}
