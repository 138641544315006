import { useState } from "react";

import { ConfirmDelete } from "components/common";
import { useIsMounted, useLambdaApi } from "hooks";

export default function DeleteProject({
  customer,
  project,
  onCancel,
  onDeleted,
}) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const deleteProject = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .execute("project.delete", { customer, project })
      .then(onDeleted)
      .catch((error) => isMounted.current && setErrors(error.response.data))
      .finally(() => isMounted.current && setIsLoading(false));
  };

  return (
    <ConfirmDelete
      name={project}
      resourceType="project"
      isDeleting={isLoading}
      errors={errors}
      onCancel={onCancel}
      onConfirm={deleteProject}
    />
  );
}
