import { useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import { useAtomValue } from "jotai";

import { UrlButton, IconButton, Table as CommonTable } from "components/common";
import CostCell from "components/common/Table/CostCell";
import { userAtom } from "store";

export default function Table({
  data,
  calculateCost,
  onDelete,
  onShareBucketCustomers,
  onShareBucketUsers,
  onRequestTempCreds,
}) {
  const user = useAtomValue(userAtom);

  const s3Hostname = window.location.hostname.replace("mgmt", "s3");
  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.name}
              <UrlButton
                title="Open in S3"
                icon="box-arrow-up-right"
                className="ml-2"
                href={`https://${s3Hostname}/buckets/?bucket=${row.original.name}`}
              ></UrlButton>
            </>
          );
        },
      },
      {
        Header: "Customer",
        accessor: "customer",
      },
      {
        Header: "Project",
        accessor: "project",
      },
      {
        Header: "Users",
        accessor: "users",
        filter: "includes",
        Cell: ({ row }) =>
          row.original.users.map((user) => (
            <h6 key={`${row.original.name}.${user}`}>
              <Badge pill variant="primary" className="share-pill">
                {user}
              </Badge>
            </h6>
          )),
      },
      {
        Header: "Customer Accounts",
        id: "customers",
        // ensure that accessor is a list of values to make global filter work
        accessor: (row) => row.shared.map((share) => share.username),
        filter: "includes",
        Cell: ({ row }) =>
          row.original.shared.map((share) => (
            <h6 key={`${row.original.name}.${share.username}`}>
              <Badge pill variant="primary" className="share-pill">
                {share.username}:{share.mode}
              </Badge>
            </h6>
          )),
      },
      {
        Header: "Cost",
        id: "calculatecost",
        Cell: ({ row, value }) => {
          return (
            <CostCell
              row={row}
              value={value}
              calculateCost={calculateCost}
              resourceType="bucket"
            />
          );
        },
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          return (
            <>
              {!user.isAdmin && (
                <IconButton
                  title="Request Credentials"
                  icon="key-fill"
                  className="mr-2"
                  onClick={() => onRequestTempCreds(row.original)}
                />
              )}
              {user.canManage && (
                <>
                  <IconButton
                    title="Share With Users"
                    icon="people-fill"
                    className="mr-2"
                    onClick={() => onShareBucketUsers(row.original)}
                  />

                  <IconButton
                    title="Share With Customers"
                    icon="share-fill"
                    className="mr-2"
                    onClick={() => onShareBucketCustomers(row.original)}
                  />

                  <IconButton
                    title="Delete"
                    icon="trash-fill"
                    onClick={() => onDelete(row.original)}
                  />
                </>
              )}
            </>
          );
        },
      },
    ];

    return columns;
  }, [
    user,
    s3Hostname,
    calculateCost,
    onShareBucketUsers,
    onShareBucketCustomers,
    onRequestTempCreds,
    onDelete,
  ]);

  return <CommonTable columns={columns} data={data} />;
}
