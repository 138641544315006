import { useState } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";

import { ButtonWithSpinner, ErrorMessages } from "components/common";
import { useLambdaApi } from "hooks";

export default function Import({ getSshKeys }) {
  const api = useLambdaApi();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [pubkey, setPubkey] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    api
      .execute("ec2.keypair.import_key", {
        name,
        pubkey,
      })
      .then(() => {
        setPubkey("");
        setErrors({});
        setName("");
      })
      .catch((errors) => setErrors(errors))
      .finally(() => {
        getSshKeys();
        setIsLoading(false);
      });
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors({});
  };

  const handlePubkeyChange = (e) => {
    setPubkey(e.target.value);
    setErrors({});
  };

  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="mb-3">
          <Col>
            <h4 className="header-title">Import Key</h4>
            <span className="text-muted">
              Public keys can only be imported in openssh format. If you are
              using Putty you will have to convert it first.
            </span>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              placeholder="Enter name"
              value={name}
              onChange={handleNameChange}
              isInvalid={!!errors.name}
              required
            />
            <Form.Text className="text-muted">
              Ssh key names have to be unique troughout the whole AWS account.
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>SSH Public Key</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              placeholder="Enter SSH public key"
              value={pubkey}
              onChange={handlePubkeyChange}
              isInvalid={!!errors.pubkey}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.pubkey}
            </Form.Control.Feedback>
          </Form.Group>

          <ErrorMessages
            errors={errors}
            keysToIgnore={["name", "pubkey"]}
            className="mt-3"
          ></ErrorMessages>

          <ButtonWithSpinner
            type="submit"
            block
            className="import-ssh-key-btn"
            disabled={Object.keys(errors).length}
            isLoading={isLoading}
          >
            Import
          </ButtonWithSpinner>
        </Form>
      </Card.Body>
    </Card>
  );
}
