import { useMemo } from "react";
import { IconButton, Table } from "../../common";

export default function CustomersTable({
  customers,
  onSelectCustomer,
  onDelete,
  tableRef,
}) {
  const columns = useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "name",
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => {
          return (
            <IconButton
              title="Delete"
              icon="trash-fill"
              onClick={() => onDelete(row.original.name)}
            ></IconButton>
          );
        },
      },
    ],
    [onDelete]
  );

  return (
    <Table
      ref={tableRef}
      columns={columns}
      data={customers}
      defaultPageSize={10}
      enableRowSelection
      enableSelectOnClick
      onRowClick={(customer) => onSelectCustomer(customer.name)}
      showHeaders={false}
    />
  );
}
