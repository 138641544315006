import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./IconButton.scss";

export default function UrlButton({ icon, title, disabled, className, href }) {
  return (
    <OverlayTrigger
      placement="top"
      transition={false}
      overlay={<Tooltip>{title}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div
          className={`icon-btn-container d-inline-block ${className}`}
          disabled={disabled}
          {...triggerHandler}
          ref={ref}
        >
          <a href={href} className="icon-btn" target="_blank" rel="noreferrer">
            <i className={`bi bi-${icon}`}></i>
          </a>
        </div>
      )}
    </OverlayTrigger>
  );
}
