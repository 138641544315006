import queryString from "query-string";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useLocation, useNavigate, Navigate } from "react-router-dom";

import { CardTitle, RefreshButton } from "components/common";
import { PageLoader } from "components";
import { useIsMounted, useLambdaApi } from "hooks";

import {
  Table,
  ConfirmDelete,
  BreadCrumbs,
  Upload,
  UploadByUrl,
  CreateFolder,
  SFTPInfo,
} from "./components";
import useObjects from "./useObjects";
import { getKey } from "./utils";

import "./buckets.scss";

export default function Buckets({ buckets }) {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const { search } = useLocation();
  const tableRef = useRef();
  const [curPath, setCurPath] = useState(null);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showUploadByUrl, setShowUploadByUrl] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showSFTPInfo, setShowSFTPInfo] = useState(false);
  const [errors, setErrors] = useState(null);

  const { bucket: bucketName, path } = queryString.parse(search);
  const bucket = buckets.find((bucket) => bucket.name === bucketName);

  const { isLoading, objects, getObjects, isDeleting, deleteObjects } =
    useObjects(api, bucket, curPath, isMounted);

  const handleNavigate = useCallback(
    (path) => {
      setCurPath(path);
      let qs = `?bucket=${bucket.name}`;
      if (path) {
        qs += `&path=${path}`;
      }
      navigate(qs);
    },
    [bucket, navigate]
  );

  const handleNavigateToDirectory = useCallback(
    (name) => {
      const path = curPath ? `${curPath}/${name}` : name;
      handleNavigate(path);
    },
    [curPath, handleNavigate]
  );

  const handleDownloadFile = useCallback(
    (file) => {
      setIsDownloading(true);
      api
        .execute("s3.download", {
          bucket: bucket.name,
          key: getKey(curPath, file),
        })
        .then((url) => isMounted.current && window.open(url))
        .finally(() => isMounted.current && setIsDownloading(false));
    },
    [api, bucket, curPath, isMounted]
  );

  const handleObjectClicked = useCallback(
    (object) => {
      if (object.is_directory) {
        handleNavigateToDirectory(object.name);
      } else {
        handleDownloadFile(object);
      }
    },
    [handleDownloadFile, handleNavigateToDirectory]
  );

  useEffect(() => setCurPath(path || ""), [path]);

  // If no buckets or bucket not found, show warning
  if (!buckets.length || (bucketName && !bucket)) {
    const message = !buckets.length
      ? "No buckets found"
      : `Bucket ${bucketName} not accessible`;
    const contactMessage = !buckets.length ? "certain buckets" : `this bucket`;
    return (
      <h6 className="no-buckets-warning mt-4 ml-2">
        <i className="bi bi-exclamation-triangle mr-2"></i>
        {message}. Please contact us at{" "}
        <a href="helpdesk@excelra.com">helpdesk@excelra.com</a> if you should
        have access to {contactMessage}.
      </h6>
    );
  }

  if (!bucket) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Container fluid={true} className="page data-storage">
        <Row>
          <Col>
            <div className="page-title-box">
              <h3 className="page-title">
                <BreadCrumbs
                  bucket={bucket}
                  curPath={curPath}
                  handleNavigate={handleNavigate}
                ></BreadCrumbs>
              </h3>
            </div>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Card className="h-100">
              <Card.Body>
                <CardTitle title="Objects">
                  <Button
                    className="mr-2"
                    onClick={() => setShowSFTPInfo(true)}
                  >
                    Use SFTP
                  </Button>
                  {bucket.mode !== "ro" && (
                    <>
                      <Upload
                        bucket={bucket}
                        curPath={curPath}
                        getObjects={getObjects}
                      ></Upload>
                      <Button
                        className="mr-2"
                        onClick={() => setShowUploadByUrl(true)}
                      >
                        Upload By Url
                      </Button>
                    </>
                  )}

                  {bucket.mode === "rw" && (
                    <>
                      <Button
                        className="mr-2"
                        disabled={isLoading}
                        onClick={() => setShowCreateFolder(true)}
                      >
                        Create Folder
                      </Button>

                      <Button
                        variant="danger"
                        className="mr-2"
                        onClick={() => {
                          setShowConfirmDelete(true);
                        }}
                        disabled={!selectedObjects.length}
                      >
                        Delete{" "}
                        {!!selectedObjects.length &&
                          `(${selectedObjects.length})`}
                      </Button>
                    </>
                  )}
                  {bucket.mode !== "wo" && (
                    <RefreshButton onRefresh={getObjects}></RefreshButton>
                  )}
                </CardTitle>

                <Table
                  isLoading={isLoading}
                  bucket={bucket}
                  objects={objects}
                  getObjects={getObjects}
                  curPath={curPath}
                  tableRef={tableRef}
                  onUploadByUrl={() => setShowUploadByUrl(true)}
                  setSelectedRows={(changedSelection) =>
                    changedSelection.length !== selectedObjects.length &&
                    setSelectedObjects(changedSelection)
                  }
                  onObjectClicked={handleObjectClicked}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {(isLoading || isDownloading) && <PageLoader />}
      </Container>

      {showConfirmDelete && (
        <ConfirmDelete
          objects={selectedObjects}
          isDeleting={isDeleting}
          onCancel={() => {
            setShowConfirmDelete(false);
            setErrors(null);
          }}
          onConfirm={() =>
            deleteObjects(selectedObjects)
              .then(() => {
                tableRef.current.resetSelection();
                setShowConfirmDelete(false);
              })
              .catch(setErrors)
          }
          errors={errors}
        ></ConfirmDelete>
      )}

      {showUploadByUrl && (
        <UploadByUrl
          bucket={bucket}
          curPath={curPath}
          getObjects={getObjects}
          onClose={() => {
            setShowUploadByUrl(false);
          }}
        ></UploadByUrl>
      )}

      {showCreateFolder && (
        <CreateFolder
          bucket={bucket}
          curPath={curPath}
          objects={objects}
          getObjects={getObjects}
          onClose={() => setShowCreateFolder(false)}
        ></CreateFolder>
      )}

      {showSFTPInfo && (
        <SFTPInfo onClose={() => setShowSFTPInfo(false)}></SFTPInfo>
      )}
    </>
  );
}
