import { useAtom, useSetAtom } from "jotai";
import { Auth, Amplify } from "aws-amplify";

import { createAwsConfig } from "utils";
import constants from "constants";
import { cognitoUserAtom, userAtom, apiAtom } from "store";
import { authStateAtom } from "store";

export default function useAuth() {
  const [cognitoUser, setCognitoUser] = useAtom(cognitoUserAtom);
  const setUser = useSetAtom(userAtom);
  const setApi = useSetAtom(apiAtom);
  const [authState, setAuthState] = useAtom(authStateAtom);

  const handleLoggedIn = (cognitoUser) => {
    setCognitoUser(cognitoUser);
    setAuthState({ isLoggedIn: true, isAuthenticating: false });
  };

  const handleLogout = async () => {
    setAuthState({ ...authState, isAuthenticating: true });
    try {
      await Auth.signOut();
      localStorage.clear();
      setCognitoUser(null);
      setUser(null);
      setApi(null);
    } catch (error) {
      window.location.reload();
    } finally {
      setAuthState({ isLoggedIn: false, isAuthenticating: false });
    }
  };

  const refreshSession = () => {
    return Auth.currentSession().then((cognitoSession) => {
      const updatedUser = { ...cognitoUser, signInUserSession: cognitoSession };
      setCognitoUser(updatedUser);
      return updatedUser;
    });
  };

  const checkIfLoggedIn = async () => {
    try {
      const loginWithoutSSO = JSON.parse(
        localStorage.getItem(constants.LOGIN_WITHOUT_SSO)
      );
      Amplify.configure(createAwsConfig(loginWithoutSSO));
      const cognitoUser = await Auth.currentAuthenticatedUser();
      handleLoggedIn(cognitoUser);
    } catch (error) {
      setAuthState({
        isLoggedIn: false,
        isAuthenticating: false,
      });
    }
  };

  return {
    cognitoUser,
    handleLoggedIn,
    handleLogout,
    refreshSession,
    checkIfLoggedIn,
    isLoggedIn: authState.isLoggedIn,
    isAuthenticating: authState.isAuthenticating,
  };
}
