import { useState, useEffect, useCallback } from "react";
import { Col } from "react-bootstrap";
import Filter from "./Filter";

export default function Filters({
  filteredResources,
  filters,
  setFilters,
  resetFilters,
  children,
}) {
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const updateAvailableResources = useCallback(() => {
    const availableProjects = new Set();
    const availableUsers = new Set();
    const availableCustomers = new Set();

    filteredResources.forEach((resource) => {
      !!resource.project && availableProjects.add(resource.project);
      !!resource.customer && availableCustomers.add(resource.customer);
      !!resource.users &&
        resource.users.forEach((user) => availableUsers.add(user));
    });

    setUsers(Array.from(availableUsers).sort());
    setCustomers(Array.from(availableCustomers).sort());
    setProjects(Array.from(availableProjects).sort());
  }, [filteredResources]);

  useEffect(() => {
    updateAvailableResources();
  }, [filteredResources, updateAvailableResources]);

  return (
    <>
      <Col>
        <Filter
          filter="users"
          name="Users"
          placeholder="Choose a user..."
          options={users}
          filters={filters}
          setFilters={setFilters}
        ></Filter>
      </Col>

      <Col>
        <Filter
          filter="customer"
          name="Customer"
          placeholder="Choose a customer..."
          options={customers}
          filters={filters}
          setFilters={setFilters}
        ></Filter>
      </Col>

      <Col>
        <Filter
          filter="project"
          name="Project"
          placeholder="Choose a project..."
          options={projects}
          filters={filters}
          setFilters={setFilters}
        ></Filter>
      </Col>

      {children}

      <Col xs="auto" className="my-auto">
        <a
          href="/"
          className=""
          onClick={(e) => {
            e.preventDefault();
            resetFilters();
          }}
        >
          <i className="bi bi-x-circle-fill mr-2"></i>Reset All
        </a>
      </Col>
    </>
  );
}
