import { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { useAtomValue } from "jotai";

import {
  ConfirmDelete,
  CardTitle,
  ConfirmAction,
  RefreshButton,
} from "components/common";
import { useIsMounted, useLambdaApi } from "hooks";
import { userAtom } from "store";

import InstancesTable from "./tables/InstancesTable";
import InstanceSubTables from "./tables/InstanceSubTables";
import {
  LaunchInstance,
  ModifyInstance,
  ShareInstanceUsers,
  ChangeInstanceProfile,
  AddSSHKey,
} from "./instance-actions";
import { AddVolume, ModifyVolume } from "./volume-actions";

import CalculateTotalCost from "./instance-actions/CalculateTotalCost";

export default function Instances({
  isLoading,
  instances,
  instanceTypes,
  volumeTypes,
  users,
  getResources,
  deleteResource,
}) {
  const api = useLambdaApi();
  const user = useAtomValue(userAtom);
  const isMounted = useIsMounted();
  const tableRef = useRef();
  const [selectedResource, setSelectedResource] = useState({});
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const [showLaunchInstance, setShowLaunchInstance] = useState(false);
  const [showModifyInstance, setShowModifyInstance] = useState(false);
  const [confirmAction, setConfirmAction] = useState({});
  const [showModifyVolume, setShowModifyVolume] = useState(false);
  const [showAddVolume, setShowAddVolume] = useState(false);
  const [showShareInstanceUsers, setShowShareInstanceUsers] = useState(false);
  const [showChangeInstanceProfile, setShowChangeInstanceProfile] =
    useState(false);
  const [showAddSSHKey, setShowAddSSHKey] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState({});
  const [confirmActionErrors, setConfirmActionErrors] = useState({});
  const [filteredInstances, setFilteredInstances] = useState([]);
  const [sshKeys, setSshKeys] = useState([]);

  const handleInstanceAction = (action, instance) => {
    setIsActionLoading(true);
    setConfirmActionErrors({});
    return api
      .execute(`ec2.instance.${action}`, {
        instance_id: instance.instance_id,
      })
      .then(() => {
        setShowConfirmAction(false);
        setConfirmAction({});
      })
      .catch((errors) => setConfirmActionErrors(errors))
      .finally(() => {
        getResources();
        setIsActionLoading(false);
      });
  };

  const detachVolume = (volume) => {
    setIsActionLoading(true);
    setConfirmActionErrors({});
    return api
      .execute("ec2.volume.detach", { volume_id: volume.volume_id })
      .then(() => {
        setShowConfirmAction(false);
        setConfirmAction({});
      })
      .catch((errors) => setConfirmActionErrors(errors))
      .finally(() => {
        getResources();
        setIsActionLoading(false);
      });
  };

  const onConfirmDelete = () => {
    setIsDeleting(true);
    setDeleteErrors({});
    return deleteResource(selectedResource)
      .then(() => {
        setShowConfirmDelete(false);
        setSelectedResource({});
      })
      .catch(setDeleteErrors)
      .finally(() => {
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    setFilteredInstances((prev) => [...prev]);
  }, [instances]);

  useEffect(() => {
    api
      .execute("ec2.keypair.list")
      .then((keys) => isMounted.current && setSshKeys(keys));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card className="h-100 instances-card">
        <Card.Body>
          <CardTitle
            title="Instances"
            subtitle="Displays instances with their attached volumes."
            showSpinner={isLoading}
          >
            <CalculateTotalCost
              instances={filteredInstances}
            ></CalculateTotalCost>

            {!user.isAdmin && user.canManage && (
              <Button
                size="sm"
                className="mr-2"
                onClick={() => setShowLaunchInstance(true)}
              >
                Launch Instance
              </Button>
            )}

            <RefreshButton
              size="sm"
              onRefresh={() => getResources()}
            ></RefreshButton>
          </CardTitle>

          <InstancesTable
            tableRef={tableRef}
            instances={instances}
            setFilteredInstances={setFilteredInstances}
            onDelete={(resource) => {
              setSelectedResource({ resource, type: "instance" });
              setDeleteErrors({});
              setShowConfirmDelete(true);
            }}
            onStartInstance={(instance) => {
              setConfirmAction({
                action: "start",
                resourceType: "instance",
                resourceName: instance.name,
                onConfirm: () => handleInstanceAction("start", instance),
              });
              setShowConfirmAction(true);
            }}
            onStopInstance={(instance) => {
              setConfirmAction({
                action: "stop",
                resourceType: "instance",
                resourceName: instance.name,
                onConfirm: () => handleInstanceAction("stop", instance),
              });
              setShowConfirmAction(true);
            }}
            onRebootInstance={(instance) => {
              setConfirmAction({
                action: "reboot",
                resourceType: "instance",
                resourceName: instance.name,
                onConfirm: () => handleInstanceAction("reboot", instance),
              });
              setShowConfirmAction(true);
            }}
            onModifyInstance={(instance) => {
              setSelectedResource({ resource: instance });
              setShowModifyInstance(true);
            }}
            onAddVolume={(instance) => {
              setSelectedResource({ resource: instance });
              setShowAddVolume(true);
            }}
            onShareInstanceUsers={(instance) => {
              setSelectedResource({ resource: instance });
              setShowShareInstanceUsers(true);
            }}
            onChangeInstanceProfile={(instance) => {
              setSelectedResource({ resource: instance });
              setShowChangeInstanceProfile(true);
            }}
            onAddSSHKey={(instance) => {
              setSelectedResource({ resource: instance });
              setShowAddSSHKey(true);
            }}
          >
            <InstanceSubTables
              instanceTypes={instanceTypes}
              onDelete={(resource, type) => {
                setSelectedResource({ resource, type });
                setDeleteErrors({});
                setShowConfirmDelete(true);
              }}
              onDetachVolume={(volume) => {
                setConfirmAction({
                  action: "detach",
                  resourceType: "volume",
                  resourceName: volume.name,
                  onConfirm: () => detachVolume(volume),
                });
                setShowConfirmAction(true);
              }}
              onModifyVolume={(volume) => {
                setSelectedResource({ resource: volume });
                setShowModifyVolume(true);
              }}
            />
          </InstancesTable>

          <Row className="mt-3">
            <Col>
              <p className="text-muted mb-0">* All costs are monthly</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {showConfirmDelete && (
        <ConfirmDelete
          name={selectedResource.resource.name}
          resourceType={selectedResource.type}
          isDeleting={isDeleting}
          deleteWarning={
            selectedResource.type === "instance"
              ? "Be aware that volumes are also deleted when you delete an instance. Detach volumes first if you want to keep them."
              : ""
          }
          onCancel={() => {
            setShowConfirmDelete(false);
            setSelectedResource({});
            setDeleteErrors({});
          }}
          onConfirm={onConfirmDelete}
          errors={deleteErrors}
        ></ConfirmDelete>
      )}

      {showConfirmAction && (
        <ConfirmAction
          action={confirmAction}
          isLoading={isActionLoading}
          onCancel={() => {
            setShowConfirmAction(false);
            setConfirmAction({});
            setConfirmActionErrors({});
          }}
          errors={confirmActionErrors}
          onConfirm={confirmAction.onConfirm}
        ></ConfirmAction>
      )}

      {showLaunchInstance && (
        <LaunchInstance
          instanceTypes={instanceTypes}
          volumeTypes={volumeTypes}
          getResources={getResources}
          sshKeys={sshKeys}
          close={() => setShowLaunchInstance(false)}
        ></LaunchInstance>
      )}

      {showModifyInstance && (
        <ModifyInstance
          instance={selectedResource.resource}
          instanceTypes={instanceTypes}
          getResources={getResources}
          close={() => {
            setShowModifyInstance(false);
            setSelectedResource({});
          }}
        ></ModifyInstance>
      )}

      {showModifyVolume && (
        <ModifyVolume
          volume={selectedResource.resource}
          volumeTypes={volumeTypes}
          getResources={getResources}
          close={() => {
            setShowModifyVolume(false);
            setSelectedResource({});
          }}
        ></ModifyVolume>
      )}

      {showAddVolume && (
        <AddVolume
          instance={selectedResource.resource}
          volumeTypes={volumeTypes}
          getResources={getResources}
          tableRef={tableRef}
          close={(success) => {
            success &&
              tableRef.current.expandRow(selectedResource.resource, "name");
            setShowAddVolume(false);
            setSelectedResource({});
          }}
        ></AddVolume>
      )}

      {showChangeInstanceProfile && (
        <ChangeInstanceProfile
          instance={selectedResource.resource}
          getResources={getResources}
          close={() => {
            setShowChangeInstanceProfile(false);
            setSelectedResource({});
          }}
        ></ChangeInstanceProfile>
      )}

      {showAddSSHKey && (
        <AddSSHKey
          instance={selectedResource.resource}
          getResources={getResources}
          sshKeys={sshKeys}
          close={() => {
            setShowAddSSHKey(false);
            setSelectedResource({});
          }}
        ></AddSSHKey>
      )}

      {showShareInstanceUsers && (
        <ShareInstanceUsers
          instance={selectedResource.resource}
          users={users}
          getResources={getResources}
          close={() => {
            setShowShareInstanceUsers(false);
            setSelectedResource({});
          }}
        ></ShareInstanceUsers>
      )}
    </>
  );
}
