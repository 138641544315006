import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Amplify, Auth } from "aws-amplify";

import { createAwsConfig } from "../../../utils";
import { ButtonWithSpinner } from "../../common";

import { ReactComponent as ExcelraLogo } from "../../../media/excelra_logo.svg";
import "./login.scss";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [showPasswordResetRequested, setShowPasswordResetRequested] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = () => {
    setIsLoading(true);
    Amplify.configure(createAwsConfig(true));

    Auth.forgotPassword(email)
      .then(() => setShowPasswordResetRequested(true))
      .catch((error) => {
        if (error.code === "UserNotFoundException") {
          error.message = "Email does not exist.";
        }
        setErrors({ "": error.message });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div id="login">
      <Container fluid>
        <Row className="justify-content-center align-items-center">
          <Col xs={10} className="card-container">
            <Card className="my-5">
              <Card.Header>
                <ExcelraLogo fill="white" width="275" height="100%" />
              </Card.Header>
              <Card.Body>
                <div className="w-75 m-auto">
                  {showPasswordResetRequested ? (
                    <>
                      <h4 className="text-center font-weight-bold mb-3">
                        Password Reset Request
                      </h4>
                      <p className="text-center">
                        An email has been sent to reset your password. Please
                        also check your spam folder.
                      </p>

                      <div className="text-center mt-3">
                        <a href="/login/">back to login</a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-center">
                        <h4 className="text-center font-weight-bold mb-3">
                          Forgot your password?
                        </h4>
                        <p>
                          Enter your email below and we will send an email to
                          reset your password.
                        </p>
                      </div>
                      <Form
                        id="new-pwd-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleResetPassword();
                        }}
                      >
                        <Form.Group>
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            autoComplete="email"
                            onChange={(e) => {
                              setErrors({});
                              setEmail(e.target.value);
                            }}
                            value={email}
                            required
                          />
                        </Form.Group>

                        <Form.Group>
                          {!!errors[""] && (
                            <p className="text-danger">{errors[""]}</p>
                          )}
                        </Form.Group>

                        <div className="mt-4">
                          <ButtonWithSpinner
                            type="submit"
                            block
                            isLoading={isLoading}
                          >
                            Reset Password
                          </ButtonWithSpinner>
                        </div>

                        <div className="text-center mt-3">
                          <a href="/login/">back to login</a>
                        </div>
                      </Form>
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
