import { useState } from "react";

import { useIsMounted } from "hooks";
import {
  ButtonWithSpinner,
  CopyToClipBoardButton,
  ErrorMessages,
  Popup,
} from "components/common";

import { useDataStorage } from "./useDataStorage";
import { Button } from "react-bootstrap";
import { exportToCSV } from "utils";

const RequestTempCreds = ({ bucket, close }) => {
  const isMounted = useIsMounted();
  const { requestTempCreds } = useDataStorage(false);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const [errors, setErrors] = useState(null);

  const handleRequestTempCreds = async () => {
    setLoading(true);
    try {
      const creds = await requestTempCreds(bucket);
      isMounted.current && setCredentials(creds);
    } catch (e) {
      isMounted.current && setErrors(e);
    } finally {
      setLoading(false);
    }
  };

  const downloadCredentials = () => {
    const headers = [
      "AWS ACCESS KEY",
      "AWS SECRET ACCESS KEY",
      "AWS SECURITY TOKEN",
    ];
    const rows = [
      [
        credentials.AccessKeyId,
        credentials.SecretAccessKey,
        credentials.SessionToken,
      ],
    ];
    const filename = `${bucket.name}-credentials.csv`;

    exportToCSV(headers, rows, filename);
  };

  const selectExampleText = () => {
    const text = document.getElementById("example-container");
    const range = document.createRange();
    range.selectNodeContents(text);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <Popup
      title="Request Temporary Credentials"
      size="lg"
      onClose={close}
      btnText="Close"
      disableOutsideClick
    >
      <p>
        Credentials are valid for <b>12 hours</b> and only for the{" "}
        <b>{bucket.name}</b> bucket.
        <br />
        Make sure you <b>copy</b> or <b>download</b> the credentials before
        navigating away from this screen, they will not be shown again.
        <br />
      </p>
      {!credentials && (
        <div className="d-flex w-100 justify-content-center mt-4">
          <ButtonWithSpinner
            size="sm"
            isLoading={loading}
            onClick={handleRequestTempCreds}
          >
            Request Temporary Credentials
          </ButtonWithSpinner>
        </div>
      )}
      {!!credentials && (
        <>
          <div className="d-flex w-100 justify-content-center mt-4">
            <Button size="sm" onClick={downloadCredentials}>
              Download Credentials
            </Button>
          </div>

          <h6 className="mt-4 d-flex align-items-center justify-content-between mr-1">
            <b>Example usage:</b>
            <CopyToClipBoardButton elementId="example-container" />
          </h6>
          <div
            onClick={selectExampleText}
            id="example-container"
            style={{ cursor: "pointer" }}
          >
            {[
              `export AWS_ACCESS_KEY_ID="${credentials.AccessKeyId}"`,
              `export AWS_SECRET_ACCESS_KEY="${credentials.SecretAccessKey}"`,
              `export AWS_SECURITY_TOKEN="${credentials.SessionToken}"`,
              `aws s3 ls s3://${bucket.name}/`,
            ].map((text, index) => {
              return (
                <code
                  key={index}
                  className="d-block word-break-all"
                >{`${text}\n`}</code>
              );
            })}
          </div>
        </>
      )}
      <ErrorMessages errors={errors} className="mt-4" />
    </Popup>
  );
};

export default RequestTempCreds;
