import { useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { useIsMounted, useLambdaApi } from "hooks";
import { FormModal } from "components/common";

const CreateCustomer = ({ customers = [], onCreated }) => {
  const api = useLambdaApi();
  const isMounted = useIsMounted();

  const [show, setShow] = useState(false);
  const [customer, setCustomer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const customersLowerCase = useMemo(
    () => customers.map((c) => c.name.toLowerCase()),
    [customers]
  );

  const createCustomer = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    api
      .execute("customer.create", { customer })
      .then(() => onCreated(customer))
      .then(() => {
        if (isMounted.current) {
          setCustomer("");
          setShow(false);
        }
      })
      .catch((error) => isMounted.current && setErrors(error.response.data))
      .finally(() => isMounted.current && setIsLoading(false));
  };

  const handleChange = (e) => {
    if (customersLowerCase.includes(e.target.value.toLowerCase())) {
      setErrors({ customer: "Customer already exists" });
    } else {
      setErrors({});
    }
    setCustomer(e.target.value);
  };

  return (
    <>
      <Button
        size="sm"
        onClick={() => {
          setShow(true);
        }}
      >
        Create Customer
      </Button>

      {show && (
        <FormModal
          onSubmit={createCustomer}
          onClose={() => {
            setShow(false);
          }}
          errors={errors}
          errorKeysToIgnore={["customer"]}
          title="Create Customer"
          isLoading={isLoading}
          submitButtonText="Create"
        >
          <Form.Group as={Row}>
            <Form.Label column sm="2">
              Customer
            </Form.Label>

            <Col sm="10">
              <Form.Control
                placeholder="Enter Customer"
                value={customer}
                name="customer"
                onChange={handleChange}
                isInvalid={!!errors.customer}
                required
                autoFocus
              />
              <Form.Control.Feedback type="invalid">
                {errors.customer}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </FormModal>
      )}
    </>
  );
};

export default CreateCustomer;
