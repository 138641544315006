import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { useAtomValue } from "jotai";

import constants from "constants";
import { userAtom, sidebarCollapsedAtom } from "store";
import { ReactComponent as ExcelraLogo } from "media/excelra_logo.svg";
import { ReactComponent as ExcelraLogoSquare } from "media/excelra_square_logo.svg";

import MenuItem from "./MenuItem";

import "./sidebar.scss";

const MGMT_APP_MENU_ITEMS = [
  {
    name: "Project Nodes",
    link: "/project-nodes/",
    icon: "cpu",
  },
  {
    name: "Data Storage",
    link: "/data-storage/",
    icon: "hdd",
  },
  {
    name: "Batch",
    link: "/batch/",
    icon: "memory",
  },
  {
    name: "SSH Keys",
    link: "/ssh-keys/",
    icon: "key",
  },
  {
    name: "VPN",
    link: "/vpn/",
    icon: "shield-lock",
    onlyNonAdmin: true,
  },

  {
    name: "Customer Accounts",
    link: "/customer-accounts/",
    icon: "card-text",
    onlyAdmin: true,
  },
  {
    name: "Users",
    link: "/users/",
    icon: "people",
    onlyAdmin: true,
  },
  {
    name: "Projects",
    link: "/projects/",
    icon: "clipboard-data",
    onlyAdmin: true,
  },
];

const getS3AppMenuItems = (buckets) => {
  return buckets.map((bucket) => ({
    name: bucket.name,
    link: `/buckets/?bucket=${bucket.name}`,
    icon: "bucket",
    fullmatch: true,
  }));
};

export default function Sidebar({ buckets }) {
  const sidebarCollapsed = useAtomValue(sidebarCollapsedAtom);
  const user = useAtomValue(userAtom);
  const [menuItems, setMenuItems] = useState([]);

  const menuItemEls = useMemo(() => {
    const elements = [];

    menuItems.forEach((menuItem) => {
      if (
        (menuItem.onlyAdmin && !user.isAdmin) ||
        (menuItem.onlyNonAdmin && user.isAdmin)
      ) {
        return;
      }

      if (sidebarCollapsed) {
        elements.push(
          <OverlayTrigger
            key={menuItem.name}
            placement="right"
            transition={false}
            overlay={<Tooltip>{menuItem.name}</Tooltip>}
          >
            {({ ref, ...triggerHandler }) => (
              <span ref={ref} {...triggerHandler}>
                <MenuItem
                  menuItem={menuItem}
                  collapsed={sidebarCollapsed}
                  fullMatch={menuItem.fullmatch}
                ></MenuItem>
              </span>
            )}
          </OverlayTrigger>
        );
      } else {
        elements.push(
          <MenuItem
            key={menuItem.link}
            menuItem={menuItem}
            collapsed={sidebarCollapsed}
            fullMatch={menuItem.fullmatch}
          ></MenuItem>
        );
      }
    });

    return elements;
  }, [menuItems, sidebarCollapsed, user]);

  useEffect(() => {
    if (constants.IS_S3_APP) {
      setMenuItems(getS3AppMenuItems(buckets));
    } else {
      setMenuItems(MGMT_APP_MENU_ITEMS);
    }
  }, [buckets]);

  return (
    <div className="sidebar-container">
      <div>
        <NavLink to="/" className="logo text-center d-block">
          <span className="logo-large">
            <ExcelraLogo fill="white" width="200" />
          </span>

          <span className="logo-small">
            <ExcelraLogoSquare fill="white" width="50" />
          </span>
        </NavLink>
        <div className="side-nav-spacer"></div>
        <div className="side-nav-title">{`${
          constants.IS_S3_APP ? "Buckets" : "Navigation"
        }`}</div>
      </div>
      <div className="sidebar-content">
        <ul className="side-nav">{menuItemEls}</ul>
      </div>
    </div>
  );
}
