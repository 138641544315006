import { useRef, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { RefreshButton, CardTitle, ConfirmDelete } from "../../common";
import VolumesTable from "./tables/VolumesTable";
import { AttachVolume, ModifyVolume, ShareVolumeUsers } from "./volume-actions";

export default function Volumes({
  isLoading,
  volumes,
  instances,
  volumeTypes,
  users,
  getResources,
  deleteResource,
}) {
  const tableRef = useRef();
  const [selectedResource, setSelectedResource] = useState({});
  const [showModifyVolume, setShowModifyVolume] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showAttachVolume, setShowAttachVolume] = useState(false);
  const [showShareVolumeUsers, setShowShareVolumeUsers] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState({});

  const onConfirmDelete = () => {
    setIsDeleting(true);
    setDeleteErrors({});

    deleteResource(selectedResource)
      .then(() => {
        setShowConfirmDelete(false);
        setSelectedResource({});
      })
      .catch((errors) => setDeleteErrors(errors))
      .finally(() => setIsDeleting(false));
  };

  return (
    <>
      <Card>
        <Card.Body>
          <CardTitle
            title="Volumes"
            subtitle="Displays all volumes that are not attached to an instance"
            showSpinner={isLoading}
          >
            <RefreshButton
              size="sm"
              onRefresh={() => getResources()}
            ></RefreshButton>
          </CardTitle>

          <VolumesTable
            tableRef={tableRef}
            volumes={volumes}
            onModify={(volume) => {
              setSelectedResource({ resource: volume });
              setShowModifyVolume(true);
            }}
            onDelete={(volume) => {
              setSelectedResource({ resource: volume, type: "volume" });
              setShowConfirmDelete(true);
            }}
            onAttach={(volume) => {
              setSelectedResource({ resource: volume });
              setShowAttachVolume(true);
            }}
            onShareVolumeUsers={(volume) => {
              setSelectedResource({ resource: volume });
              setShowShareVolumeUsers(true);
            }}
          ></VolumesTable>

          <Row className="mt-3">
            <Col>
              <p className="text-muted mb-0">* All costs are monthly</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {showConfirmDelete && (
        <ConfirmDelete
          name={selectedResource.resource.name}
          resourceType="volume"
          isDeleting={isDeleting}
          onCancel={() => {
            setShowConfirmDelete(false);
            setSelectedResource({});
            setDeleteErrors({});
          }}
          onConfirm={onConfirmDelete}
          errors={deleteErrors}
        ></ConfirmDelete>
      )}

      {showModifyVolume && (
        <ModifyVolume
          volume={selectedResource.resource}
          volumeTypes={volumeTypes}
          getResources={getResources}
          close={() => {
            setShowModifyVolume(false);
            setSelectedResource({});
          }}
        ></ModifyVolume>
      )}

      {showAttachVolume && (
        <AttachVolume
          volume={selectedResource.resource}
          instances={instances}
          getResources={getResources}
          close={() => {
            setSelectedResource({});
            setShowAttachVolume(false);
          }}
        ></AttachVolume>
      )}

      {showShareVolumeUsers && (
        <ShareVolumeUsers
          volume={selectedResource.resource}
          users={users}
          getVolumes={getResources}
          close={() => {
            setShowShareVolumeUsers(false);
            setSelectedResource({});
          }}
        ></ShareVolumeUsers>
      )}
    </>
  );
}
