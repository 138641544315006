import { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

export default function ExistingResources({ resources, close }) {
  return (
    <Modal show animation={false} onHide={close} scrollable>
      <Modal.Header>
        <Modal.Title>
          <i className="bi bi-exclamation-triangle mr-2"></i>Existing Resources
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p className="mb-4 font-weight-bold">
          Following resources have to be detached or removed before this user
          can be deleted:
        </p>
        <dl className="row">
          {Object.keys(resources).map((resource, index) => (
            <Fragment key={resource + index}>
              <dt className="col-3">{resource}</dt>
              <dd className="col-9">
                <div className="row">
                  {resources[resource].map((resourceItem, index) => (
                    <div key={resourceItem.name + index} className="col-12">
                      {resourceItem.name}
                    </div>
                  ))}
                </div>
              </dd>
            </Fragment>
          ))}
        </dl>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={close}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
