import constants from "../constants";

function createAwsConfig(login_without_sso = false) {
  // https://stackoverflow.com/questions/42712872/how-to-modify-expiry-time-of-the-access-and-identity-tokens-for-aws-cognito-user
  const cognitoRedirectUri = `${window.location.protocol}//${window.location.host}`;

  let userPoolPrefix;
  if (login_without_sso) {
    userPoolPrefix = constants.IS_S3_APP ? "CUSTOMER" : "ADMIN";
  } else {
    userPoolPrefix = "EMPLOYEE";
  }

  const userPoolId = constants[`${userPoolPrefix}_USER_POOL_ID`];
  const userPoolWebClientId =
    constants[`${userPoolPrefix}_USER_POOL_WEB_CLIENT_ID`];
  const userPoolDomain = constants[`${userPoolPrefix}_USER_POOL_DOMAIN`];

  const config = {
    Auth: {
      identityPoolId: constants.IS_S3_APP
        ? constants.IDENTITY_POOL_ID_S3
        : constants.IDENTITY_POOL_ID_MGMT,
      userPoolId,
      userPoolWebClientId,
      region: constants.AWS_REGION,
      redirectSignIn: cognitoRedirectUri,
      redirectSignOut: cognitoRedirectUri,
    },
  };

  if (!login_without_sso) {
    config.Auth.oauth = {
      domain: userPoolDomain,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: cognitoRedirectUri,
      redirectSignOut: cognitoRedirectUri,
      responseType: "code",
    };
  }

  return config;
}

function sortObjectsAlfabetically(objects, sortKey) {
  const copyObjects = JSON.parse(JSON.stringify(objects));

  copyObjects.sort((a, b) => {
    const nameA = a[sortKey] ? a[sortKey].toUpperCase() : a[sortKey];
    const nameB = b[sortKey] ? b[sortKey].toUpperCase() : b[sortKey];

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return copyObjects;
}

// Expects a list of functions that return a promise
// eg:
// const apiCallFuncs = shared.map((share) => () =>
//    api.execute("s3.share", {
//        name: bucket.name,
//        ...share,
//        })
//    );
function callPromisesSerial(promiseFuncs) {
  return promiseFuncs.reduce(
    (promise, func) =>
      promise.then((result) =>
        func().then(Array.prototype.concat.bind(result))
      ),
    Promise.resolve([])
  );
}

function roundToTwo(num) {
  return Number(num.toFixed(2));
}

function applyDefaultFilters(resources, filters) {
  const keys = ["users", "customer", "project"];
  const listFilters = ["users"];
  return resources.filter((resource) => {
    let match = true;
    keys.forEach((filter) => {
      if (!(filter in filters)) {
        return;
      }
      const allowedValue = filters[filter][0];
      if (listFilters.includes(filter)) {
        if (allowedValue && !resource[filter].includes(allowedValue)) {
          match = false;
        }
      } else if (allowedValue && resource[filter] !== allowedValue) {
        match = false;
      }
    });
    return match;
  });
}

function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function sortBooleanField(objectA, objectB, field) {
  const fieldA = objectA.original[field];
  const fieldB = objectB.original[field];
  if (fieldA && !fieldB) {
    return -1;
  } else if (!fieldA && fieldB) {
    return 1;
  }
  return 0;
}

function exportToCSV(headers, rows, filename) {
  // Create CSV content
  let csvContent = headers.join(",") + "\n";
  rows.forEach((rowArray) => {
    csvContent += rowArray.join(",") + "\n";
  });

  // Create a Blob with the CSV content and get its URL
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  // Create a download link element, click it and clean it up
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export {
  createAwsConfig,
  sortObjectsAlfabetically,
  callPromisesSerial,
  roundToTwo,
  applyDefaultFilters,
  createUUID,
  sortBooleanField,
  exportToCSV,
};
