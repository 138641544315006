import { useEffect } from "react";
import { useAtom, useSetAtom } from "jotai";

import { userAtom, addNotificationAtom } from "store";
import constants from "constants";

import useLambdaApi from "./useLambdaApi";
import useAuth from "./useAuth";
import useIsMounted from "./useIsMounted";

const useUser = () => {
  const { cognitoUser } = useAuth();
  const api = useLambdaApi();
  const [user, setUser] = useAtom(userAtom);
  const isMounted = useIsMounted();
  const addNotification = useSetAtom(addNotificationAtom);

  const getUserInfo = async () => {
    api.execute("user.current").then((userInfo) => {
      if (!isMounted.current) return;
      setUser((user) => ({
        ...user,
        email: cognitoUser.attributes.email,
        isAdmin: userInfo.is_admin,
        canManage: userInfo.can_manage,
      }));

      if (!userInfo.is_admin) {
        checkIfVpnProfileCreated();
        checkIfSshKeyExists();
      }
    });
  };

  const checkIfVpnProfileCreated = () => {
    api.execute("vpn.is_configured").then((response) => {
      if (isMounted.current && !response.configured) {
        addNotification({
          id: "vpn-profile-not-found",
          title: "VPN profile not found",
          message: "Please generate a VPN profile",
          autohide: false,
        });
      }
    });
  };

  const checkIfSshKeyExists = () => {
    api.execute("ec2.keypair.list").then((keys) => {
      if (isMounted.current && !keys.length) {
        addNotification({
          id: "ssh-key-not-found",
          title: "SSH key not found",
          message: "Please generate an SSH key",
          autohide: false,
        });
      }
    });
  };

  useEffect(() => {
    if (!cognitoUser || user || !api.isConfigured()) return;

    // if S3 app, we don't need to fetch user info
    if (constants.IS_S3_APP) {
      setUser((user) => ({
        ...user,
        email: cognitoUser.attributes.email,
      }));
    } else {
      getUserInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cognitoUser, user]);

  return {
    user: user,
  };
};

export default useUser;
