import { useMemo } from "react";
import { Badge } from "react-bootstrap";
import { useAtomValue } from "jotai";

import { Table, IconButton } from "components/common";
import { roundToTwo } from "utils";
import { userAtom } from "store";

import {
  getTotalInstanceCost,
  instanceStates,
  isInstanceInIntermediateState,
} from "../utils";

export default function InstancesTable({
  tableRef,
  instances,
  setFilteredInstances,
  children,
  onDelete,
  onStartInstance,
  onStopInstance,
  onModifyInstance,
  onAddVolume,
  onShareInstanceUsers,
  onChangeInstanceProfile,
  onAddSSHKey,
}) {
  const user = useAtomValue(userAtom);

  const columns = useMemo(() => {
    const columns = [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "State",
        accessor: "state",
        Cell: ({ value }) => (
          <h6 className="mb-0">
            {!!instanceStates[value] && (
              <Badge
                pill
                variant={instanceStates[value].variant}
                className="state-pill d-flex"
              >
                <i className={`bi ${instanceStates[value].icon}`} />
                {value}
              </Badge>
            )}
          </h6>
        ),
      },
      {
        Header: "IP",
        accessor: "ip",
      },
      {
        Header: "Cost *",
        id: "cost",
        accessor: "total",
        sortType: (a, b) => {
          const costA = getTotalInstanceCost(a.original);
          const costB = getTotalInstanceCost(b.original);

          return costA > costB ? 1 : costA < costB ? -1 : 0;
        },
        Cell: ({ row }) => {
          const total = getTotalInstanceCost(row.original);

          return `$${roundToTwo(total)}`;
        },
      },
      {
        Header: "Customer",
        accessor: "customer",
      },
      {
        Header: "Project",
        accessor: "project",
      },
      {
        Header: "Instance Profile",
        accessor: "instance_profile",
      },
      {
        Header: "Users",
        accessor: "users",
        filter: "includes",
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          const instance = row.original;
          const canStart = instance.state === "stopped";

          if (instance.state === "shutting-down") {
            return null;
          }

          return (
            <>
              <div>
                <IconButton
                  title={canStart ? "Start" : "Stop"}
                  icon={canStart ? "play-fill" : "stop-fill"}
                  className="mr-2"
                  disabled={isInstanceInIntermediateState(instance.state)}
                  onClick={() =>
                    canStart
                      ? onStartInstance(instance)
                      : onStopInstance(instance)
                  }
                ></IconButton>

                {user.canManage && (
                  <IconButton
                    title={
                      instance.isOld
                        ? "Old instances cannot be modified."
                        : "Modify"
                    }
                    icon="pencil-fill"
                    className="mr-2"
                    disabled={instance.isOld || instance.state !== "stopped"}
                    onClick={() => onModifyInstance(instance)}
                  ></IconButton>
                )}

                {!user.isAdmin && (
                  <IconButton
                    title="Add SSH Key"
                    icon="key-fill"
                    className="mr-2 bold extra-bold"
                    disabled={instance.state !== "running"}
                    onClick={() => onAddSSHKey(instance)}
                  ></IconButton>
                )}

                {user.canManage && (
                  <IconButton
                    title="Share With Users"
                    icon="people-fill"
                    className="mr-2"
                    onClick={() => onShareInstanceUsers(instance)}
                  ></IconButton>
                )}

                {user.isAdmin && (
                  <IconButton
                    title="Change Instance Profile"
                    icon="person-fill-gear"
                    className="mr-2 bold extra-bold"
                    disabled={instance.state !== "stopped"}
                    onClick={() => onChangeInstanceProfile(instance)}
                  ></IconButton>
                )}

                {!user.isAdmin && user.canManage && (
                  <IconButton
                    title="Add Volume"
                    icon="folder-plus"
                    className="mr-2 bold extra-bold"
                    disabled={instance.state === "pending"}
                    onClick={() => onAddVolume(instance)}
                  ></IconButton>
                )}

                {user.canManage && (
                  <IconButton
                    title="Delete"
                    icon="trash-fill"
                    onClick={() => onDelete(instance)}
                  ></IconButton>
                )}
              </div>
            </>
          );
        },
      },
    ];

    return columns;
  }, [
    user,
    onDelete,
    onStartInstance,
    onStopInstance,
    onModifyInstance,
    onAddVolume,
    onShareInstanceUsers,
    onChangeInstanceProfile,
    onAddSSHKey,
  ]);

  return (
    <Table
      ref={tableRef}
      columns={columns}
      data={instances}
      setFilteredRows={setFilteredInstances}
      defaultPageSize={25}
      subTableDataFields={["volumes"]}
      hiddenColumns={["instance_profile", "users"]}
    >
      {children}
    </Table>
  );
}
