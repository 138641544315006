import { Col, Form } from "react-bootstrap";

import { ButtonWithSpinner } from "components/common";
import { ReactComponent as MicrosoftLogo } from "media/microsoft.svg";
import constants from "constants";

export default function LoginForm({
  username,
  password,
  isLoggingInFederated,
  isLoggingIn,
  handleFederatedLogin,
  handleLogIn,
  errors,
  setState,
}) {
  return (
    <>
      <div className="text-center">
        <div className="my-2">
          <ButtonWithSpinner
            block
            onClick={handleFederatedLogin}
            isLoading={isLoggingInFederated}
          >
            <MicrosoftLogo width="25" height="25" className="mr-2 pb-1" />
            Sign in with Microsoft
          </ButtonWithSpinner>
        </div>
        <div className="my-4">OR</div>
        <h4 className="text-center font-weight-bold mb-3">
          {`${constants.IS_S3_APP ? "Customer" : "Admin"} Log In`}
        </h4>
      </div>
      <Form
        id="login-form"
        onSubmit={(e) => {
          e.preventDefault();
          handleLogIn(username, password);
        }}
      >
        <Form.Group>
          <Form.Label>{`${
            constants.IS_S3_APP ? "Username" : "Email"
          }`}</Form.Label>
          <Form.Control
            placeholder={`Enter ${constants.IS_S3_APP ? "Username" : "Email"}`}
            autoComplete={`${constants.IS_S3_APP ? "username" : "email"}`}
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                errors: {},
                username: e.target.value,
              }));
            }}
            value={username}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="row">
            <Col>Password</Col>
            <Col xs="auto" className="text-right">
              <a className="small" href="/reset-password/" tabIndex="-1">
                Forgot your password?
              </a>
            </Col>
          </Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            autoComplete="current-password"
            onChange={(e) => {
              setState((prevState) => ({
                ...prevState,
                errors: {},
                password: e.target.value,
              }));
            }}
            value={password}
            required
          />
        </Form.Group>
        <Form.Group>
          {!!(errors && errors[""]) && (
            <p className="text-danger">{errors[""]}</p>
          )}
        </Form.Group>
        <div className="mt-4">
          <ButtonWithSpinner type="submit" block isLoading={isLoggingIn}>
            Login
          </ButtonWithSpinner>
        </div>
      </Form>
    </>
  );
}
