import { Row, Col } from "react-bootstrap";

export default function ErrorMessages({
  errors = {},
  keysToIgnore = [],
  position = "end",
  className = "",
}) {
  const errorKeys = errors ? Object.keys(errors) : [];

  if (errorKeys.length) {
    const errorMsgs = errorKeys
      .filter((key) => !keysToIgnore.includes(key))
      .map((key) => (
        <p key={`error-${key}`} className="text-danger m-0">
          {errors[key]}
        </p>
      ));

    return (
      <Row className={className}>
        <Col className={`d-flex justify-content-${position}`}>
          <Row>
            <Col>{errorMsgs}</Col>
          </Row>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
}
