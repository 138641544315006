import { useEffect, useState } from "react";
import { Card, Form, Row } from "react-bootstrap";

import { applyDefaultFilters } from "../../../utils";
import { CardTitle, DefaultFilters } from "../../common";

export default function Filters({
  buckets,
  filteredBuckets,
  setFilteredBuckets,
}) {
  const [filters, setFilters] = useState({
    users: [],
    customer: [],
    project: [],
  });

  useEffect(() => {
    setFilteredBuckets(applyDefaultFilters(buckets, filters));
  }, [buckets, filters, setFilteredBuckets]);

  return (
    <Card className="h-100 instances-card">
      <Card.Body>
        <CardTitle title="Filters" showSpinner={false}></CardTitle>
        <Form autoComplete="false">
          <Row>
            <DefaultFilters
              filteredResources={filteredBuckets}
              filters={filters}
              setFilters={setFilters}
              resetFilters={() =>
                setFilters({
                  users: [],
                  customer: [],
                  project: [],
                })
              }
            ></DefaultFilters>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
