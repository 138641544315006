import { useEffect, useState, useCallback } from "react";
import { FormControl } from "react-bootstrap";

import UploadFailed from "./UploadFailed";
import UploadProgress from "./UploadProgress";
import { createUUID } from "utils";
import { useLambdaApi } from "hooks";

const FIVE_GB = 5 * 1024 * 1024 * 1024;

export default function Upload({ variant, bucket, curPath, getObjects }) {
  const api = useLambdaApi();
  const [uploads, setUploads] = useState({});
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const [showUploadFailed, setShowUploadFailed] = useState(false);
  const [errorFile, setErrorFile] = useState(null);

  const handleUpload = (e) => {
    setShowUploadProgress(true);
    const files = Object.values(e.target.files);
    const uploads = {};
    files.forEach((file) => {
      const key = createUUID();
      uploads[key] = {
        file,
        progress: [0],
        started: false,
      };
    });
    setUploads(uploads);
    // Reset upload input
    e.target.value = "";
  };

  const updateUploadProgress = useCallback((key, progress, index = 0) => {
    setUploads((prevUploads) => {
      const upload = prevUploads[key];
      if (upload) {
        upload.progress[index] = progress;
        return { ...prevUploads, [key]: upload };
      }
      return prevUploads;
    });
  }, []);

  const updateMultiPartUpload = useCallback((key, complete) => {
    setUploads((prevUploads) => {
      const upload = prevUploads[key];
      if (upload) {
        upload.multi = true;
        upload.complete = complete;
        return { ...prevUploads, [key]: upload };
      }
      return prevUploads;
    });
  }, []);

  const request = useCallback(
    (method, url, key, data, callback, errorCallback, index) => {
      const req = new XMLHttpRequest();
      req.open(method, url, true);
      req.onload = () => callback(req.status, req.responseText);
      req.onerror = errorCallback;
      req.onabort = errorCallback;
      req.upload.addEventListener("progress", (e) => {
        updateUploadProgress(key, e.loaded, index);
      });
      req.send(data);
    },
    [updateUploadProgress]
  );

  const continueUpload = useCallback(
    (key, file, data) => {
      const promise = new Promise((resolve, reject) => {
        const formData = new FormData();

        if (data.url) {
          Object.keys(data.fields).forEach((key) => {
            formData.append(key, data.fields[key]);
          });
          formData.append("file", file);

          request(
            "POST",
            data.url,
            key,
            formData,
            (status) => {
              if (status !== 204) {
                reject();
              } else {
                resolve();
              }
            },
            () => {
              reject();
            },
            0
          );
        } else if (data.urls) {
          updateMultiPartUpload(key, false);
          const promises = data.urls.map((url, index) => {
            const promise = new Promise((resolve, reject) => {
              const start = FIVE_GB * index;
              const end = Math.min(start + FIVE_GB, file.size);
              const part = file.slice(start, end);

              updateUploadProgress(key, 0, index);

              request(
                "PUT",
                url,
                key,
                part,
                (status) => {
                  if (status === 200) {
                    resolve();
                  } else {
                    reject();
                  }
                },
                () => reject(),
                index
              );
            });

            return promise;
          });

          const params = {
            bucket: bucket.name,
            key: `${curPath}/${file.name}`,
            upload_id: data.upload_id,
          };
          Promise.all(promises)
            .then(() =>
              api.execute("s3.upload_complete", params).then(() => {
                updateMultiPartUpload(key, true);
                resolve();
              })
            )
            .catch(() => {
              api.execute("s3.upload_abort", params);
              reject();
            });
        }
      });

      return promise;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bucket, curPath, request, updateUploadProgress, updateMultiPartUpload]
  );

  const startUpload = useCallback(
    (key, file) => {
      api
        .execute("s3.upload", {
          bucket: bucket.name,
          key: curPath ? `${curPath}/${file.name}` : file.name,
          size: file.size,
        })
        .then((data) => continueUpload(key, file, data))
        .catch(() => {
          setErrorFile(file.name);
          setShowUploadProgress(false);
          setShowUploadFailed(true);
          setUploads({});
        })
        .finally(getObjects);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bucket, continueUpload, getObjects, curPath]
  );

  useEffect(() => {
    Object.keys(uploads)
      .filter((key) => !uploads[key].started)
      .forEach((key) => {
        const upload = uploads[key];
        upload.started = true;
        setUploads({ ...uploads, [key]: upload });
        startUpload(key, upload.file);
      });
  }, [uploads, startUpload]);

  return (
    <>
      <label className={`btn btn-${variant || "primary"} mb-0 mr-2`}>
        Upload
        <FormControl
          type="file"
          hidden
          multiple
          onChange={handleUpload}
        ></FormControl>
      </label>

      {showUploadFailed && (
        <UploadFailed
          file={errorFile}
          onClose={() => {
            setShowUploadFailed(false);
            setErrorFile(null);
          }}
        ></UploadFailed>
      )}

      {showUploadProgress && (
        <UploadProgress
          uploads={Object.values(uploads)}
          onClose={() => {
            setShowUploadProgress(false);
            setUploads({});
          }}
        ></UploadProgress>
      )}
    </>
  );
}
