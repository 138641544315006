import { Modal, Button } from "react-bootstrap";

export default function Popup({
  title,
  message = null,
  btnText,
  onClose,
  size = null,
  children = null,
  disableOutsideClick = false,
}) {
  return (
    <Modal
      show
      animation={false}
      onHide={onClose}
      size={size}
      backdrop={disableOutsideClick ? "static" : true}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children ? children : message}</Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose}>{btnText || "ok"}</Button>
      </Modal.Footer>
    </Modal>
  );
}
