import { Modal, Button, Row, Col } from "react-bootstrap";

import ButtonWithSpinner from "./ButtonWithSpinner";
import ErrorMessages from "./ErrorMessages";

export default function ConfirmDelete({
  name,
  resourceType,
  isDeleting,
  onCancel,
  onConfirm,
  deleteWarning,
  errors,
}) {
  return (
    <Modal show animation={false} onHide={onCancel} backdrop="static">
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {deleteWarning && (
          <Row className="mb-3">
            <Col>
              <p className="mb-0">
                <i className="bi bi-exclamation-triangle mr-2"></i>
                {deleteWarning}
              </p>
            </Col>
          </Row>
        )}

        <p>
          Are you sure you want to delete {resourceType ? resourceType : ""} '
          {name}'?
        </p>

        <ErrorMessages errors={errors} className="mt-4"></ErrorMessages>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="light" onClick={onCancel} disabled={isDeleting}>
          Cancel
        </Button>
        <ButtonWithSpinner
          type="submit"
          block={false}
          onClick={onConfirm}
          isLoading={isDeleting}
        >
          Confirm
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
}
