import { Container, Row, Col } from "react-bootstrap";

import "./footer.scss";

export default function Footer() {
  return (
    <footer>
      <Container fluid>
        <Row>
          <Col>
            <p className="mb-0">
              {new Date().getFullYear()} &#169; Excelra, All rights reserved
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
