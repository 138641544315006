import { Fragment, useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";

import { ButtonWithSpinner } from "components/common";

export default function UploadProgress({ uploads, onClose }) {
  const [isUploading, setIsUploading] = useState(true);

  const getProgress = (upload) => {
    const progress = upload.progress.reduce((acc, val) => acc + val, 0);
    let percentage = Math.round((progress / upload.file.size) * 100);
    return percentage > 100 ? 100 : percentage;
  };

  useEffect(() => {
    setIsUploading(
      !uploads.every((upload) => {
        if (upload.multi) {
          return upload.complete;
        } else {
          return getProgress(upload) === 100;
        }
      })
    );
  }, [uploads]);

  return (
    <Modal
      show
      animation={false}
      onHide={onClose}
      backdrop="static"
      size="lg"
      className="upload-progress-modal"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>Upload Progress</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="text-center mb-4 mt-2">
          Uploading files. Please do not close your browser until all files have
          finished uploading.
        </h6>
        {uploads.map((upload) => {
          const progress = getProgress(upload);

          return (
            <Fragment key={upload.file.name}>
              <dl className="row mb-0">
                <dt className="col-4 font-weight-normal my-auto">
                  {upload.file.name}
                </dt>
                <dd className="col my-auto">
                  <ProgressBar
                    now={progress}
                    label={`${progress}%`}
                  ></ProgressBar>
                </dd>
              </dl>
            </Fragment>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithSpinner onClick={onClose} isLoading={isUploading}>
          {isUploading ? "Uploading" : "Close"}
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
}
