import { useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";

import { ButtonWithSpinner, ErrorMessages, Popup } from "components/common";
import { useLambdaApi } from "hooks";

export default function Generate({ getSshKeys }) {
  const api = useLambdaApi();
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [generatedKey, setGeneratedKey] = useState("");
  const [errors, setErrors] = useState({});
  const [showWarning, setShowWarning] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    setGeneratedKey("");

    api
      .execute("ec2.keypair.generate", { name })
      .then((response) => {
        setGeneratedKey(response.private_key);
        setErrors({});
        setName("");
        setShowWarning(true);
      })
      .catch((errors) => setErrors(errors))
      .finally(() => {
        getSshKeys();
        setIsLoading(false);
      });
  };

  const handleNameChange = (e) => {
    setErrors({});
    setName(e.target.value);
  };

  return (
    <>
      <Card className="h-100">
        <Card.Body>
          <Row className="mb-3">
            <Col>
              <h4 className="header-title">Generate key</h4>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="generate-key-container">
                <Form.Control
                  className="generate-key-box"
                  as="textarea"
                  rows={7}
                  value={generatedKey}
                  readOnly={generatedKey}
                  disabled={!generatedKey}
                />
              </div>
            </Col>
          </Row>

          <Form className="mt-3" onSubmit={handleSubmit}>
            <Row>
              <Form.Group as={Col}>
                <Form.Control
                  placeholder="Name"
                  value={name}
                  onChange={handleNameChange}
                  isInvalid={!!errors.name}
                  required
                ></Form.Control>
                <Form.Text className="text-muted">
                  Ssh key names have to be unique troughout the whole AWS
                  account.
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Col xs="auto">
                <ButtonWithSpinner
                  type="submit"
                  block
                  disabled={!!errors.name}
                  isLoading={isLoading}
                >
                  Generate
                </ButtonWithSpinner>
              </Col>
            </Row>

            <ErrorMessages
              errors={errors}
              keysToIgnore={["name"]}
              position="left"
              className="mt-3"
            ></ErrorMessages>
          </Form>
        </Card.Body>
      </Card>

      {showWarning && (
        <Popup
          message={
            <p>
              Make sure you copy the SSH Key before navigating away from this
              page. It will not be shown again.
            </p>
          }
          title="SSH Key"
          onClose={() => setShowWarning(false)}
        ></Popup>
      )}
    </>
  );
}
