import { useMemo } from "react";

import { IconButton, Table as CommonTable } from "components/common";
import { sortBooleanField } from "utils";

import CanManage from "./CanManage";

export default function Table({
  data,
  onDelete,
  updateCanManage,
  onDeleteVpnConfig,
}) {
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "name",
      },
      {
        Header: "Last Activity",
        accessor: "last_activity",
        Cell: ({ value }) => value && new Date(value).toLocaleString(),
      },
      {
        Header: "Is Admin",
        accessor: "is_admin",
        sortType: (a, b) => sortBooleanField(a, b, "is_admin"),
        Cell: ({ value: isAdmin }) => {
          return (
            isAdmin && (
              <h4>
                <i className="bi bi-check2"></i>
              </h4>
            )
          );
        },
      },
      {
        Header: "Can Manage",
        accessor: "can_manage",
        sortType: (a, b) => sortBooleanField(a, b, "can_manage"),
        Cell: ({ row }) => {
          return (
            <CanManage user={row.original} updateCanManage={updateCanManage} />
          );
        },
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          return (
            <>
              {row.original.vpn && (
                <IconButton
                  title="Remove Vpn Config"
                  icon="shield-fill-x"
                  onClick={() => onDeleteVpnConfig(row.original)}
                  className="mr-2"
                />
              )}
              <IconButton
                title="Delete"
                icon="trash-fill"
                onClick={() => onDelete(row.original)}
              />
            </>
          );
        },
      },
    ],
    [onDelete, updateCanManage, onDeleteVpnConfig]
  );

  return <CommonTable columns={columns} data={data} />;
}
