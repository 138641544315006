import { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import { useIsMounted } from "hooks";
import {
  CardTitle,
  ConfirmDelete,
  Popup,
  RefreshButton,
} from "components/common";

import Table from "./Table";
import ExistingResources from "./ExistingResources";
import useUsersApi from "./useUsers";

import "./users.scss";

export default function Users() {
  const isMounted = useIsMounted();
  const {
    users,
    isLoading,
    getUsers,
    deleteUser,
    updateUserCanManage,
    deleteUserVpnConfig,
  } = useUsersApi();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmDeleteVpn, setShowConfirmDeleteVpn] = useState(false);
  const [deleteErrors, setDeleteErrors] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showExistingResources, setShowExistingResources] = useState(false);
  const [existingResources, setExistingResources] = useState(null);

  const [showError, setShowError] = useState(false);

  const deleteSelectedUser = () => {
    setIsDeleting(true);
    setDeleteErrors(null);

    deleteUser(selectedUser)
      .then(() => {
        if (isMounted.current) {
          setShowConfirmDelete(false);
          setSelectedUser(null);
        }
      })
      .catch((errors) => {
        if (!isMounted.current) return;

        if ("existing_resources" in errors) {
          setShowConfirmDelete(false);
          setExistingResources(errors.existing_resources);
          setShowExistingResources(true);
        } else {
          setDeleteErrors(errors);
        }
      })
      .finally(() => setIsDeleting(false));
  };

  const updateCanManage = (user, canManage) => {
    updateUserCanManage(user, canManage).catch(
      () => isMounted.current && setShowError(true)
    );
  };

  const deleteSelectedUserVpnConfig = () => {
    setIsDeleting(true);
    setDeleteErrors(null);

    deleteUserVpnConfig(selectedUser)
      .then(() => {
        if (isMounted.current) {
          setShowConfirmDeleteVpn(false);
          setSelectedUser(null);
        }
      })
      .catch((errors) => {
        if (isMounted.current) {
          setDeleteErrors(errors);
        }
      })
      .finally(() => setIsDeleting(false));
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid={true} className="page users">
      <Row>
        <Col>
          <div className="page-title-box">
            <h3 className="page-title">Users</h3>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <CardTitle title="Users" showSpinner={isLoading}>
                <RefreshButton size="sm" onRefresh={getUsers}></RefreshButton>
              </CardTitle>
              <Table
                data={users}
                onDelete={(user) => {
                  setSelectedUser(user);
                  setShowConfirmDelete(true);
                }}
                onDeleteVpnConfig={(user) => {
                  setSelectedUser(user);
                  setShowConfirmDeleteVpn(true);
                }}
                updateCanManage={updateCanManage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showConfirmDelete && (
        <ConfirmDelete
          name={selectedUser.name}
          resourceType="user"
          isDeleting={isDeleting}
          errors={deleteErrors}
          onCancel={() => {
            setShowConfirmDelete(false);
            setDeleteErrors(null);
            setSelectedUser(null);
          }}
          onConfirm={deleteSelectedUser}
        />
      )}

      {showConfirmDeleteVpn && (
        <ConfirmDelete
          name={selectedUser.name}
          resourceType="VPN config of"
          isDeleting={isDeleting}
          errors={deleteErrors}
          onCancel={() => {
            setShowConfirmDeleteVpn(false);
            setDeleteErrors(null);
            setSelectedUser(null);
          }}
          onConfirm={deleteSelectedUserVpnConfig}
        />
      )}

      {showExistingResources && (
        <ExistingResources
          resources={existingResources}
          close={() => {
            setShowExistingResources(false);
            setExistingResources(null);
          }}
        />
      )}

      {showError && (
        <Popup
          title="Error"
          message="Failed to update user. Please try again or contact support."
          onClose={() => setShowError(false)}
        />
      )}
    </Container>
  );
}
