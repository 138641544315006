import { useMemo } from "react";
import { Button } from "react-bootstrap";
import PrettyBytes from "pretty-bytes";

import { Table as CommonTable } from "components/common";
import Upload from "./Upload";
import DownloadLink from "./DownloadLink";

export default function Table({
  tableRef,
  isLoading,
  bucket,
  objects,
  getObjects,
  curPath,
  onUploadByUrl,
  selectedRows,
  setSelectedRows,
  onObjectClicked,
}) {
  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          const isDirectory = row.original.is_directory;
          return (
            <>
              {isDirectory ? (
                <i className="bi bi-folder mr-2"></i>
              ) : (
                <i className="bi bi-file-earmark mr-2"></i>
              )}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  onObjectClicked(row.original);
                }}
              >
                {`${row.original.name}${isDirectory ? "/" : ""}`}
              </a>
              {!isDirectory && (
                <DownloadLink
                  bucket={bucket}
                  file={row.original}
                  curPath={curPath}
                />
              )}
            </>
          );
        },
      },
      {
        Header: "Last Modified",
        accessor: "mtime",
        Cell: ({ value }) => value && new Date(value).toLocaleString(),
      },
      {
        Header: "Size",
        accessor: "size",
        Cell: ({ value }) => (value ? PrettyBytes(value) : null),
      },
    ];
  }, [bucket, curPath, onObjectClicked]);

  const data = useMemo(() => objects, [objects]);

  return (
    <CommonTable
      ref={tableRef}
      data={data}
      columns={columns}
      emptyMsg={
        <>
          {!isLoading && (
            <>
              <p className="font-weight-bold mt-2 mb-1">
                {bucket.mode === "wo" ? "No permissions" : "No Objects"}
              </p>
              {bucket.mode === "wo" ? (
                <>
                  <p>You are not allowed to view objects in this bucket.</p>
                </>
              ) : (
                <p>
                  You don't have any objects in this{" "}
                  {curPath ? "folder" : "bucket"}.
                </p>
              )}
              {bucket.mode !== "ro" && (
                <>
                  <Upload
                    variant="outline-primary"
                    bucket={bucket}
                    curPath={curPath}
                    getObjects={getObjects}
                  ></Upload>
                  <Button variant="outline-primary" onClick={onUploadByUrl}>
                    Upload By Url
                  </Button>
                </>
              )}
            </>
          )}
        </>
      }
      enableMultiRowSelection={true}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
    ></CommonTable>
  );
}
