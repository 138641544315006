import { useEffect, useState } from "react";
import { useIsMounted, useLambdaApi } from "hooks";
import PrettyBytes from "pretty-bytes";
import { roundToTwo } from "utils";

export const useDataStorage = (fetchOnLoad = true) => {
  const api = useLambdaApi();
  const isMounted = useIsMounted();
  const [buckets, setBuckets] = useState([]);
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchBuckets = () => {
    setIsLoading(true);
    return api.execute("s3.list").then((buckets) => {
      setIsLoading(false);
      setBuckets(buckets);
    });
  };

  const fetchCustomerAccounts = () => {
    return api
      .execute("customer_account.list")
      .then(
        (customerAccounts) =>
          isMounted.current && setCustomerAccounts(customerAccounts)
      );
  };

  const fetchUsers = () => {
    return api
      .execute("user.list", { is_admin: false })
      .then((users) => isMounted.current && setUsers(users));
  };

  const calculateCost = (bucket) => {
    return api
      .execute("s3.calculate", { name: bucket.name })
      .then((calculated) => {
        calculated.size = PrettyBytes(calculated.size);
        calculated.cost = roundToTwo(calculated.cost);
        return calculated;
      });
  };

  const deleteBucket = (bucket) => {
    return api
      .execute("s3.delete", { name: bucket.name })
      .then(fetchBuckets)
      .catch((error) => {
        if (!isMounted.current) return;
        if (error?.code === "TimeoutError") {
          return deleteBucket(bucket);
        }
        return Promise.reject(error);
      });
  };

  const createBucket = (name, customer, project) => {
    return api
      .execute("s3.create", {
        name: name,
        project: project,
        customer: customer,
      })
      .then(fetchBuckets);
  };

  const requestTempCreds = (bucket) => {
    return api.execute("s3.create_temporary_credentials", {
      name: bucket.name,
    });
  };

  useEffect(() => {
    if (!fetchOnLoad) return;
    fetchBuckets();
    fetchCustomerAccounts();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnLoad]);

  return {
    buckets,
    customerAccounts,
    users,
    isLoading,
    calculateCost,
    fetchBuckets,
    deleteBucket,
    createBucket,
    requestTempCreds,
  };
};
