import { useCallback } from "react";

import { CopyToClipBoardButton } from "components/common";
import { useLambdaApi } from "hooks";

import { getKey } from "../utils";

export default function DownloadLink({ bucket, file, curPath }) {
  const api = useLambdaApi();

  const getDownloadLink = useCallback(() => {
    return api.execute("s3.download", {
      bucket: bucket.name,
      key: getKey(curPath, file),
    });
  }, [api, bucket.name, curPath, file]);

  return (
    <CopyToClipBoardButton
      asIcon
      getTextToCopy={getDownloadLink}
      hoverText="Copy download link"
      copiedText="Download link copied"
    />
  );
}
