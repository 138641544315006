import React from "react";
import { IconButton, Table as CommonTable } from "../../common";

export default function Table({ data, onDelete }) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Customer",
        accessor: "customer",
      },
      {
        Header: "Last Activity",
        accessor: "last_activity",
        Cell: ({ value }) => value && new Date(value).toLocaleString(),
      },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => {
          return (
            <IconButton
              title="Delete"
              icon="trash-fill"
              onClick={() => onDelete(row.original)}
            ></IconButton>
          );
        },
      },
    ],
    [onDelete]
  );

  return <CommonTable columns={columns} data={data} />;
}
