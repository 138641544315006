import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./IconButton.scss";

export default function DetachButton({
  onClick,
  title,
  disabled,
  className,
  children,
}) {
  return (
    <OverlayTrigger
      placement="top"
      transition={false}
      overlay={<Tooltip>{title}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <div
          className={`icon-btn-container d-inline-block ${className}`}
          disabled={disabled}
          {...triggerHandler}
          ref={ref}
        >
          <a
            href="/"
            className="icon-btn"
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
            disabled={disabled}
          >
            {children}
          </a>
        </div>
      )}
    </OverlayTrigger>
  );
}
